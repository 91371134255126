import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LegacyProduct } from 'src/app/models/classes/LegacyProduct';

@Injectable({
  providedIn: 'root'
})
export class LegacyAffiliateService {
  private COLLECTION:string = "legacyAffiliate";
  constructor(private afs: AngularFirestore) { }

  addProduct(product:LegacyProduct) {
    return this.afs.collection(this.COLLECTION).add(product.toJson());
  }

  async getProducts(category:string, ageGroup:number) {
    if(category.length > 0 && ageGroup < 0)
      return await this.afs.collection(this.COLLECTION).ref.where("category","==",category).get();
    else if(category.length == 0 && ageGroup < 0)
      return await this.afs.collection(this.COLLECTION).ref.get();
    else if(category.length == 0 && ageGroup > 0)
      return await this.afs.collection(this.COLLECTION).ref.where("ageGroup","==",ageGroup).get();
    else
      return await this.afs.collection(this.COLLECTION).ref.where("ageGroup","==",ageGroup).where("category","==",category).get();
  }

  async getTopClickedProducts(limit:number) {
    //return await this.afs.collection(this.COLLECTION).ref.orderBy("clicks", 'desc').limit(limit).get();
    return await this.afs.collection(this.COLLECTION).ref.limit(limit).get();
  }

  async updateClick(id:String) {
    console.log(id);
  }
}
