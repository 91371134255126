<div class="w3-card-4 w3-margin cursorPointer w3-border zoom">
  <a (click)="openPost()">
    <div class="w3-center">
      <img src="{{post.titleImage}}" alt="{{post.imageAlt}}" class="w3-image w3-hover-grayscale"
        style="height: 125px;width: 100%;object-fit:fill;">
    </div>
    <div class="w3-container w3-justify w3-white" style="min-height: 120px;">
      <p><b>{{post.title}}</b></p>
      <p class="w3-small">{{post.description! | ellipsis: 50}}<a class="w3-text-purple noDecoration" (click)="openPost()">Read More</a>
      </p>
    </div>
    <div class="w3-container w3-purple">
      <p class="w3-small"><span>{{post.updatedDate | date:'mediumDate'}}</span><em class="w3-right">{{post.author}}</em></p>
    </div>
  </a>
</div>
