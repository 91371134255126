import { OverlayRef, Overlay, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, ElementRef } from '@angular/core';
import { HostListener, Input, OnInit } from '@angular/core';
import { Directive } from '@angular/core';
import { TooltipComponent } from '../ui/common/tooltip/tooltip.component';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnInit{
  overlayRef!: OverlayRef;

  @Input('tooltip') text = '';

  constructor(private overlay: Overlay,private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef) { }

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
    .flexibleConnectedTo(this.elementRef)
    .withPositions([{
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -8,
    }]);
    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  @HostListener('mouseover')
  show() {
    const tooltipRef: ComponentRef<TooltipComponent>
      = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    tooltipRef.instance.text = this.text;
   }

  @HostListener('mouseout')
  hide() {
    this.overlayRef.detach();
  }

  @HostListener('click')
  hideTT() {
    this.overlayRef.detach();
  }
}
