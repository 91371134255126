import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  data:any;
  array:any[] = [];
  constructor() { }

  setData(data:any) {
    this.data = data;
  }

  addArrayData(data:any) {
    this.array.push(data);
  }

  removeAllArrayData(){
    this.array = [];
  }

  getArrayData() {
    return this.array;
  }

  getData() {
    return this.data;
  }

  clearData() {
    this.data = null;
  }
}
