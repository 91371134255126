import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LocalSubscription, Subscription } from 'src/app/models/subscription';

@Injectable({
  providedIn: 'root'
})
export class SubscribeService {
 
  collectionName:string = "subscription";
  constructor(private afs: AngularFirestore) { }

  async hasSubscribed(email:string) {
    const data = await this.afs.collection(this.collectionName).ref.where("email", "==", email).get();
    return data.size > 0;
  }

  async subscribe(subscription: Subscription){
    let ref = await this.afs.collection(this.collectionName).add(subscription);
    return ref.id;
  }

  async getSubscription(email:string){
    return await this.afs.collection(this.collectionName).ref.where("email", "==", email).get();
  }
  
}
