import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css']
})
export class AlertDialogComponent implements OnInit {
  @Input('id') id = '';
  @Input('title') title = '';
  @Input('description') description = '';
  @Input('ok') ok = true;
  @Input('cancel') cancel = true;
  @Output() dialogEvent = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit(): void {
  }

  openModal() {
    document.getElementById(this.id)!.style.display='block';
  }

  closeModal(value:boolean) {
    document.getElementById(this.id)!.style.display='none';
    this.dialogEvent.emit(value);
  }

}
