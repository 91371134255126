import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-af-store-manage-products',
  templateUrl: './af-store-manage-products.component.html',
  styleUrls: ['./af-store-manage-products.component.css']
})
export class AfStoreManageProductsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
