<div class="w3-panel w3-bottombar w3-border-deep-purple">
  <div class="w3-left">
    <p class="w3-large">Blog Settings</p>
  </div>
  <div class="w3-right">
    <a class="w3-btn w3-pink" href="/dashboard/settings">Back</a>
  </div>
</div>
<div class="w3-container">
  <form id="categoriesForm">
    <div class="w3-row">
      <div class="w3-half">
        <div class="w3-row w3-deep-purple w3-padding w3-margin">
          <h3>Add Category</h3>
        </div>
        <div class="w3-row w3-padding w3-margin">
          <input class="w3-input" type="text" placeholder="Enter a category name" [formControl]="newCategory">
          <p class="w3-clear"></p>
          <button class="w3-pink w3-btn" (click)="addCategory(newCategory.value)" [disabled]="!newCategory.dirty || newCategory.value.length < 3">Add</button>
        </div>
      </div>
      <div class="w3-half">
        <div class="w3-row w3-deep-purple w3-padding w3-margin">
          <h3>Categories</h3>
        </div>
        <div class="w3-row w3-padding w3-margin">
          <spinner *ngIf="showLoader"></spinner>
          <table class="w3-table-all" *ngIf="postSettings">
            <tr *ngFor="let item of postSettings.category | keyvalue">
              <td style="width: 500px;">{{item.key}}</td>
              <td><input class="w3-check" type="checkbox" [checked]="item.value" (click)="tapCheckCategory(item.key,!item.value)"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </form>
  <p class="w3-clear"></p>
  <form id="tagsForm">
    <div class="w3-row">
      <div class="w3-half">
        <div class="w3-row w3-deep-purple w3-padding w3-margin">
          <h3>Add Tag</h3>
        </div>
        <div class="w3-row w3-padding w3-margin">
          <input class="w3-input" type="text" placeholder="Enter a tag" [formControl]="newTag">
          <p class="w3-clear"></p>
          <button class="w3-pink w3-btn" (click)="addTag(newTag.value)" [disabled]="!newTag.dirty || newTag.value.length < 3">Add</button>
        </div>
      </div>
      <div class="w3-half">
        <div class="w3-row w3-deep-purple w3-padding w3-margin">
          <h3>Tags</h3>
        </div>
        <div class="w3-row w3-padding w3-margin">
          <spinner *ngIf="showLoader"></spinner>
          <table class="w3-table-all" *ngIf="postSettings">
            <tr *ngFor="let item of postSettings.Tags | keyvalue">
              <td style="width: 500px;">{{item.key}}</td>
              <td><input class="w3-check" type="checkbox" [checked]="item.value" (click)="tapCheckTag(item.key,!item.value)"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </form>
</div>
