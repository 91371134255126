import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { QuillModules } from 'ngx-quill';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill/lib/quill-editor.component';
import Quill from 'quill';

@Component({
  selector: 'app-store-add-product',
  templateUrl: './store-add-product.component.html',
  styleUrls: ['./store-add-product.component.css']
})
export class StoreAddProductComponent implements OnInit {
  fileExts = ['png', 'jpg', 'jpeg', 'gif'];
  regex = '(https?://)?([\\da-zA-Z0-9//%._?=&-]+)';

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ['emoji'],
    ['link', 'image', 'video'],
    ['clean'], // remove formatting button
  ];

  quillModules: QuillModules = {
    toolbar: {
      container: this.toolbarOptions,
      handlers: {
        image: this.imageHandler,
        emoji: function () {},
      },
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    imageResize: {},
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          },
        },
      },
    },
  };
  fg: FormGroup;
  displayFormError: boolean = false;
  badUser: boolean = false;
  showSaveMessage: boolean = false;
  imageUrls?: string[] = [];
  html: string = '';

  constructor(private formBuilder: FormBuilder) {
    this.fg = this.formBuilder.group({
      title: new FormControl('', [
        Validators.required || Validators.minLength(10),
      ]),
      titleImage: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regex),
      ]),
      imageAlt: new FormControl(''),
      description: new FormControl('', [
        Validators.required || Validators.maxLength(100),
      ]),
      category: new FormControl('Blog'),
      subCategory: new FormControl(''),
      target: new FormControl('0'),
      author: new FormControl('Butterfly Mom', [Validators.required]),
      editorContent: new FormControl('', [Validators.required]),
      authorType: new FormControl({
        value: "",
        disabled: true,
      }),
      tags: new FormControl(''),
      disableComments: new FormControl(false),
      isTop: new FormControl({ value: false, disabled: true }),
      amafli: new FormControl(),
      spcl: new FormControl(),
      olinks: new FormControl(),
    });
  }

  ngOnInit(): void {
  }

  imageHandler(this: any) {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;
    tooltip.save = function (this: any) {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function (this: any) {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Embed URL';
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {

  }

  editorFocusChange(event: any) {

  }

  addBindingCreated(quill: Quill) {
    quill.keyboard.addBinding(
      {
        key: 'b',
      },
      (range, context) => {
        console.log('KEYBINDING B', range, context);
      }
    );

    quill.keyboard.addBinding(
      {
        key: 'B',
        shiftKey: true,
      } as any,
      (range, context) => {
        console.log('KEYBINDING SHIFT + B', range, context);
      }
    );
  }

  expandAccordion(id: string, chevronId: string) {
    var x = document.getElementById(id);
    var y = document.getElementById(chevronId);
    if (x!.className.indexOf('w3-show') == -1) {
      x!.className += ' w3-show';
      y!.classList.replace('fa-chevron-down', 'fa-chevron-up');
    } else {
      x!.className = x!.className.replace(' w3-show', '');
      y!.classList.replace('fa-chevron-up', 'fa-chevron-down');
    }
  }

}
