import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-af-store-orders',
  templateUrl: './af-store-orders.component.html',
  styleUrls: ['./af-store-orders.component.css']
})
export class AfStoreOrdersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
