import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ClipboardService } from 'ngx-clipboard'
import { StateService } from 'src/app/services/state/state.service';
import { Upload } from 'src/app/models/interfaces/upload';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.css']
})
export class UploadTaskComponent implements OnInit {
  baseFolder:string = environment.storageFolder;
  @Input() file!: File;
  @Input() fileNo:number = 0;
  @Input() totalFiles:number = 0;
  @Input() folder:string = 'all';

  task!: AngularFireUploadTask;

  percentage!: Observable<number | undefined>;
  @Output() percentValueEvent = new EventEmitter<Upload>();
  snapshot!: Observable<any>;
  downloadURL: string = "";

  constructor(private storage: AngularFireStorage, private db: AngularFirestore, private stateService:StateService) { }

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    // The storage path
    const path:string = this.baseFolder + "/" + this.folder + `/${Date.now()}_${this.file.name}`;

    // Reference to storage bucket
    const ref = this.storage.ref(path);

    // The main task
    this.task = this.storage.upload(path, this.file);

    // Progress monitoring
    this.percentage = this.task.percentageChanges().pipe(map(data=>{
      this.addNewEventItem(<Upload>{fileNo:this.fileNo, percentage: data, totalFiles: this.totalFiles});
      return data;
    }));

    this.snapshot = this.task.snapshotChanges().pipe(
      tap(),
      // The file's download URL
      finalize( async() =>  {
        this.downloadURL = await ref.getDownloadURL().toPromise();
        this.stateService.addArrayData(this.downloadURL);
        this.stateService.setData(this.downloadURL);
        //this.db.collection('files').add( { downloadURL: this.downloadURL, path });
      }),
    );
  }

  isActive(snapshot:any) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes;
  }

  addNewEventItem(value: Upload) {
    this.percentValueEvent.emit(value);
  }

}
