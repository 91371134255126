<div class="w3-text-purple">
    <ul id="myUL">
        <li><span id="1" (click)="toggleCarret('1')" class="caret">Beverages</span>
          <ul class="nested">
            <li><span id="2" (click)="toggleCarret('2')" class="caret">Tea</span>
              <ul class="nested">
                <li>Black Tea</li>
                <li>White Tea</li>
              </ul>
            </li>  
            <li><span id="3" (click)="toggleCarret('3')" class="caret">Tea</span>
                <ul class="nested">
                  <li>Black Tea</li>
                  <li>White Tea</li>
                </ul>
              </li>  
          </ul>
        </li>
      </ul>
</div>