<!-- Blog entry -->
<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
  <h4>Recent Blogs and Reviews</h4>
</div>
<spinner *ngIf="showLoader"></spinner>
<no-records *ngIf="showNoRecords"></no-records>
<error-loading *ngIf="showError"></error-loading>
<div *ngFor="let localPost of localPosts" class="w3-row w3-border w3-card-4 w3-white w3-margin w3-padding-32">
  <div class="w3-container w3-third w3-padding-32">
    <img src="{{ localPost.post!.titleImage }}" alt="{{ localPost.post!.imageAlt }}"
        style="object-fit: contain; width: 100%;height: 100%;" class="w3-image" />
  </div>
  <div class="w3-container w3-twothird">
    <div class="w3-center">
      <h3>{{ localPost.post!.title }}</h3>
      <h5>
        <span *ngIf="localPost.post!.description!.length > 0">{{ localPost.post!.description }}, </span><span
          class="w3-opacity">{{
          localPost.post!.createdDate | date: "fullDate"
          }}</span>
      </h5>
    </div>
    <div>
      <span style="text-overflow: unset; justify-content: safe"
      [innerHTML]="extractContent(localPost.post!.html, true)! | ellipsis: 400"></span>
      <a class="w3-text-purple noDecoration" (click)="openPost(localPost)">Read More</a>
    </div>
  </div>
</div>
<div *ngIf="!showLoader" class="w3-right w3-container">
  <p>
    <a href="web/blog" class="w3-btn w3-pink w3-margin" tooltip="Click to view more blogs">More Blogs</a>
    <a href="web/reviews" class="w3-btn w3-pink" tooltip="Click to view more reviews">More Reviews</a>
  </p>
</div>
