import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalPost, Post } from 'src/app/models/classes/posts';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.css']
})
export class BlogCardComponent implements OnInit {

  @Input() lpPost!:LocalPost;
  post!:Post;

  constructor(private router:Router, private stateService: StateService) {
   }

  ngOnInit(): void {
    this.post = this.lpPost.post!;
  }

  openPost()
  {
    this.stateService.setData(this.lpPost);
    if(this.post.category == "Blog")
      this.router.navigate(['web/blog/post'], { queryParams:{ title: this.post.urlTitle }});
    else if(this.post.category == "Reviews")
      this.router.navigate(['web/reviews/post'], { queryParams:{ title: this.post.urlTitle }});
  }
}
