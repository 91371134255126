import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalPost, Post } from 'src/app/models/classes/posts';
import { DashboardDataOperation } from 'src/app/models/enums/dashboardDataOperation';
import { PostStatus } from 'src/app/models/enums/poststatus';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashbaordDataService } from 'src/app/services/dashboard-data/dashbaord-data.service';
import { PostsService } from 'src/app/services/post/posts.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-manage-posts',
  templateUrl: './manage-posts.component.html',
  styleUrls: ['./manage-posts.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(500)),
    ]),
  ]
})
export class ManagePostsComponent implements OnInit {

  statuses: any[] = [{ item: "All", value: -1 }, { item: "New", value: 0 }, { item: "Published", value: 1 }, { item: "Unpublished", value: 2 }, { item: "Scheduled", value: 3 }]
  localPosts: LocalPost[] = [];

  showLoader = true;
  showNoRecords = false;
  showError = false;

  filterAndSort: FormGroup;

  constructor(private postsService: PostsService, private router: Router, private formBuilder: FormBuilder, private stateService: StateService, private dashboardService: DashbaordDataService, private authService: AuthService) {
    this.filterAndSort = formBuilder.group({
      status: new FormControl(-1),
      creator: new FormControl('All'),
    });
    postsService.getAllPostsAndReviews().then((snapshot) => {
      if (snapshot.size > 0) {
        this.showLoader = false;
        snapshot.forEach((doc) => {
          this.localPosts.push({ uid: doc.id, post: <Post>doc.data() });
        });
      }
      else {
        this.showLoader = false;
        this.showNoRecords = true;
      }
    }).catch(err => {
      this.showLoader = false;
      this.showError = true;
    });
  }

  ngOnInit(): void {
  }

  getStatusText(status: number) {
    return PostStatus[status];
  }

  edit(localPost: LocalPost) {
    this.stateService.setData(localPost);
    this.router.navigate(['/dashboard/post/edit'], { queryParams: { title: localPost.post!.urlTitle}});
  }

  publish(uid: any, category:String) {
    if (category?.includes('log')) {
      this.dashboardService.updateBlogData(
        DashboardDataOperation.Increment,
        DashboardDataOperation.Increment,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.DoNothing,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    } else {
      this.dashboardService.updateReviewData(
        DashboardDataOperation.Increment,
        DashboardDataOperation.Increment,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.DoNothing,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    }
    this.postsService.publishPost(uid).then(() => {
      window.location.reload();
    }).catch((err) => {
      console.error(err);
    });
  }

  unPublish(uid: any,category:String) {
    if (category?.includes('log')) {
      this.dashboardService.updateBlogData(
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.Decrement,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.Increment,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    } else {
      this.dashboardService.updateReviewData(
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.Decrement,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.Increment,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    }
    this.postsService.unPublishPost(uid).then(() => {
      window.location.reload();
    }).catch((err) => {
      console.error(err);
    });
  }

  deletePost(uid: any, category:string, status: number) {
    if (category?.includes('log')) {
      this.dashboardService.updateBlogData(
        DashboardDataOperation.Decrement,
        status == PostStatus.Published ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.Scheduled ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.Unpublished ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.ToModerate ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
      );
    } else {
      this.dashboardService.updateReviewData(
        DashboardDataOperation.Decrement,
        status == PostStatus.Published ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.Scheduled ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.Unpublished ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
        status == PostStatus.ToModerate ? DashboardDataOperation.Decrement : DashboardDataOperation.DoNothing,
      );
    }
    this.postsService.deletePost(uid).then(() => {
      window.location.reload();
    }).catch((err) => {
      console.error(err);
    });
  }
}
