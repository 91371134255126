import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { DashboardDataOperation } from 'src/app/models/enums/dashboardDataOperation';

@Injectable({
  providedIn: 'root',
})
export class DashbaordDataService {
  collection = 'dashboard';
  doc = 'data';
  constructor(private afs: AngularFirestore) {}

  async getDashboardData() {
    return this.afs.collection(this.collection).doc(this.doc).ref.get();
  }

  private fieldValue(data: DashboardDataOperation) {
    var fieldData;
    switch (data) {
      case DashboardDataOperation.Increment:
        fieldData = firebase.default.firestore.FieldValue.increment(1);
        break;
      case DashboardDataOperation.DoNothing:
        break;
      case DashboardDataOperation.Decrement:
        fieldData = firebase.default.firestore.FieldValue.increment(-1);
        break;
    }
    return fieldData;
  }

  async updateBlogData(
    total: DashboardDataOperation,
    published: DashboardDataOperation,
    scheduled: DashboardDataOperation,
    unpublished: DashboardDataOperation,
    unmoderated: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      blog: {
        published: this.fieldValue(published),
        scheduled: this.fieldValue(scheduled),
        total: this.fieldValue(total),
        unmoderated: this.fieldValue(unmoderated),
        unpublished: this.fieldValue(unpublished),
      },
    });
  }

  async updateReviewData(
    total: DashboardDataOperation,
    published: DashboardDataOperation,
    scheduled: DashboardDataOperation,
    unpublished: DashboardDataOperation,
    unmoderated: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      reviews: {
        total: this.fieldValue(total),
        published: this.fieldValue(published),
        scheduled: this.fieldValue(scheduled),
        unpublished: this.fieldValue(unpublished),
        unmoderated: this.fieldValue(unmoderated),
      },
    });
  }

  async updateSubscribersData(
    total: DashboardDataOperation,
    blog: DashboardDataOperation,
    reviews: DashboardDataOperation,
    courses: DashboardDataOperation,
    events: DashboardDataOperation,
    products: DashboardDataOperation,
    suggestions: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      subscribers: {
        total: this.fieldValue(total),
        blog: this.fieldValue(blog),
        reviews: this.fieldValue(reviews),
        courses: this.fieldValue(courses),
        events: this.fieldValue(events),
        products: this.fieldValue(products),
        suggestions: this.fieldValue(suggestions)
      },
    });
  }

  async updateUsersData(
    total: DashboardDataOperation,
    admin: DashboardDataOperation,
    editors: DashboardDataOperation,
    moderators: DashboardDataOperation,
    sellers: DashboardDataOperation,
    seller_others: DashboardDataOperation,
    course_admin: DashboardDataOperation,
    course_providers: DashboardDataOperation,
    event_admin: DashboardDataOperation,
    event_provider: DashboardDataOperation,
    customers: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      users: {
        total: this.fieldValue(total),
        admin: this.fieldValue(admin),
        editors: this.fieldValue(editors),
        moderators: this.fieldValue(moderators),
        sellers: this.fieldValue(sellers),
        seller_others: this.fieldValue(seller_others),
        course_admin: this.fieldValue(course_admin),
        course_providers: this.fieldValue(course_providers),
        event_admin: this.fieldValue(event_admin),
        event_provider: this.fieldValue(event_provider),
        customers: this.fieldValue(customers),
      },
    });
  }

  async updateModerationData(
    post: DashboardDataOperation,
    unpost: DashboardDataOperation,
    comment: DashboardDataOperation,
    uncomment: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      moderation: {
        moderated_comment: this.fieldValue(comment),
        moderated_post: this.fieldValue(post),
        unmoderated_comment: this.fieldValue(uncomment),
        unmoderated_post: this.fieldValue(unpost),
      },
    });
  }

  async updateOrderData(
    completed: DashboardDataOperation,
    intransit: DashboardDataOperation,
    open: DashboardDataOperation,
    returned: DashboardDataOperation
  ) {
    let ref = this.afs.collection(this.collection).doc(this.doc).ref;
    ref.update({
      moderation: {
        completed: this.fieldValue(completed),
        in_transit: this.fieldValue(intransit),
        open_orders: this.fieldValue(open),
        returned: this.fieldValue(returned),
      },
    });
  }
}
