import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import * as firebase from 'firebase';
import { User } from 'src/app/models/user';
import { UserRoles } from 'src/app/models/enums/userRoles';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  updatedData: any;

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        this.afs.collection('users').doc<User>(user.uid).valueChanges().subscribe((data) => {
          localStorage.setItem('user', JSON.stringify(data));
          JSON.parse(localStorage.getItem('user')!);
        });
      } else {
        localStorage.setItem('user', '{}');
        JSON.parse(localStorage.getItem('user')!);
      }
    });
  }

  // Sign in with email/password
  signIn(email: any, password: any) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result: { user: any; }) => {
        this.ngZone.run(() => {
          this.router.navigate(['web/home']);
        });
        this.setUserData(result.user);
      }).catch((error: { message: any; }) => {
        window.alert(error.message)
      })
  }

  // Sign up with email/password
  signUp(email: any, password: any) {
    return this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((result: { user: any; }) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
        this.sendVerificationMail();
        this.setUserData(result.user);
      }).catch((error: { message: any; }) => {
        window.alert(error.message)
      })
  }

  // Send email verfificaiton when new user sign up
  sendVerificationMail() {
    return firebase.default.auth().currentUser?.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      })
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail: any) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error: any) => {
        window.alert(error)
      })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    let flag = false;
    if (Object.keys(this.localStoredUser).length === 0)
      flag = false;
    else if (this.localStoredUser.emailVerified !== false) {
      flag = true;
    }
    return flag;
  }

  get isSubscriber(): boolean {
    let flag = false;
    if (Object.keys(this.localStoredUser).length === 0)
      return flag;
    const storedData = this.localStoredUser;
    if (storedData.emailVerified !== false) {
      if (!storedData.roles) {
        flag = false;
      }
      else if (storedData.roles.includes(UserRoles.CUSTOMER))
        flag = true;
    }
    return flag;
  }

  get isEditor(): boolean {
    let flag = false;
    if (Object.keys(this.localStoredUser).length === 0)
      return flag;
    const storedData = this.localStoredUser;
    if (storedData.emailVerified !== false) {
      if (!storedData.roles) {
        flag = false;
      }
      else if (storedData.roles.includes(UserRoles.BLOG_EDITOR))
        flag = true;
    }
    return flag;
  }

  private get localStoredUser(): any {
    if (localStorage.getItem("user") === null)
      return {};
    else return JSON.parse(localStorage.getItem('user')!);
  }

  get isAdmin(): boolean {
    let flag = false;
    if (Object.keys(this.localStoredUser).length === 0)
      return flag;
    const storedData = this.localStoredUser;
    if (storedData.emailVerified !== false) {
      if (!storedData.roles) {
        flag = false;
      }
      else if (storedData.roles.includes(UserRoles.ADMIN))
        flag = true;
    }
    return flag;
  }

  get roles(): number[] {
    let ret: number[] = [];
    if (Object.keys(this.localStoredUser).length === 0)
      return ret;
    const storedData = this.localStoredUser;
    if (storedData.emailVerified !== false) {
      if (storedData.roles)
        ret = storedData.roles;
    }
    return ret;
  }

  get userName(): string {
    const user = this.localStoredUser;
    return user.displayName;
  }

  get user(): any {
    return JSON.parse(localStorage.getItem('user')!);
  }

  // Sign in with Google
  googleAuth() {
    return this.authLogin(new firebase.default.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  authLogin(provider: any) {
    return this.afAuth.signInWithPopup(provider)
      .then((result: { user: any; }) => {
        this.ngZone.run(() => {
          this.router.navigate(['web/home']);
        });
        this.afs.collection('users').ref.where('email', "==", result.user.email).get().then((data) => {
          if (data.size == 0) {
            let user = result.user;
            user.roles = [UserRoles.CUSTOMER]
            this.setUserData(user);
          }
        });
      }).catch((error: any) => {
        window.alert(error)
      })
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  setUserData(user: { uid: any; email: any; displayName: any; photoURL: any; emailVerified: any; roles: any }) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      alias: "",
      profilepath: "",
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      roles: user.roles,
      createdDate: Date.now().toString()
    }
    return userRef.set(userData, {
      merge: true
    })
  }

  updateUserData(uid: any, data: any) {
    this.afs.doc(`users/${uid}`).update(data);
  }

  // Sign out
  signOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['web/home']);
      window.location.reload();
    })
  }

}
