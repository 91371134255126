<div class="w3-row-padding">
  <p class="w3-text-black w3-large">
    <b>Users</b>
  </p>
  <p class="w3-text-grey">Provide or revoke access</p>
</div>
<table class="w3-table-all">
  <thead>
    <tr class="w3-deep-purple">
      <th>Name</th>
      <th>Email</th>
      <th>Admin</th>
      <th>Customer</th>
      <th>Editor</th>
      <th>Moderator</th>
      <th>Seller Admin</th>
      <th>Seller Other</th>
      <th>Course Admin</th>
      <th>Course Provider</th>
      <th>Event Admin</th>
      <th>Event Provider</th>
      <th>Update</th>
    </tr>
  </thead>
  <tr *ngFor="let item of users; let i = index;" class="w3-card-2">
    <td>{{item.user.displayName}}</td>
    <td>{{item.user.email}}</td>
    <td><input class="w3-check" type="checkbox" id="{{ADMIN+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(1)"></td> <!-- ADMIN -->
    <td><input class="w3-check" type="checkbox" id="{{CUSTOMER+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(0)"></td> <!-- CUSTOMER -->
    <td><input class="w3-check" type="checkbox" id="{{EDITOR+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(2)"></td> <!-- BLOG_EDITOR -->
    <td><input class="w3-check" type="checkbox" id="{{MODERATOR+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(3)"></td> <!-- MODERATOR -->
    <td><input class="w3-check" type="checkbox" id="{{SELLER_ADMIN+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(4)"></td> <!-- SELLER_ADMIN -->
    <td><input class="w3-check" type="checkbox" id="{{SELLER_OTHER+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(5)"></td> <!-- SELLER_OTHER -->
    <td><input class="w3-check" type="checkbox" id="{{COURSE_ADMIN+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(6)"></td> <!-- COURSE_ADMIN -->
    <td><input class="w3-check" type="checkbox" id="{{COURSE_PROVIDER+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(7)"></td> <!-- COURSE_PROVIDER -->
    <td><input class="w3-check" type="checkbox" id="{{EVENT_ADMIN+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(8)"></td> <!-- EVENT_ADMIN -->
    <td><input class="w3-check" type="checkbox" id="{{EVENT_PROVIDER+i}}" [disabled]="item.disabled" [checked]="item.user.roles.includes(9)"></td> <!-- EVENT_PROVIDER -->
    <td><button class="w3-btn w3-circle" (click)="item.disabled ? unlock(i) : lock(i)"><i class="fa" [ngClass]="item.disabled ? 'fa-lock' : 'fa-unlock'" aria-hidden="true"></i>
    </button></td> <!-- EVENT_PROVIDER -->
  </tr>
</table>
