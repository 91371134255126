import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  displayHomeMenu:Boolean = false;
  isAdmin:any = false;

  constructor(private router:Router, private authService: AuthService) {
    router.events.subscribe((val)=>{
      if(val instanceof NavigationEnd)
      {
        if(window.location.href.includes("dashboard"))
        {
            this.displayHomeMenu = true
        }
        else{
          this.displayHomeMenu = false
        }
      }
    });
    this.isAdmin = authService.isAdmin
   }

  ngOnInit(): void {
  }

}
