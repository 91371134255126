<footer class="footer">
    <!-- <a href="#" class="w3-button w3-black w3-padding-large w3-margin-bottom"><i class="fa fa-arrow-up w3-margin-right"></i>To the top</a> -->
    <div class="w3-card-4 w3-margin-top w3-pink">
    <div class="w3-content w3-center" style="max-width:1600px">
        <div class="w3-container">
            <p>ButterflyMom<sup>&reg;</sup> 2022 | <a style="text-decoration: none;" routerLink="privacy-policy">Privacy Policy</a> | <a style="text-decoration: none;" routerLink="terms-of-use">Terms of Use</a> | <a style="text-decoration: none;" routerLink="about">About</a></p>
        </div>
    </div>
    </div>
</footer>
