import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/user';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  user!: User;
  constructor(public authService: AuthService, private titleService: Title) {
    if(authService.isLoggedIn)
      this.user = authService.user;
   }

  ngOnInit(): void {
    this.titleService.setTitle("Profile - Butterfly Mom");
  }

}
