<div class="w3-panel w3-bottombar w3-border-deep-purple">
  <div class="w3-left">
    <p class="w3-large">Home Slider Settings</p>
  </div>
  <div class="w3-right">
    <a class="w3-btn w3-pink" href="/dashboard/settings">Back</a>
  </div>
</div>
<div class="w3-container">
  <div class="w3-row">
    <div class="w3-half">
      <div class="w3-row w3-deep-purple w3-padding w3-margin">
        <h3>Add Slide</h3>
      </div>
      <div class="w3-row w3-padding w3-margin">
        <form [formGroup]="fg" id="categoriesForm">
          <label>Index</label>
          <input class="w3-input" type="number" placeholder="Enter index" formControlName="indexNo">
          <p class="w3-clear"></p>
          <label>Title</label>
          <input class="w3-input" type="text" placeholder="Enter image title" formControlName="title">
          <p class="w3-clear"></p>
          <label>Title Color</label>
          <select class="w3-select" formControlName="titleColor">
            <option disabled selected value="">Select title color</option>
            <option *ngFor="let item of colors" [ngClass]="'w3-'+item" value="red">{{item | titlecase}}</option>
          </select>
          <p class="w3-clear"></p>
          <label>Sub-Title</label>
          <input class="w3-input" type="text" placeholder="Enter image sub-title" formControlName="subtitle">
          <p class="w3-clear"></p>
          <label>Sub-Title Color</label>
          <select class="w3-select" formControlName="subtitleColor">
            <option selected value="">Select sub-title color</option>
            <option *ngFor="let item of colors" [ngClass]="'w3-'+item" [value]="item">{{item | titlecase}}</option>
          </select>
          <p class="w3-clear"></p>
          <label>Duration</label>
          <input class="w3-input" type="number" min=1 max=6 placeholder="Enter slide duration"
            formControlName="seconds">
          <p class="w3-clear"></p>
          <label>Animation Type</label>
          <select class="w3-select" formControlName="animation">
            <option selected value="">Select animation type</option>
            <option *ngFor="let item of animationTypes" [value]="item">{{item | titlecase}}</option>
          </select>
          <p class="w3-clear"></p>
          <label>Position of text</label>
          <select class="w3-select" formControlName="position">
            <option selected value="">Select position of text</option>
            <option *ngFor="let item of positionTypes" [value]="item">{{item | titlecase}}</option>
          </select>
          <p class="w3-clear"></p>
          <div *ngIf="desktopImage == '' || mobileImage != ''">
            <label>Upload Single Image for desktop</label>
            <app-uploader [exts]="fileExts" [folder]="FOLDER"></app-uploader>
            <p class="w3-clear"></p>
          </div>
          <div class="w3-row">
            <div class="w3-threequarter">
              <label>Link to desktop image</label>
              <input class="w3-input" type="text" placeholder="Uploaded image url" readonly formControlName="imageLinkD" [value]="desktopImage">
            </div>
            <div class="w3-quarter w3-padding">
              <p class="w3-clear"></p>
              <button class="w3-btn w3-purple" (click)="refreshDesktopImageUrl()">Refresh</button>
            </div>
          </div>
          <p class="w3-clear"></p>
          <div *ngIf="desktopImage != ''">
          <label>Upload Single Image for mobile</label>
          <app-uploader [exts]="fileExts" [folder]="FOLDER"></app-uploader>
          <p class="w3-clear"></p>
          </div>
          <div class="w3-row">
            <div class="w3-threequarter">
              <label>Link to mobile image</label>
              <input class="w3-input" type="text" placeholder="Uploaded image url" readonly formControlName="imageLinkM" [value]="mobileImage">
            </div>
            <div class="w3-quarter w3-padding">
              <p class="w3-clear"></p>
              <button class="w3-btn w3-purple" (click)="refreshMobileImageUrl()">Refresh</button>
            </div>
          </div>
        </form>
        <p class="w3-clear"></p>
        <button class="w3-pink w3-btn" (click)="addSlide()" [disabled]="!fg.dirty || fg.value.length < 3">Add</button>
      </div>
    </div>
    <div class="w3-half">
      <div class="w3-row w3-deep-purple w3-padding w3-margin">
        <h3>Preview/Edit</h3>
      </div>
      <div class="w3-row w3-padding w3-margin">
        <h1>Desktop</h1>
        <app-header-carousel [images]="images" [showBoth]="false"></app-header-carousel>
        <h1>Mobile</h1>
        <app-header-carousel [images]="images" [showBoth]="true"></app-header-carousel>
      </div>
    </div>
  </div>
</div>
