import { NgModule } from '@angular/core';
import { BrowserModule, Title, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from './services/auth/auth.service';
import { DropzoneDirective } from './directives/dropzone.directive';
import { ClipboardModule } from 'ngx-clipboard';
import { QuillEditorComponent, QuillModule } from 'ngx-quill';
import { TooltipDirective } from './directives/tooltip.directive';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EllipsisPipe } from './pipes/ellipsis/ellipsis.pipe';
import { NodomsanitizePipe } from './pipes/nodomsanitize/nodomsanitize.pipe';
import { AccordionComponent } from './ui/common/accordion/accordion.component';
import { ButteflyAnimationComponent } from './ui/common/buttefly-animation/buttefly-animation.component';
import { CommentCardComponent } from './ui/common/comments-replies/comment-card/comment-card.component';
import { CommentsRepliesComponent } from './ui/common/comments-replies/comments-replies.component';
import { ErrorLoadingComponent } from './ui/common/error-loading/error-loading.component';
import { NoRecordsComponent } from './ui/common/no-records/no-records.component';
import { SpinnerComponent } from './ui/common/spinner/spinner.component';
import { TestCkeComponent } from './ui/common/test-cke/test-cke.component';
import { TooltipComponent } from './ui/common/tooltip/tooltip.component';
import { UploadTaskComponent } from './ui/common/upload-task/upload-task.component';
import { UploaderComponent } from './ui/common/uploader/uploader.component';
import { AdminLoginErrorComponent } from './ui/dashboard/admin-login-error/admin-login-error.component';
import { AdminLoginComponent } from './ui/dashboard/admin-login/admin-login.component';
import { AfLegacyAddProductComponent } from './ui/dashboard/af-store/af-legacy-add-product/af-legacy-add-product.component';
import { AfStoreAddProductComponent } from './ui/dashboard/af-store/af-store-add-product/af-store-add-product.component';
import { AfStoreManageProductsComponent } from './ui/dashboard/af-store/af-store-manage-products/af-store-manage-products.component';
import { AfStoreOrdersComponent } from './ui/dashboard/af-store/af-store-orders/af-store-orders.component';
import { AfStoreComponent } from './ui/dashboard/af-store/af-store.component';
import { DashboardComponent } from './ui/dashboard/dashboard/dashboard.component';
import { OrdersComponent } from './ui/dashboard/orders/orders.component';
import { OverviewComponent } from './ui/dashboard/overview/overview.component';
import { ManagePostsComponent } from './ui/dashboard/post/manage-posts/manage-posts.component';
import { NewPostComponent } from './ui/dashboard/post/new-post/new-post.component';
import { PostComponent } from './ui/dashboard/post/post.component';
import { PublishedPostComponent } from './ui/dashboard/post/published-post/published-post.component';
import { SellerComponent } from './ui/dashboard/seller/seller.component';
import { StoreAddProductComponent } from './ui/dashboard/seller/store-add-product/store-add-product.component';
import { StoreManageComponent } from './ui/dashboard/seller/store-manage/store-manage.component';
import { StoreOrdersComponent } from './ui/dashboard/seller/store-orders/store-orders.component';
import { SettingsComponent } from './ui/dashboard/settings/settings.component';
import { UsersComponent } from './ui/dashboard/users/users.component';
import { ViewsComponent } from './ui/dashboard/views/views.component';
import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component';
import { TitlebarComponent } from './ui/titlebar/titlebar.component';
import { TopbarComponent } from './ui/topbar/topbar.component';
import { ContentComponent } from './ui/web/content/content.component';
import { EventDetailsComponent } from './ui/web/content/events/event-details/event-details.component';
import { EventsComponent } from './ui/web/content/events/events.component';
import { ReventRegistrationComponent } from './ui/web/content/events/revent-registration/revent-registration.component';
import { UpcomingEventComponent } from './ui/web/content/events/upcoming-event/upcoming-event.component';
import { HeaderCarouselComponent } from './ui/web/content/home/header-carousel/header-carousel.component';
import { HomeComponent } from './ui/web/content/home/home.component';
import { RecentBlogsComponent } from './ui/web/content/home/recent-blogs/recent-blogs.component';
import { ProfileComponent } from './ui/web/content/profile/profile.component';
import { ReviewsComponent } from './ui/web/content/reviews/reviews.component';
import { AfCardComponent } from './ui/web/content/shop/affiliate-store/af-card/af-card.component';
import { AfPdpComponent } from './ui/web/content/shop/affiliate-store/af-pdp/af-pdp.component';
import { AffiliateStoreComponent } from './ui/web/content/shop/affiliate-store/affiliate-store.component';
import { DownloadsComponent } from './ui/web/content/shop/downloads/downloads.component';
import { ShopComponent } from './ui/web/content/shop/shop.component';
import { FooterComponent } from './ui/web/footer/footer.component';
import { ForgotPasswordComponent } from './ui/web/forgot-password/forgot-password.component';
import { MenuComponent } from './ui/web/menu/menu.component';
import { AdComponent } from './ui/web/right-bar/ad/ad.component';
import { BlogTagsComponent } from './ui/web/right-bar/blog-tags/blog-tags.component';
import { BlogTreeComponent } from './ui/web/right-bar/blog-tree/blog-tree.component';
import { BuyFromAmazonComponent } from './ui/web/right-bar/buy-from-amazon/buy-from-amazon.component';
import { FollowMeComponent } from './ui/web/right-bar/follow-me/follow-me.component';
import { MeCardComponent } from './ui/web/right-bar/me-card/me-card.component';
import { PopularPostsComponent } from './ui/web/right-bar/popular-posts/popular-posts.component';
import { RightBarComponent } from './ui/web/right-bar/right-bar.component';
import { SubscribeComponent } from './ui/web/right-bar/subscribe/subscribe.component';
import { SuggestionsComponent } from './ui/web/right-bar/suggestions/suggestions.component';
import { SignIpComponent } from './ui/web/sign-ip/sign-ip.component';
import { TagResultsComponent } from './ui/web/tag-results/tag-results.component';
import { UserLoginComponent } from './ui/web/user-login/user-login.component';
import { BlogCardComponent } from './ui/web/content/blog/blog-card/blog-card.component';
import { BlogPostComponent } from './ui/web/content/blog/blog-post/blog-post.component';
import { BlogComponent } from './ui/web/content/blog/blog.component';
import { ImageCarouselComponent } from './ui/common/sliders/image-carousel/image-carousel.component';
import { PostsSettingsComponent } from './ui/dashboard/settings/posts-settings/posts-settings.component';
import { ReviewSettingsComponent } from './ui/dashboard/settings/review-settings/review-settings.component';
import { AffiliateSettingsComponent } from './ui/dashboard/settings/affiliate-settings/affiliate-settings.component';
import { ModerationComponent } from './ui/dashboard/moderation/moderation.component';
import { HomeSliderComponent } from './ui/dashboard/settings/home-slider/home-slider.component';
import { PrivacyPolicyComponent } from './ui/web/footer/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './ui/web/footer/terms-of-use/terms-of-use.component';
import { AboutComponent } from './ui/web/footer/about/about.component';
import { DomSanitizePipe } from './pipes/domsanitize/domsanitize.pipe';
import { AngularFireAnalyticsModule, ScreenTrackingService  } from '@angular/fire/analytics';
import { StoreComponent } from './ui/web/content/shop/store/store.component';
import { StoreCardComponent } from './ui/web/content/shop/store/store-card/store-card.component';
import { EditPostComponent } from './ui/dashboard/post/edit-post/edit-post.component';
import { AlertDialogComponent } from './ui/common/alert-dialog/alert-dialog.component';
import { StoreEditProductComponent } from './ui/dashboard/seller/store-edit-product/store-edit-product.component';
import { SellerDashboardComponent } from './ui/dashboard/seller/seller-dashboard/seller-dashboard.component';

const firebaseConfig = {
  apiKey: "AIzaSyBjN_q3kGUTpF89RRl2R-DLwVNeoqMaM40",
  authDomain: "butterflymom-1652c.firebaseapp.com",
  databaseURL: "https://butterflymom-1652c.firebaseio.com",
  projectId: "butterflymom-1652c",
  storageBucket: "butterflymom-1652c.appspot.com",
  messagingSenderId: "666961109233",
  appId: "1:666961109233:web:8ab032e8a7e8daadf24950",
  measurementId: "G-ER20VEP8K2"
};

@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    TitlebarComponent,
    MenuComponent,
    HeaderCarouselComponent,
    ContentComponent,
    HomeComponent,
    RecentBlogsComponent,
    MeCardComponent,
    PopularPostsComponent,
    BlogTagsComponent,
    DashboardComponent,
    NewPostComponent,
    ManagePostsComponent,
    AccordionComponent,
    OverviewComponent,
    ViewsComponent,
    OrdersComponent,
    SettingsComponent,
    UsersComponent,
    AdminLoginComponent,
    UserLoginComponent,
    SignIpComponent,
    BlogPostComponent,
    BlogComponent,
    ReviewsComponent,
    ForgotPasswordComponent,
    AdminLoginErrorComponent,
    DropzoneDirective,
    UploaderComponent,
    UploadTaskComponent,
    ProfileComponent,
    AdComponent,
    SubscribeComponent,
    FooterComponent,
    BlogCardComponent,
    EllipsisPipe,
    PublishedPostComponent,
    PageNotFoundComponent,
    PostComponent,
    SellerComponent,
    StoreAddProductComponent,
    StoreManageComponent,
    StoreOrdersComponent,
    AfStoreComponent,
    AfStoreAddProductComponent,
    AfStoreManageProductsComponent,
    AfStoreOrdersComponent,
    TooltipDirective,
    TooltipComponent,
    ButteflyAnimationComponent,
    SpinnerComponent,
    ErrorLoadingComponent,
    NoRecordsComponent,
    ShopComponent,
    DownloadsComponent,
    AffiliateStoreComponent,
    AfCardComponent,
    AfPdpComponent,
    AfLegacyAddProductComponent,
    CommentsRepliesComponent,
    CommentCardComponent,
    RightBarComponent,
    EventsComponent,
    UpcomingEventComponent,
    EventDetailsComponent,
    ReventRegistrationComponent,
    NodomsanitizePipe,
    SuggestionsComponent,
    BlogTreeComponent,
    TagResultsComponent,
    FollowMeComponent,
    BuyFromAmazonComponent,
    TestCkeComponent,
    ImageCarouselComponent,
    PostsSettingsComponent,
    ReviewSettingsComponent,
    AffiliateSettingsComponent,
    ModerationComponent,
    HomeSliderComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    AboutComponent,
    DomSanitizePipe,
    StoreComponent,
    StoreCardComponent,
    EditPostComponent,
    AlertDialogComponent,
    StoreEditProductComponent,
    SellerDashboardComponent
  ],
  imports: [
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ClipboardModule,
    OverlayModule,
    IvyCarouselModule,
    QuillModule.forRoot(),
    CKEditorModule
  ],
  providers: [AuthService, Title, Meta, ScreenTrackingService],
  bootstrap: [AppComponent],
  entryComponents: [TooltipComponent]
})
export class AppModule { }
