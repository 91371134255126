import { Component, Input, OnInit } from '@angular/core';
import { LocalPost } from 'src/app/models/classes/posts';
import { PostsService } from 'src/app/services/post/posts.service';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css']
})
export class SuggestionsComponent implements OnInit {

  @Input() isBlog:boolean = false;
  @Input() category:string = 'Other';
  @Input() limit:number = 5;
  postItems:LocalPost[] = []
  showLoader:boolean = true;
  showNoRecords:boolean = false;
  showError:boolean = false;

  constructor(private postsService:PostsService) { }

  ngOnInit(): void {
    this.postsService.getPublishedItemsByCategory(this.isBlog?'Blog':'Review',this.category, this.limit).then((snapshot)=>{
      if(snapshot.size > 0){
        snapshot.forEach((data)=>{
          let lp = <LocalPost>{uid:data.id,post:data.data()}
          this.postItems.push(lp);
        });
      }
      else{
        this.showNoRecords = true;
      }
      this.showLoader = false;
    }).catch(err=>{
      this.showLoader = false;
      this.showError = false;
      console.error(err);
    });
  }

}
