<div class="w3-container w3-margin w3-padding">
  <div class="w3-row-padding">
    <p class="w3-text-black w3-large"><b>Manage Post</b></p>
    <p class="w3-text-grey">Edit / Publish / Unpublish / Schedule Posts</p>
  </div>
  <div class="w3-row w3-margin-top">
    <div class="w3-row">
      <h6>Filter</h6>
      <form [formGroup]="filterAndSort" class="w3-container">
        <div class="w3-padding">
          <div class="w3-row">
            <div class="w3-col s4 m3 l3 w3-padding">
              <label class="w3-text-purple">Status</label>
              <select class="w3-select w3-border w3-border-purple w3-round-large" id="status" formControlName="status">
                <option *ngFor="let status of statuses" [ngValue]="status.value">{{status.item}}
                </option>
              </select>
            </div>
            <div class="w3-col s4 m3 l3 w3-padding">
              <label class="w3-text-purple">Author</label>
              <select class="w3-select w3-border w3-border-purple w3-round-large" id="creator"
                formControlName="creator">
                <option value="All" selected>All</option>
                <option value="Admin">Admin</option>
                <option value="Editor">Editor</option>
              </select>
            </div>
          </div>
        </div>
      </form>
    </div>
    <table class="w3-table w3-striped">
      <thead class="w3-purple">
        <tr>
          <th>Sl No</th>
          <th>Title</th>
          <th>Status</th>
          <th>Created On</th>
          <th>Last Updated On</th>
          <th>Created By</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let localPost of localPosts; let i = index" [@fadeInOut]>
          <td>{{i+1}}</td>
          <td>{{localPost.post!.title}}</td>
          <td>{{getStatusText(localPost.post!.status!)}}</td>
          <td>{{localPost.post!.createdDate | date:'short'}}</td>
          <td>{{localPost.post!.updatedDate | date:'short'}}</td>
          <td>{{localPost.post!.author}} ({{localPost.post!.authorType}})</td>
          <td>
            <a tooltip="View this post" class="w3-button w3-circle w3-lavendar w3-margin-right" href="/web/blog/post?title={{localPost.post!.urlTitle}}" target="_blank"><i
                class="fa fa-eye" aria-hidden="true"></i></a>
            <button class="w3-button w3-circle w3-yellow w3-margin-right" tooltip="Edit this post"
              (click)="edit(localPost)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
            <button *ngIf="localPost.post!.status != 1" tooltip="Publish this post"
              class="w3-button w3-circle w3-green w3-margin-right" (click)="publish(localPost.uid, localPost.post!.category!)"><i
                class="fa fa-check-circle" aria-hidden="true"></i></button>
            <button *ngIf="localPost.post!.status == 1 || localPost.post!.status == 3" tooltip="Unpublish this post"
              class="w3-button w3-circle w3-orange w3-margin-right" (click)="unPublish(localPost.uid, localPost.post!.category!)"><i class="fa fa-ban"
                aria-hidden="true"></i></button>
            <button *ngIf="localPost.post!.status != 1" tooltip="Schedule this post"
              class="w3-button w3-circle w3-blue w3-margin-right"><i class="fa fa-clock-o"
                aria-hidden="true"></i></button>
            <button tooltip="Delete this post"
              class="w3-button w3-circle w3-red w3-margin-right" (click)="deletePost(localPost.uid, localPost.post!.category!, localPost.post!.status!)" ><i class="fa fa-trash"
                aria-hidden="true"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="w3-center" *ngIf="showLoader">
      <spinner></spinner>
    </div>
    <div class="w3-center" *ngIf="showNoRecords">
      <no-records></no-records>
    </div>
    <div class="w3-center" *ngIf="showError">
      <error-loading></error-loading>
    </div>
  </div>
</div>
