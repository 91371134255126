import { Component, Input, OnInit } from '@angular/core';
import { Comment } from 'src/app/models/classes/Comments';

@Component({
  selector: 'app-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.css']
})
export class CommentCardComponent implements OnInit {

  @Input('isCommentParent') isCommentParent:boolean = true;
  @Input("comment") comment!:Comment;
  constructor() { }

  ngOnInit(): void {
  }
}
