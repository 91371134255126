<header *ngIf="images" class="w3-wide w3-bottombar w3-border-purple" style="min-height:100px" [ngClass]="{'w3-hide-small': deviceInfo.deviceType == 'desktop'}" [ngClass]="{'w3-hide-medium w3-hide-large': deviceInfo.deviceType != 'desktop'}">
  <div class="w3-display-container" [ngClass]="className" *ngFor="let item of images">
    <img *ngIf="deviceInfo.deviceType == 'desktop' && !showBoth" src="{{item.desktopSrc}}" alt="{{item.title}}" width="100%">
    <img *ngIf="deviceInfo.deviceType != 'desktop' && !showBoth" src="{{item.mobileSrc}}" alt="{{item.title}}" width="100%">
    <img *ngIf="showBoth" src="{{item.mobileSrc}}" alt="{{item.title}}" width="100%">
    <div [ngClass]="'w3-display-'+item.positionClass" style="font-family:Georgia, 'Times New Roman', Times, serif" [ngStyle]="{'margin-left': item.positionClass.includes('left') ? '50px' : '', 'margin-right': item.positionClass.includes('right') ? '50px' : ''}">
      <div class="w3-panel" [ngClass]="'w3-animate-'+item.animationClass" [ngStyle]="{'text-align': item.positionClass.includes('middle') ? 'center': item.positionClass.includes('right') ? 'right' : ''}">
        <h1 class="w3-text-shadow" [ngClass]="'w3-text-'+item.titleColor" style="opacity: 1;">{{item.title}}</h1>
        <h5 [ngClass]="'w3-text-'+item.subtitleColor" style="opacity: 1;">{{item.subTitle}}</h5>
      </div>
    </div>
  </div>
</header>
