import { PostStatus } from "../enums/poststatus";
import { Target } from "../enums/target";

export class Post {
    title?: string = '';
    urlTitle?: string = '';
    postImages?:string[] = [];
    searchHelp?: string[] = [];
    titleImage?: string = '';
    imageAlt?: string = '';
    description?: string = '';
    category?: string = '';
    subCategory?: string = '';
    author?: string = '';
    authorType?: string = '';
    html?: string = '';
    tags?: string[] = [];
    target?: number = 0;
    disableComments?: boolean = false;
    createdDate?: number = 0;
    updatedDate?: number = 0
    status?: number = PostStatus.New;
    likes?: string[] = [];
    isTop?: boolean = false;
    relatedAmazonProducts?:string = "";
    relatedSellingItems?:string = "";
    otherlinks?:string = "";

    constructor(title: string, imageUrl: string, imageAlt: string, description: string, category: string, subCategory: string,
        author: string, authorType: string, html: string, tags: string, target: string, disableComments: boolean,
        createdDate: number, status: number, likes: string[], isTop: boolean, postImages:string[],
        relatedAmazonProducts:string,relatedSellingItems:string, otherlinks:string) {
            this.setTitle(title);
            this.setTitleImage(imageUrl);
            this.setImageAlt(imageAlt);
            this.setDescription(description);
            this.setCategory(category);
            this.setSubCategory(subCategory);
            this.setAuthor(author);
            this.setAuthorType(authorType);
            this.setHtml(html);
            this.setTags(tags);
            this.setTarget(target);
            this.setDisableComments(disableComments);
            this.setCreatedDate(createdDate);
            this.setUpdatedDate();
            this.setStatus(status);
            this.setLikes(likes);
            this.setIsTop(isTop);
            this.postImages = postImages || [];
            this.relatedAmazonProducts = relatedAmazonProducts || "";
            this.otherlinks = otherlinks || "";
            this.relatedSellingItems = relatedSellingItems || "";
    }

    setLikes(likes:string[])
    {
        this.likes = likes;
    }

    setTitle(title: string) {
        this.title = title;
        this.setUrlTitle();
        this.seteSearchHelp();
    }

    setUrlTitle() {
        this.urlTitle = this.title!.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
    }

    seteSearchHelp() {
        let len = this.title?.length;
        let chars = this.title?.split('');
        let array: string[] = [];
        let temp: string = "";
        for (var i = 0; i < len!; i++) {
            temp = temp + String(chars![i]);
            if (i > 3 && i < 15) {
                array.push(temp);
            }
        }
        this.searchHelp = array;
    }

    setTitleImage(imageUrl: string) {
        this.titleImage = imageUrl;
    }

    setImageAlt(altText: string) {
        this.imageAlt = altText;
    }

    setDescription(desc: string) {
        this.description = desc;
    }

    setCategory(category: string) {
        this.category = category;
    }

    setSubCategory(subcategory: string) {
        this.subCategory = subcategory;
    }

    setAuthor(author: string = "Butterfly Mom") {
        this.author = author;
    }

    setAuthorType(authorType: string = "Admin") {
        this.authorType = authorType;
    }

    setHtml(html: string) {
        this.html = html;
    }

    setTags(tags: string) {
        this.tags = tags.split(',');
    }

    setTarget(target: string = "any") {
        switch (target) {
            case "any":
                this.target = Target.any;
                break;
            case "subscriber":
                this.target = Target.subscriber;
                break;
            case "special":
                this.target = Target.specialSubsccriber;
                break;
            case "premium":
                this.target = Target.premiumSubscriber;
                break;
            default:
                this.target = Target.any;
                break;
        }
    }

    setDisableComments(comments: boolean = false) {
        this.disableComments = comments;
    }

    setCreatedDate(date:number = 0) {
        this.createdDate = date == 0 ? Date.now() : date;
    }

    setUpdatedDate(date: number = 0) {
        this.updatedDate = date == 0 ? Date.now() : date;
    }

    setStatus(status: PostStatus) {
        this.status = status;
    }

    setIsTop(isTop: boolean = false) {
        this.isTop = isTop;
    }

    isEqual(post: Post) {
        return this.title === post.title && this.titleImage === post.titleImage && this.imageAlt === post.imageAlt
            && this.description === post.description && this.category === post.category && this.subCategory === post.subCategory && this.author === post.author
            && this.authorType === post.authorType && this.html === post.html && this.tags?.join(",") === post.tags?.join(",") && this.target === post.target
            && this.disableComments === post.disableComments && this.isTop === post.isTop
    }

    isEmpty() {
        return this.title?.length == 0;
    }

    toJson() {
        return JSON.parse(JSON.stringify(this));
    }

    getUpdatedPostJson(existingPost: Post) {
        let json = JSON.parse(JSON.stringify(this));
        console.log(JSON.stringify(json));

        if (this.title == existingPost.title) {
            delete json['title'];
            delete json['urlTitle'];
            delete json['searchHelp'];
        }
        if (this.titleImage == existingPost.titleImage) {
            delete json['titleImage'];
        }
        if (this.imageAlt == existingPost.imageAlt) {
            delete json['imageAlt'];
        }
        if (this.description == existingPost.description) {
            delete json['description'];
        }
        if (this.category == existingPost.category) {
            delete json['category'];
        }
        if (this.subCategory == existingPost.subCategory) {
            delete json['subCategory'];
        }
        if (this.author == existingPost.author) {
            delete json['author'];
        }
        if (this.authorType == existingPost.authorType) {
            delete json['authorType'];
        }
        if (this.html == existingPost.html) {
            delete json['html'];
        }
        if (this.tags == existingPost.tags) {
            delete json['tags'];
        }
        if (this.target == existingPost.target) {
            delete json['target'];
        }
        if (this.disableComments == existingPost.disableComments) {
            delete json['disableComments'];
        }
        if (this.isTop == existingPost.isTop) {
            delete json['isTop'];
        }
        console.log("JSON : \n" + JSON.stringify(json));
        return json;
    }

    getHtml()
    {
        return this.html;
    }
}

export class LocalPost {
    uid?: string = '';
    post?: Post = undefined;
}
