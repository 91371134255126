import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Component, OnInit } from '@angular/core';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-test-cke',
  templateUrl: './test-cke.component.html',
  styleUrls: ['./test-cke.component.css']
})
export class TestCkeComponent implements OnInit {
  editor = ClassicEditor;
  editorData = '<p>Hello, world!</p>';
  constructor() { }

  public onChange( { editor }: ChangeEvent ) {
    const data = editor.getData();

    console.log( data );
}

  ngOnInit(): void {
  }

}
