import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-sign-ip',
  templateUrl: './sign-ip.component.html',
  styleUrls: ['./sign-ip.component.css']
})
export class SignIpComponent implements OnInit {

  constructor(public authService: AuthService, private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Sign Up - Butterfly Mom");
  }

}
