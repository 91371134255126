import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { slider_image } from 'src/app/models/interfaces/sliders';

@Component({
  selector: 'app-header-carousel',
  templateUrl: './header-carousel.component.html',
  styleUrls: ['./header-carousel.component.css']
})
export class HeaderCarouselComponent implements AfterViewInit, OnInit {
  myIndex:number = 0;
  myIndexMobile:number = 0;
  deviceInfo!: DeviceInfo;
  @Input() showBoth:boolean = false;
  @Input() images: slider_image[] = [];
  className:string = this.makeid(10);
  constructor(@Inject(DOCUMENT) private doc: Document, private deviceDetectorService: DeviceDetectorService) {

  }
  ngOnInit(): void {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
  }

  ngAfterViewInit(): void {
    this.carousel();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
  }

  carousel() {
    var i: number;
    var x = this.doc.getElementsByClassName(this.className) as HTMLCollectionOf<HTMLElement>;
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    this.myIndex++;
    if (this.myIndex > x.length) { this.myIndex = 1 }
    if(x[this.myIndex - 1])
      x[this.myIndex - 1].style.display = "block";
    setTimeout(()=>{
      this.carousel();
    }, this.images[this.myIndex-1].duration*1000);
  }

  makeid(length: number) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
}
