<div class="w3-container w3-padding">
  <!-- <div class="dropzone w3-lavendar w3-border w3-border-purple w3-hover-white" appDropzone (dropped)="onDrop($event)">
    <h6>Drag and Drop Images</h6>
    <p class="w3-small">(PNG/JPG/JPEG/GIF)</p>
    <h6>Or</h6>
    <input type="file" ng-file-model="files" id="inputFiles" name="inputFiles" multiple class="inputfile" (change)="uploadFile($event)"/>
    <label for="inputFiles" class="w3-btn w3-pink">Choose image files</label>
  </div> -->
  <input type="file" ng-file-model="files" id="inputFiles" name="inputFiles" appDropzone (dropped)="onDrop($event)"
    multiple class="inputfile" (change)="uploadFile($event)" />
  <label for="inputFiles" class="w3-center w3-btn w3-lavendar bigarea w3-hover-lavendar">
    <p class="w3-clear"></p>
    <p class="w3-clear"></p>
    <p class="w3-small">Drop or upload {{exts.join('/') | uppercase}}</p>
  </label>
  <br>
  <div *ngIf="files.length > 0">
    <div *ngFor="let file of files; index as i">
      <upload-task [file]="file" (percentValueEvent)="addPercentage($event)" [folder]="folder" [fileNo]="i+1" [totalFiles]="files.length">
      </upload-task>
    </div>
    <!-- <h4>Uploaded Files</h4>
    <div class="w3-row w3-container w3-border w3-border-pink w3-margin-top">
      <ul id="thumbnailsList">
        <li *ngFor="let file of files; index as i" class="w3-margin w3-card-4">
          <upload-task [file]="file" [fileNo]="i+1" [totalFiles]="files.length"></upload-task>
        </li>
      </ul>
    </div> -->
  </div>
  <div class="w3-row w3-margin-top">
    <div class="w3-light-grey">
      <div class="w3-container w3-deep-purple w3-center" [ngStyle]="{'width': percentage.toFixed() + '%'}">
        {{percentage.toFixed()}}%</div>
    </div>
  </div>
