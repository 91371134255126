import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { slider_image } from 'src/app/models/interfaces/sliders';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.css']
})
export class HomeSliderComponent implements OnInit {
  FOLDER = 'home-slider';
  fg:FormGroup;
  showLoader:boolean = true;
  slides:slider_image[] = [];
  colors:string[] = ["red","pink","purple", "deep-purple","indigo","blue","light-blue","cyan","aqua","teal","green","light-green","lime","sand","khaki","yellow","amber","orange","deep-orange","blue-gray","brown","light-gray","gray","dark-gray","pale-red","pale-yellow","pale-green","pale-blue"]
  animationTypes:string[]=["top","bottom","left","right","opacity","zoom","fading","spin"]
  positionTypes:string[]=["topleft","topright","bottomleft","bottomright","left","right","middle","topmiddle","bottommiddle"]
  fileExts = ['png', 'jpg', 'jpeg', 'gif'];
  desktopImage:string = "";
  mobileImage:string = "";

  images: slider_image[] = [];

  constructor(private fb: FormBuilder, private stateService:StateService) {
    this.fg = fb.group({
      indexNo: new FormControl(1),
      title: new FormControl('sdfasf'),
      titleColor: new FormControl('red'),
      subtitle: new FormControl('fasdfas'),
      subtitleColor: new FormControl('green'),
      seconds: new FormControl(3),
      animation: new FormControl('left'),
      position: new FormControl('right'),
      imageLinkD: new FormControl(''),
      imageLinkM: new FormControl('')
    });
  }

  ngOnInit(): void {

  }

  getFormData() {
    const slideData:slider_image = {
      index:this.fg.controls['indexNo'].value,
      title:this.fg.controls['title'].value,
      desktopSrc:this.desktopImage,
      mobileSrc:this.mobileImage,
      titleColor: this.fg.controls['titleColor'].value,
      subTitle: this.fg.controls['subtitle'].value,
      subtitleColor: this.fg.controls['subtitleColor'].value,
      duration: this.fg.controls['seconds'].value,
      animationClass: this.fg.controls['animation'].value,
      positionClass:this.fg.controls['position'].value,
    };
    return slideData;
  }

  addSlide(){
    this.images.push(this.getFormData());
    this.desktopImage = "";
    this.mobileImage = "";
    console.log(this.images);
  }

  refreshDesktopImageUrl() {
    this.desktopImage = this.stateService.getData();
    this.stateService.clearData();
  }

  refreshMobileImageUrl() {
    this.mobileImage = this.stateService.getData();
    this.stateService.clearData();
  }
}
