export enum UserRoles {
    CUSTOMER = 0,
    ADMIN = 1,
    BLOG_EDITOR = 2,
    MODERATOR = 3,
    SELLER_ADMIN = 4,
    SELLER_OTHER = 5,
    COURSE_ADMIN = 6,
    COURSE_PROVIDER = 7,
    EVENT_ADMIN = 8,
    EVENT_PROVIDER = 9
}
