<div class="">
  <div class="w3-panel w3-white w3-bottombar w3-border-purple w3-text-purple">
    <h3>Downloads</h3>
  </div>
  <div class="w3-row" style="margin-top:-30px;">
    <form class="w3-container w3-text-white">
      <p class="w3-medium">Filter</p>
      <div class="w3-col s12 m6 l4 w3-padding">
        <label>Category</label>
        <select class="w3-select w3-border w3-border-purple w3-round-large" id="category" formControlName="category">
          <option value="" selected>All</option>
          <!-- <option *ngFor="let category of categories" [ngValue]="category">{{category}}</option> -->
        </select>
      </div>
      <div class="w3-col s12 m6 l4 w3-padding">
        <label>Age Group</label>
        <select class="w3-select w3-border w3-border-purple w3-round-large" id="agegroup" formControlName="agegroup">
          <option value="-1" selected>All</option>
          <!-- <option *ngFor="let group of ageGroups" [ngValue]="group">{{group}} - {{group+1}}</option> -->
        </select>
      </div>
    </form>
  </div>
  <div class="w3-white">
    <hr style="border-top: 1px solid purple;">
    <!-- <spinner *ngIf="showLoader"></spinner>
  <no-records *ngIf="showNoRecords"></no-records>
  <error-loading *ngIf="showError"></error-loading> -->
    <div class="w3-row" style="min-height: 300px;">
      <div class="w3-col l3 m4 s6 w3-padding">
        <div class="pcContianer w3-card-4">
          <div class="pcHeader w3-row w3-padding">
            <div class="w3-left">
              <p>Title</p>
              <p class="w3-small w3-text-grey">&emsp;SubTitle</p>
            </div>
            <div class="w3-right">
              <p>
                <span class="w3-tag w3-purple w3-small">10%</span>
                <button class="w3-button w3-text-red w3-large"><i class="fa fa-heart"></i></button>
              </p>
            </div>
          </div>
          <div class="pcImage w3-row">
            <div class="w3-image">
              <img src="../../../../../../assets/images/slider/1.png" width="100%" height="200px">
            </div>
          </div>
          <div class="pcContent w3-row w3-container" style="word-wrap: break-word;">
            <p>jsfghasfkjsafgsdfgjkasdfjkasbdkasdkasdbkasgdfkhasgdfjkasbdkfjbasdkjf</p>
          </div>
          <div class="pcFooter w3-row w3-padding">
            <button class="w3-btn w3-pink w3-right">Download</button>
          </div>
        </div>
      </div>
      <div class="w3-col l3 m4 s6 w3-padding">
        <div class="pcContianer w3-card-4">
          <div class="pcHeader w3-row w3-padding">
            <div class="w3-left">
              <p>Title</p>
              <p class="w3-small w3-text-grey">&emsp;SubTitle</p>
            </div>
            <div class="w3-right">
              <p>
                <span class="w3-tag w3-purple w3-small">10%</span>
                <button class="w3-button w3-text-red w3-large"><i class="fa fa-heart"></i></button>
              </p>
            </div>
          </div>
          <div class="pcImage w3-row">
            <div class="w3-image">
              <img src="../../../../../../assets/images/slider/1.png" width="100%" height="200px">
            </div>
          </div>
          <div class="pcContent w3-row w3-container" style="word-wrap: break-word;">
            <p>jsfghasfkjsafgsdfgjkasdfjkasbdkasdkasdbkasgdfkhasgdfjkasbdkfjbasdkjf</p>
          </div>
          <div class="pcFooter w3-row w3-padding">
            <button class="w3-btn w3-pink w3-right">Download</button>
          </div>
        </div>
      </div>
      <div class="w3-col l3 m4 s6 w3-padding">
        <div class="pcContianer w3-card-4">
          <div class="pcHeader w3-row w3-padding">
            <div class="w3-left">
              <p>Title</p>
              <p class="w3-small w3-text-grey">&emsp;SubTitle</p>
            </div>
            <div class="w3-right">
              <p>
                <span class="w3-tag w3-purple w3-small">10%</span>
                <button class="w3-button w3-text-red w3-large"><i class="fa fa-heart"></i></button>
              </p>
            </div>
          </div>
          <div class="pcImage w3-row">
            <div class="w3-image">
              <img src="../../../../../../assets/images/slider/1.png" width="100%" height="200px">
            </div>
          </div>
          <div class="pcContent w3-row w3-container" style="word-wrap: break-word;">
            <p>jsfghasfkjsafgsdfgjkasdfjkasbdkasdkasdbkasgdfkhasgdfjkasbdkfjbasdkjf</p>
          </div>
          <div class="pcFooter w3-row w3-padding">
            <button class="w3-btn w3-pink w3-right">Download</button>
          </div>
        </div>
      </div>
      <div class="w3-col l3 m4 s6 w3-padding">
        <div class="pcContianer w3-card-4">
          <div class="pcHeader w3-row w3-padding">
            <div class="w3-left">
              <p>Title</p>
              <p class="w3-small w3-text-grey">&emsp;SubTitle</p>
            </div>
            <div class="w3-right">
              <p>
                <span class="w3-tag w3-purple w3-small">10%</span>
                <button class="w3-button w3-text-red w3-large"><i class="fa fa-heart"></i></button>
              </p>
            </div>
          </div>
          <div class="pcImage w3-row">
            <div class="w3-image">
              <img src="../../../../../../assets/images/slider/1.png" width="100%" height="200px">
            </div>
          </div>
          <div class="pcContent w3-row w3-container" style="word-wrap: break-word;">
            <p>jsfghasfkjsafgsdfgjkasdfjkasbdkasdkasdbkasgdfkhasgdfjkasbdkfjbasdkjf</p>
          </div>
          <div class="pcFooter w3-row w3-padding">
            <button class="w3-btn w3-pink w3-right">Download</button>
          </div>
        </div>
      </div>
    </div>
  </div>
