import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-me-card',
  templateUrl: './me-card.component.html',
  styleUrls: ['./me-card.component.css']
})
export class MeCardComponent implements OnInit {

  @Input() photoUrl:string = "../../../assets/images/admin.jpg";
  @Input() name:string = "Sindhu J Jaisha";
  @Input() description:string = "Just me, myself and I, exploring motherhood. I have a heart of love and a interest to make kids grow intellectually. By this blog I want to share my world with you.";

  constructor() { }

  ngOnInit(): void {
  }

}
