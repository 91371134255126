import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { slider_image } from 'src/app/models/interfaces/sliders';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {

  constructor(private titleService: Title, private metaService: Meta) { }
  ngAfterViewInit(): void {
  }

  images: slider_image[] = [
    {
      index:1,
      desktopSrc:"../../../../assets/images/slider/1.png",
      mobileSrc: "../../../../assets/images/slider/mobile/1.png",
      title: 'Parenting',
      titleColor: 'pink',
      subTitle: 'Helping parents to guide kids',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'right',
      positionClass: 'left'
    },
    {
      index:2,
      desktopSrc:"../../../../assets/images/slider/2.png",
      mobileSrc: "../../../../assets/images/slider/mobile/2.png",
      title: 'Recitals',
      subTitle: 'Learn by way of stories',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'top',
      positionClass: 'middle'
    },
    {
      index:3,
      desktopSrc:"../../../../assets/images/slider/3.png",
      mobileSrc: "../../../../assets/images/slider/mobile/3.png",
      title: 'Health & Cooking',
      subTitle: 'Healthify your life',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'bottom',
      positionClass: 'middle'
    },
    {
      index:4,
      desktopSrc:"../../../../assets/images/slider/4.png",
      mobileSrc: "../../../../assets/images/slider/mobile/4.png",
      title: 'Gardening',
      subTitle: 'Grow your own food',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'right',
      positionClass: 'left'
    },
    {

      index:5,
      desktopSrc:"../../../../assets/images/slider/5.png",
      mobileSrc: "../../../../assets/images/slider/mobile/5.png",
      title: 'Reviews',
      subTitle: 'Get to know what is better',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'right',
      positionClass: 'left'
    },
    {
      index:6,
      desktopSrc:"../../../../assets/images/slider/6.png",
      mobileSrc: "../../../../assets/images/slider/mobile/6.png",
      title: 'Theme & Activities',
      subTitle: 'Perform activities with themes',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'left',
      positionClass: 'right'
    },
    {
      index:7,
      desktopSrc:"../../../../assets/images/slider/7.png",
      mobileSrc: "../../../../assets/images/slider/mobile/7.png",
      title: 'Toys',
      subTitle: 'What all toys can help your kids',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'right',
      positionClass: 'left'
    },
    {
      index:8,
      desktopSrc:"../../../../assets/images/slider/8.png",
      mobileSrc: "../../../../assets/images/slider/mobile/8.png",
      title: 'Arts & Crafts',
      subTitle: 'Bring out the best in you',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'bottom',
      positionClass: 'middle'
    },
    {
      index:9,
      desktopSrc:"../../../../assets/images/slider/9.png",
      mobileSrc: "../../../../assets/images/slider/mobile/9.png",
      title: 'Decorations & Celebrations',
      subTitle: 'Make your family happy',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'right',
      positionClass: 'left'
    },
    {
      index:10,
      desktopSrc:"../../../../assets/images/slider/10.png",
      mobileSrc: "../../../../assets/images/slider/mobile/10.png",
      title: 'Printables',
      subTitle: 'Let the kids learn with drawings',
      titleColor: 'pink',
      subtitleColor: 'green',
      duration: 3,
      animationClass: 'left',
      positionClass: 'right'
    },
  ];

  ngOnInit(): void {
    this.titleService.setTitle("Home - Butterfly Mom");
  }



}
