import { AfterViewInit, ElementRef } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { LegacyProduct } from 'src/app/models/classes/LegacyProduct';
import { LegacyAffiliateService } from 'src/app/services/legacy-affiliate/legacy-affiliate.service';

@Component({
  selector: 'app-af-legacy-add-product',
  templateUrl: './af-legacy-add-product.component.html',
  styleUrls: ['./af-legacy-add-product.component.css']
})
export class AfLegacyAddProductComponent implements OnInit, AfterViewInit {
  successFlag:boolean = false;
  fg:FormGroup;
  productHtml: string = "<p>Preview will be displayed here...</p>";
  ageGroups:number[] = [0,1,2,3,4];
  categories:string[] = ["Toys & Games", "Books", "Gadgets", "Essentials"];
  initialData!:LegacyProduct;

  @ViewChild("addBtn") addBtn!: ElementRef; 

  constructor(fb:FormBuilder, public sanitizer: DomSanitizer, private legacyAffiliateService: LegacyAffiliateService) {
    this.fg = fb.group({
      title:new FormControl('',[Validators.required]),
      category:new FormControl('',[Validators.required]),
      agegroup:new FormControl(-1,[Validators.required]),
      product:new FormControl(this.productHtml,[Validators.required])
    });
    this.initialData = this.getData();
   }
  ngAfterViewInit(): void {
    this.fg.valueChanges.subscribe(()=>{
      let flag = this.getData().isEqual(this.initialData);
      console.log(flag);
      this.addBtn.nativeElement.style.disabled = flag;
    });
  }

  ngOnInit(): void {
    
  }

  getData(){
    return new LegacyProduct(this.fg.controls['title'].value, this.fg.controls['category'].value, this.fg.controls['agegroup'].value, this.fg.controls['product'].value);
  }

  addProduct(){
    this.legacyAffiliateService.addProduct(this.getData()).then(data =>{
      console.log(data.id);
      this.successFlag = true;
      setTimeout(()=>{
        this.fg.reset();
        this.fg.controls['agegroup'].setValue(-1);
        this.fg.controls['product'].setValue(this.productHtml);
        this.successFlag = false;
      }, 1000);
    }).catch(err =>{
      console.error(err);
    });
  }
}
