<div class="w3-white" style="min-height: 550px;">
  <div class="w3-panel w3-bottombar w3-border-purple w3-text-purple">
    <h3>Privacy Policy</h3>
  </div>
  <div class="w3-container w3-justify">
    <h3>What does this policy cover?</h3>
    <p>Welcome to ButterflyMom, the online and mobile service of ButterflyMom<sup>&reg;</sup> (ButterflyMom,” “we,” or
      “us”).
      Our Privacy Policy explains how we collect, use, disclose, and protect information that applies to our Service,
      and your
      choices about the collection and use of your information. If you do not want your information processed in
      accordance with this
      Privacy Policy in general or any part of it, you should not use our online or mobile services.</p>
    <p>Below are our policies.</p>
    <em>Summary: This policy sets out how ButterflyMom collects and uses the information that we collect about you when
      you use the ButterflyMom services.
      This policy also explains the choices that you can make about the way that we use your information.</em>
    <h4>1. Information we collect and its use</h4>
    <p>We collect the following types of information about you:</p>
    <h5>(a) Information you provide us directly</h5>
    <p>We may ask for certain information when you register for a ButterflyMom account or correspond with us (such as a
      username, your first and last names, birthdate, phone number, profession and e-mail address).</p>
    <p>We also collect any messages you send us through the Service, and may collect information you provide in User
      Content you post to the Service (such as text and photos you upload to use in your designs or posts in our
      Community). We use this information to operate, maintain, and provide the features and functionality of the
      Service to you, to correspond with you, and to address any issues you raise about the Service.</p>
    <p>If you don’t provide your personal information to us, you may not be able to access or use our Service or your
      experience of using our Service may not be as enjoyable.</p>
    <em>
      Summary: If you give us info about you, we will keep it and use it to provide our Service and make our service
      better.</em>
    <h5>(b) Information we receive from third parties</h5>
    <p>We may receive information about you from third parties.
      For example, if you access our websites or Service through a third-party connection or log-in, for example,
      through Facebook Connect, by “following,” “liking,” adding the ButterflyMom application, linking your account to
      the
      ButterflyMom Service, etc., that third party may pass certain information about your use of its service to
      ButterflyMom. This
      information could include, but is not limited to, the user ID associated with your account (for example, your
      Facebook UID), an access token necessary to access that service, any information that you have permitted the third
      party to share with us, and any information you have made public in connection with that service. You should
      always review, and if necessary, adjust your privacy settings on third-party websites and services before linking
      or connecting them to the ButterflyMom Service. You may also unlink your third party account from the Service by
      adjusting your settings on the third party service. If you unlink your third party account, we will no longer
      receive information collected about you in connection with that service. We may also receive information about you
      and your engagement with our advertisements from our ad servers, ad networks and social media platforms. And if
      you choose to integrate with a third party application to incorporate elements into your designs, enhance media,
      supercharge your workflow or share to social media, we could receive data from such apps (as well as disclose to
      such apps as set out below).</p>
    <em>Summary: When you use our Service, third parties might give us some info about you, especially if you are signed
      into a third party account while using our Service. When you choose to link your social media account with our
      Service such as by logging in through Facebook or Gmail, your social media account provider (such as Google and
      Facebook) will give us some info about you such as your Facebook user identification number.</em>
    <h5>(c) Information we collect from you automatically</h5>
    <p>We will directly collect analytics data, or use third-party analytics tools, to help us measure traffic and usage
      trends for the Service. These tools collect information sent by your browser or mobile device, including the pages
      you visit and other information (in respect of which, please see the paragraph on log file information below) that
      assists us in improving the Service. For more information on the analytics cookies we use and how to opt out of
      third parties collecting this information, please see our Cookies Policy.</p>
    <em>Summary: To help us improve our service we keep info about how you use our service.</em>
    <h5>(d) Cookies information and information taken from similar technologiesy</h5>
    <p>When you visit the Service, we (and our third-party partners) will send cookies — small text files containing a
      string of alphanumeric characters — to your computer that uniquely identifies your browser and lets ButterflyMom
      do
      things like help you log in faster, enhance your navigation through the site, remembering your preferences and
      generally improve the user experience. Cookies also convey information to us about how you use the Service (e.g.,
      the pages you view, the links you click and other actions you take on the Service), and allow us or our business
      partners to track your usage of the Service over time. They also allow us to deliver personalized advertisements
      that may be of interest to you and measure their effectiveness.
    </p>
    <p>You can control or reset your cookies and similar technologies through your web browser, which will allow you to
      customise your cookie preferences and to refuse all cookies or to indicate when a cookie is being sent. However,
      some features of the Service may not function properly if the ability to accept cookies is disabled. For more
      information on how we use cookies and other technologies and how you can control them, please read our Cookies
      Policy.</p>
    <em>Summary: We will send cookies (not the yummy kind) to your web browser to help you use ButterflyMom and for
      other
      business purposes. For more information on how we use cookies and how you can control them, please see our Cookies
      Policy.</em>
    <!--<h5>(e) Log file information</h5>
      <p>Log file information is automatically reported by your browser or mobile device each time you access the Service. When you use our Service, our servers automatically record certain log file information. These server logs may include anonymous information such as your web request, Internet Protocol (“IP”) address, browser type, referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain names, landing pages, pages viewed, and other such information.</p>
      <em>Summary: Whenever you load a page from ButterflyMom, your browser sends us info about itself and your interactions with our Service. That info gets stored on our servers.</em>-->
    <h4>2. How we use your information</h4>
    <p>We use the information we collect about you for the purposes set out above which we have described in more detail
      below:</p>
    <ul>
      <li>
        <p>Providing you with the Service: We use the information that you provide us to provide the Service to you.
          This includes allowing you to log in to ButterflyMom, operating and maintaining the Service, giving you access
          to
          your designs and billing you for transactions that you make via the Service. We also use information we
          collect about you automatically to remember information about you so that you will not have to re-enter it
          during your visit or the next time you visit the site.</p>
      </li>
      <li>
        <p>Allowing you to participate in our Community of ButterflyMom Users so that you can view and share information
          in a
          forum about the Services and connect with other Users.</p>
      </li>
      <li>
        <p>For data analytics: We use information about you to help us improve the ButterflyMom Service and our users’
          experience, including by monitoring aggregate metrics such as total number of visitors, traffic, and
          demographic patterns.</p>
      </li>
      <li>
        <p>Customising the Service for you: We use information you provide us and information about you to make sure
          that your use of the Service is customised to your needs. For example, if you tell us your profession to
          recommend designs that are likely to be relevant to you.</p>
      </li>
      <li>
        <p>To communicate with you about the Service: We use your contact information to get in touch with you and to
          send communications about critical elements of the Service. For example, we may send you emails about
          technical issues, security alerts or administrative matters.</p>
      </li>
      <li>
        <p>To promote and drive engagement with the ButterflyMom Service: We use your contact information to get in
          touch with
          you about features and offers relating to the Service that we think you would be interested in. We also use
          information we collect about you to make sure that you get the most relevant offers and promotions based on
          your use of the Service, and your preferences. You can opt-out of getting these communications as described
          below.</p>
      </li>
      <li>
        <p>For advertising purposes: We use information about you, including cookies information and other information
          we (and our third-party partners) collect from you automatically, to serve and measure the effectiveness of
          advertising on the Service and third-party sites and platforms.</p>
      </li>
      <li>
        <p>Customer happiness: We use information about you, information that you provide to our customer happiness
          team, and information about your interactions with the Service to resolve technical issues you experience with
          the Service, and to ensure that we can repair and improve the Service for all ButterflyMom users.</p>
      </li>
      <li>
        <p>For security measures: We use information about you to monitor activity that we think is suspicious or
          potentially fraudulent, and to identify violations or this Privacy Policy or our Terms of Service.</p>
      </li>
      <li>
        <p>For matters that you have specifically consent to: From time to time ButterflyMom may seek your consent to
          use your
          information for a particular purpose. Where you consent to our doing so, we will use it for that purpose.
          Where you no longer want us to use your information for that purpose you may withdraw your consent to this
          use.</p>
      </li>
      <li>
        <p>For matters that we are required to use your information by law: ButterflyMom will use or disclose your
          information
          where we reasonably believe that such action is necessary to (a) comply with the law and the reasonable
          requests of law enforcement; (b) to enforce our Terms of Use or to protect the security or integrity of our
          Service; and/or (c) to exercise or protect the rights, property, or personal safety of ButterflyMom, our Users
          or
          others.</p>
      </li>
      <em>Summary: These are the reasons that ButterflyMom needs to use information about you.</em>
    </ul>
    <h4>3. Sharing your information</h4>
    <h5>(a) Who we share your information with</h5>
    <p>We share your information with third-party service providers for the purpose of providing the Service to you that
      is useful and safe and to facilitate ButterflyMom’s legitimate interests. Those service providers will only be
      provided with access to your information as is reasonably necessary for the purpose that ButterflyMom has engaged
      the service provider, and we will require that such third parties comply with this Privacy Policy, appropriate
      data processing terms and any applicable laws.</p>
    <p>Some of the third parties that ButterflyMom may share your personal information with include providers who assist
      ButterflyMom with functions such as:</p>
    <ul>
      <li>
        Billing
      </li>
      <li>Customer support and customer management</li>
      <li>Email services</li>
      <li>Hosting and storage</li>
      <li>Analytics</li>
      <li>Security</li>
      <li>Advertising and marketing services</li>
      <li>Delivery of physical products</li>
    </ul>
    <em>Summary: We might share some information on you with our business partners and third party service providers,
      but only as necessary to provide the service to you or to fulfil ButterflyMom’s legitimate business
      interests.</em>
    <h5>(b) Who you can choose to share your User Content with</h5>
    <p>The comments that you make on the platform are public and can be viewed by everyone.</p>
    <h5>(c) Sharing with other third parties</h5>
    <p>We dont not share Personally Identifiable Info to third parties</p>
    <h4>4. Ads on ButterflyMom</h4>
    <p>We do not use ads from Facebook, Google, LiveRamp and Appsflyer. We use only static ads from Amazon. We do have
      affiliate marketing pages for amazon.in. You may or may not tap those links to redirect to amazon.in. Note: A
      cookie will be placed in your browser on tapping those links.</p>
    <h4>5. How we transfer, store and protect your data</h4>
    <p>Your information collected through the Service will be stored and processed in United States, India or any other
      country. ButterflyMom transfers information that we collect about you, including personal information, to
      affiliated entities, and to other third parties across borders and from your country or jurisdiction to other
      countries or jurisdictions around the world. As a result, we may transfer information, including personal
      information, to a country and jurisdiction that does not have the same data protection laws as your jurisdiction.
      However, we always take steps to ensure that your information remains protected wherever it is stored and
      processed in accordance with applicable laws. Where required under applicable laws, you consent to the transfer of
      information to the U.S. or any other country in which ButterflyMom or its service providers maintain facilities
      and the use and disclosure of information about you as described in this Privacy Policy.</p>
    <em>Summary: To run our service we’ll have to use computers all over the world. This means your information might be
      transferred to the US, Australia, Europe and anywhere else the service is operated.</em>
    <h4>6. Keeping your information safe</h4>
    <p>ButterflyMom cares about the security of your information, and uses appropriate safeguards to preserve the
      integrity and security of all information collected through the Service. To protect your privacy and security, we
      take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to
      your account. You are responsible for maintaining the secrecy of your unique password and account information, and
      for controlling access to your email communications from ButterflyMom, at all times. However, ButterflyMom cannot
      ensure or warrant the security of any information you transmit to ButterflyMom or guarantee that information on
      the Service may not be accessed, disclosed, altered, or destroyed. Your privacy settings may also be affected by
      changes to the functionality of third party sites and services that you add to the ButterflyMom Service, such as
      social networks. ButterflyMom is not responsible for the functionality or security measures of any third party.
    </p>
    <p>For more information about ButterflyMom security, please visit Security at ButterflyMom.</p>
    <em>Summary: We care about the safety of your data and, have implemented industry recognized measures to protect it,
      but unfortunately we can’t guarantee that nothing bad will ever happen to it. (A troop of rabid baboons springs to
      mind).</em>
    <h4>7. Compromise of information</h4>
    <p>In the event that any information under our control is compromised as a result of a breach of security,
      ButterflyMom will take reasonable steps to investigate the situation and where appropriate, notify those
      individuals whose information may have been compromised and take other steps, in accordance with any applicable
      laws and regulations.</p>
    <em>Summary: If there is a breach of our security we will investigate it and let any affected users know.</em>
    <h4>8. Your choices about your information</h4>
    <h5>(a) You control your account information and settings</h5>
    <p>You may update your account information by logging into your account and changing your profile settings. You can
      also stop receiving promotional email communications from us by clicking on the “unsubscribe link” provided in
      such communications. We make every effort to promptly process all unsubscribe requests.</p>
    <p>You may not opt out of Service-related communications (e.g., account verification, purchase and billing
      confirmations and reminders, changes/updates to features of the Service, technical and security notices) although
      you can object to us providing these emails by emailing butterflymom00@gmail.com.</p>
    <p>If you have any questions about reviewing or modifying your account information, you can contact us directly at
      butterflymom00@gmail.com.</p>
    <em>Summary: You have control over your account settings, such as your account information and marketing email
      notifications, but there’s some important stuff we’ll always send you. If you have any questions about reviewing
      or modifying your account information, you can contact us directly at butterflymom00@gmail.com.</em>
    <h4>9. How long we keep your information</h4>
    <p>Following termination or deactivation of your User account, ButterflyMom will retain your profile information and
      User Content for a commercially reasonable time, and for as long as we have a valid purpose to do so. In
      particular, ButterflyMom will retain your information for the purpose of complying with its legal and audit
      obligations, and for backup and archival purposes.</p>
    <em>Summary: We retain your profile information and user content for the purpose of providing our Service to you and
      to comply with our legal and regulatory obligations.</em>
    <h4>10. Links to other websites and services</h4>
    <p>We are not responsible for the practices employed by websites or services linked to or from the Service,
      including the information or content contained therein. Please remember that when you use a link to go from the
      Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing
      and interaction on any third-party website or service, including those that have a link or advertisement on our
      website, are subject to that third party’s own rules and policies. In addition, you acknowledge that we are not
      responsible for and we do not exercise control over any third-parties that you authorize to access your User
      Content. If you are using a third-party website or service (like Facebook) and you allow such a third-party access
      to your User Content you do so at your own risk. This Privacy Policy does not apply to information we collect by
      other means (including offline) or from other sources other than through the Service.</p>
    <em>Summary: The Internet. It’s made of links. If we post a link to a third party website on ButterflyMom, we can’t
      control what happens on the other end. The same goes if you let another site use your data on ButterflyMom; the
      use of your information will be governed by the Privacy Policy of the third party.</em>
    <h4>11. Changes to this Policy</h4>
    <p>We may update this policy from time to time to reflect our current practice and ensure compliance with applicable
      laws. When we post changes to this policy, we will revise the “Last Updated” date at the top of this policy. If we
      make any material changes to the way we collect, use, store and/or share your personal information, we will notify
      you with a notice on our website and/by sending an email to the email address associated with your ButterflyMom
      account. We recommend that you check this page from time to time to inform yourself of any changes.</p>
    <h4>12. How to contact us</h4>
    <p>TODO</p>
  </div>
</div>
