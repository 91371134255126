<div class="w3-container">
  <div class="w3-row">
    <p class="w3-medium"><b>Comments</b></p>
    <div *ngIf="uid.length > 0;else notloggedin">
      <div class="w3-col" style="width: 100px">
        <img src="{{ commentUserImage }}" class="w3-circle" alt="Commenter" width="80%" />
      </div>
      <div class="w3-rest">
        <form [formGroup]="fgComment">
          <textarea rows="3" cols="100" class="w3-input w3-border w3-border-purple w3-round"
            placeholder="Add your comment here" formControlName="commentCtrl" id="commentCtrl"></textarea>
        </form>
        <div class="w3-right">
          <button class="
              w3-button
              w3-white
              w3-text-pink
              w3-border
              w3-border-pink
              w3-hover-pink
              w3-margin
            " (click)="fgComment.reset()">
            Reset
          </button>
          <button class="
              w3-button
              w3-white
              w3-text-purple
              w3-border
              w3-border-purple
              w3-hover-purple
              w3-margin
            " (click)="addComment()">
            Submit
          </button>
        </div>
      </div>
    </div>
    <ng-template #notloggedin>
      <div class="w3-center w3-panel w3-lavendar">
      <p><a class="w3-text-purple" style="text-decoration: none;" routerLink="/web/sign-in">Login</a> to add a comment</p>
    </div>
    </ng-template>
  </div>
  <p class="w3-clear"></p>
  <div class="w3-center">
    <p *ngIf="showNoComments">
      No comments yet. Be the first to add a comment.
    </p>
    <p class="w3-clear"></p>
  </div>
  <spinner *ngIf="showLoaderC"></spinner>
  <div *ngIf="!showLoaderC" class="w3-row">
    <div *ngFor="let localComment of localComments">
      <div id="commentContainer_{{ localComment.id }}" class="w3-display-container">
        <app-comment-card [isCommentParent]="true" [comment]="localComment.comment"></app-comment-card>
        <div class="w3-display-bottom" style="margin-top: -30px; margin-left: 110px">
          <div class="w3-row">
            <div class="w3-half">
              <button class="w3-button w3-circle w3-white" (click)="updateCommentLike(docId, localComment.id)">
                <i [ngClass]="
                    localComment.comment.likedUserIds.includes(
                      authService.user.uid
                    )
                      ? 'fa-heart'
                      : 'fa-heart-o'
                  " class="fa w3-text-red" id="{{ localComment.id }}" aria-hidden="true"></i>
              </button>
              {{ localComment.comment.likedUserIds.length }}
              <button (click)="showReply('replyCtrl_' + localComment.id)"
                class="w3-button w3-circle w3-white w3-margin-left">
                <i class="fa fa-reply w3-text-purple" aria-hidden="true"></i>
              </button>
            </div>
            <div class="w3-half">
              <button (click)="
                  deleteComment(
                    'commentContainer_' + localComment.id,
                    docId,
                    localComment.id
                  )
                " class="w3-right w3-button w3-circle w3-white">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="w3-row-padding" style="margin-left: 50px; margin-top: -20px" style="display: none"
          id="replyCtrl_{{ localComment.id }}">
          <textarea rows="3" cols="100" class="w3-input w3-border w3-border-purple w3-round"
            placeholder="Add your reply here" id="commentReplyCtrl_{{ localComment.id }}"></textarea>
          <div class="w3-right">
            <button *ngIf="localComment.comment.userId == authService.user.uid" class="
                w3-button
                w3-white
                w3-text-purple
                w3-border
                w3-border-purple
                w3-hover-purple
                w3-margin
              " (click)="
                addReply(
                  localComment.id,
                  localComment.comment.userName,
                  'replyCtrl_' + localComment.id,
                  'commentReplyCtrl_' + localComment.id
                )
              ">
              Add Reply
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="localComment.replies.length > 0" style="margin-left: 50px">
        <div *ngFor="let reply of localComment.replies">
          <div class="w3-display-container" id="replyContainer_{{ reply.id }}">
            <app-comment-card [isCommentParent]="false" [comment]="reply.comment"></app-comment-card>
            <div class="w3-display-bottom" style="margin-top: -30px; margin-left: 110px">
              <div class="w3-row">
                <div class="w3-half">
                  <button class="w3-button w3-circle w3-white"
                    (click)="updateReplyLike(docId, localComment.id, reply.id)">
                    <i [ngClass]="
                        reply.comment.likedUserIds.includes(
                          authService.user.uid
                        )
                          ? 'fa-heart'
                          : 'fa-heart-o'
                      " class="fa w3-text-red" id="{{ reply.id }}" aria-hidden="true"></i>
                  </button>
                  {{ reply.comment.likedUserIds.length }}
                  <button (click)="showReply('rReplyCtrl_' + reply.id)"
                    class="w3-button w3-circle w3-white w3-margin-left">
                    <i class="fa fa-reply w3-text-purple" aria-hidden="true"></i>
                  </button>
                </div>
                <div class="w3-half">
                  <button *ngIf="reply.comment.userId == authService.user.uid" (click)="
                      deleteReply(
                        'replyContainer_' + reply.id,
                        docId,
                        localComment.id,
                        reply.id
                      )
                    " class="w3-right w3-button w3-circle w3-white">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="w3-row-padding" style="margin-left: 50px; margin-top: -20px" style="display: none"
            id="rReplyCtrl_{{ reply.id }}">
            <textarea rows="3" cols="100" class="w3-input w3-border w3-border-purple w3-round"
              placeholder="Add your reply here" id="replyReplyCtrl_{{ reply.id }}"></textarea>
            <div class="w3-right">
              <button class="
                  w3-button
                  w3-white
                  w3-text-purple
                  w3-border
                  w3-border-purple
                  w3-hover-purple
                  w3-margin
                " (click)="
                  addReply(
                    localComment.id,
                    reply.comment.userName,
                    'rReplyCtrl_' + reply.id,
                    'replyReplyCtrl_' + reply.id
                  )
                ">
                Add Reply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="justAnItem">
    <p class="w3-clear" #justAnItem></p>
  </div>
</div>
