<div *ngIf="!authService.isLoggedIn || !subscribed">
  <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
    <h4>Subscribe</h4>
  </div>
  <div [formGroup]="homeSubsFG" class="w3-container w3-white">
    <p>Enter your e-mail below and get notified on the latest blog posts, reviews, products, courses and events.</p>
    <p><input formControlName="emailElm" id="emailElm" class="w3-input w3-border" type="email"
        placeholder="Enter e-mail" style="width:100%"></p>
        <p *ngIf="alreadySubscribed" class="w3-text-red">Email Id has already subscribed to updates!!!</p>
    <button #subscribeHomeBtn (click)="openModal()" class="w3-button w3-block w3-pink" disabled>Subscribe</button>
  </div>
</div>
<div #modalDialog id="modalDialog" class="w3-modal">
  <div class="w3-modal-content">
    <div class="w3-card-4">
      <header class="w3-container w3-purple">
        <span (click)="closeModal()" class="w3-button w3-display-topright">&times;</span>
        <h3>Subscribe to our emails</h3>
      </header>

      <div class="w3-container w3-padding">
        <p>Please check the items on which you wish to get updates via your email, <span
            class="w3-text-purple">{{email}}</span></p>
        <form [formGroup]="subsFG">
          <p>
            <input class="w3-check w3-blue" type="checkbox" formControlName="blog" id="blog">
            <label> Blog</label>
          </p>
          <p>
            <input class="w3-check" type="checkbox" formControlName="reviews" id="reviews">
            <label> Reviews</label>
          </p>
          <p>
            <input class="w3-check" type="checkbox" formControlName="courses" id="courses">
            <label> Courses</label>
          </p>
          <p>
            <input class="w3-check" type="checkbox" formControlName="events" id="events">
            <label> Events</label>
          </p>
          <p>
            <input class="w3-check" type="checkbox" formControlName="products" id="products">
            <label> New Products</label>
          </p>
          <p>
            <input class="w3-check" type="checkbox" formControlName="suggest" id="suggest">
            <label> Suggestions</label>
          </p>
        </form>
        <div class="w3-panel w3-green">
          <p *ngIf="displaySubscribed" #subscribedMsg>You have successfully subscribed.</p>
        </div>
      </div>

      <footer class="w3-container">
        <div class="w3-right">
          <p>
            <button class="w3-btn w3-grey w3-margin-right" (click)="closeModal()">Cancel</button>
            <button class="w3-btn w3-pink" #subscribeBtn (click)="subscribe()" disabled>Subscribe</button>
          </p>
        </div>
      </footer>
    </div>
  </div>
</div>