<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
    <h4>Follow Us</h4>
  </div>
  <div class="w3-container w3-xlarge w3-padding w3-center">
    <a tooltip="Butterfly Mom Facebook Group" href="https://www.facebook.com/Butterfly-Mom-109250014048850"  style="color: #4267B2;"
      target="_blank" class="w3-bar-item purplehoverText w3-button"><i class="fa fa-facebook-official"></i></a>
    <a tooltip="Butterfly Mom Instagram" href="https://www.instagram.com/butterfly_mom_jaisha/" target="_blank" style="color: #8a3ab9;"
      class="w3-bar-item purplehoverText w3-button"><i class="fa fa-instagram"></i></a>
    <a tooltip="Butterfly Mom Telegram" href="https://t.me/ButterflyMoms" target="_blank" style="color: #229ED9;"
      class="w3-bar-item purplehoverText w3-button"><i class="fa fa-telegram"></i></a>
    <a tooltip="Butterfly Mom Youtube Channel" href="https://www.youtube.com/c/ButterflyMom" target="_blank" style="color: #FF0000;"
      class="w3-bar-item purplehoverText w3-button"><i class="fa fa-youtube-play"></i></a>
    <a tooltip="Butterfly Mom Pinterest" href="https://fi.pinterest.com/sindhu27993" target="_blank" style="color: #c8232c;"
      class="w3-bar-item purplehoverText w3-button"><i class="fa fa-pinterest-square"></i></a>
  </div>
