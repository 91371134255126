import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-moderation',
  templateUrl: './moderation.component.html',
  styleUrls: ['./moderation.component.css']
})
export class ModerationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openTab(id: string) {
    var i;
    var x = <HTMLElement[]>(<any>document.getElementsByClassName('tab'));
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    document.getElementById(id)!.style.display = 'block';
  }
}
