import { Component, OnInit } from '@angular/core';
import { DashboardData } from 'src/app/models/interfaces/dashboarddata';
import { DashbaordDataService } from 'src/app/services/dashboard-data/dashbaord-data.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  dashboardData!:DashboardData;
  constructor(private dashboardService: DashbaordDataService) { }

  ngOnInit(): void {
    this.dashboardService.getDashboardData().then((data) => {
      this.dashboardData = <DashboardData>data.data();
      console.log(this.dashboardData.users.total);
    });
  }

}
