<div class="w3-bar">
  <a routerLink="home" routerLinkActive="bold" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-home" aria-hidden="true"></i> Home</a>
  <a routerLink="blog" routerLinkActive="bold" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-rss" aria-hidden="true"></i> Blog</a>
  <a routerLink="reviews" routerLinkActive="bold" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-pencil" aria-hidden="true"></i> Reviews</a>
  <!-- <a routerLink="#" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-list-alt" aria-hidden="true"></i> Courses</a>
  <a routerLink="#" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-trophy" aria-hidden="true"></i> Events</a>
  <a routerLink="#" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-calendar" aria-hidden="true"></i> Calendar</a>-->
  <div class="w3-dropdown-hover w3-mobile">
    <button class="w3-button"><i class="fa fa-shopping-basket" aria-hidden="true"></i> Shop</button>
    <div class="w3-dropdown-content w3-bar-block w3-card-4">
      <a routerLink="store" class="w3-bar-item w3-button w3-mobile"><img src="../../../assets/images/shopIcon.png" width="16px" height="16px" class="w3-grayscale-max"> Butterfly Store</a>
      <a routerLink="affiliate-store" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-cart-arrow-down" aria-hidden="true"></i> Affiliate Store</a>
      <a routerLink="downloads" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-download" aria-hidden="true"></i> Downloads</a>
    </div>
  </div>
  <a *ngIf="!authService.isLoggedIn" routerLink="sign-in" class="w3-right w3-bar-item w3-button w3-mobile"><i class="fa fa-sign-in" aria-hidden="true"></i> Sign In</a>
  <div class="w3-dropdown-hover w3-right w3-mobile" *ngIf="authService.isLoggedIn" >
    <button class="w3-button"><i class="fa fa-gear" aria-hidden="true"></i> Account</button>
    <div class="w3-dropdown-content w3-bar-block w3-card-4">
      <a routerLink="profile" routerLinkActive="w3-lavendar" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-user" aria-hidden="true"></i> Profile</a>
      <!-- <a routerLink="profile" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-user" aria-hidden="true"></i> My Orders</a>
      <a routerLink="profile" class="w3-bar-item w3-button w3-mobile"><i class="fa fa-user" aria-hidden="true"></i> BMPs</a> -->
      <a routerLink="sign-in" class="w3-bar-item w3-button w3-mobile" (click)="authService.signOut()"><i class="fa fa-sign-out" aria-hidden="true"></i> Sign Out</a>
    </div>
  </div>
  <a *ngIf="authService.isAdmin || authService.isEditor" href="dashboard/overview" class="w3-right w3-bar-item w3-button w3-mobile"><i class="fa fa-dashboard" aria-hidden="true"></i> Dashboard</a>
  <p class="w3-clear w3-hide-large w3-hide-medium"></p>
  <p class="w3-clear w3-hide-large w3-hide-medium"></p>
  <p class="w3-clear w3-hide-large w3-hide-medium"></p>
</div>


