import { Component, Input, OnInit } from '@angular/core';
import { Product } from 'src/app/models/product';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.css']
})
export class StoreCardComponent implements OnInit {
  @Input('item') item!:Product;
  sellingPrice = 0;
  savings = 0;
  constructor() { }

  ngOnInit(): void {
    this.sellingPrice = this.item.pricing.discount == 0 ? this.item.pricing.mrp : this.item.pricing.mrp * ( 1 - (this.item.pricing.discount/100));
    this.savings = this.item.pricing.discount == 0 ? 0 : (this.item.pricing.mrp*this.item.pricing.discount/100)
  }

  increment(itemId:string) {

  }

  decrement(itemId:string) {

  }

  addToCart(itemId:string) {

  }

  download(itemId:string) {

  }
}
