<div class="w3-row">
    <div *ngIf="comment.userPhotoUrl" class="w3-col w3-padding" style="width:100px;">
        <p class="w3-clear"></p>
        <img src="{{comment.userPhotoUrl}}" class="w3-circle" alt="{{comment.userName}}" width="70%">
    </div>
    <div *ngIf="!comment.userPhotoUrl" class="w3-col w3-padding" style="width:100px">
        <p class="w3-clear"></p>
        <div class="dot w3-border w3-bottombar w3-border-pink">
            <p class="w3-text-purple"><b>{{comment.userName.charAt(0)}}</b></p>
        </div>
    </div>
    <div class="w3-rest">
        <div class="w3-row-padding">
            <p><span class="w3-left"><em>{{comment.userName}}</em></span><span class="w3-right w3-small w3-text-grey">{{comment.timestamp | date:'short'}}</span></p>
        </div>
        <div class="w3-panel w3-round w3-leftbar w3-border-purple w3-lavendar" style="margin-top: -5px;">
            <p><span class="w3-text-purple" *ngIf="!isCommentParent"><span>@{{comment.parentCommentUserName}}</span> : </span>{{comment.content}}</p>
            
        </div>
    </div>
</div>