<div class="w3-container" style="min-height: 500px; padding-left: 20px">
  <div *ngIf="subscribeBlock" class="w3-row">
    <app-subscribe></app-subscribe>
  </div>
  <div class="w3-row" *ngIf="blogBlock">
    <app-suggestions
      [isBlog]="true"
      [category]="subCategory"
      [limit]="5"
    ></app-suggestions>
  </div>
  <div class="w3-row" *ngIf="reviewBlock">
    <app-suggestions
      [isBlog]="false"
      [category]="subCategory"
      [limit]="5"
    ></app-suggestions>
  </div>
  <div class="w3-row" *ngIf="popularTagsBlock">
    <app-blog-tags></app-blog-tags>
  </div>
  <div class="w3-row" *ngIf="popularPostsBlock">
    <app-popular-posts></app-popular-posts>
  </div>
  <div *ngIf="topSellingBlock" class="w3-row">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>Top Selling</h4>
    </div>
    <div class="">
      <p>
        <span class="w3-tag w3-pink w3-margin-bottom">mom</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >New York</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >entrepreneurship</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">Hats</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Norway</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">kids</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Ideas</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Deals</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >learnings</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">News</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Clothing</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >child development</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Jeans</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Trends</span
        >
      </p>
    </div>
  </div>
  <div class="w3-row" *ngIf="adBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>Advertisement</h4>
    </div>
    <app-ad></app-ad>
  </div>
  <div class="w3-row" *ngIf="timeLineBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>Blog Time Line</h4>
    </div>
    <app-blog-tree></app-blog-tree>
  </div>
  <div class="w3-row" *ngIf="productSuggestionsBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>Buy Suggestions</h4>
    </div>
    <div class="">
      <p>
        <span class="w3-tag w3-pink w3-margin-bottom">mom</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >New York</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >entrepreneurship</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">Hats</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Norway</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">kids</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Ideas</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Deals</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >learnings</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">News</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Clothing</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >child development</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Jeans</span
        >
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom"
          >Trends</span
        >
      </p>
    </div>
  </div>
  <div class="w3-row" *ngIf="amazonProductsBlock">
    <app-buy-from-amazon></app-buy-from-amazon>
  </div>
  <div class="w3-row" *ngIf="authorBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>About Author</h4>
    </div>
    <app-me-card
      [photoUrl]="''"
      [name]="'SOME AUTHOR'"
      [description]="'SOME DESCRIPTION'"
    ></app-me-card>
  </div>
  <div class="w3-row" *ngIf="ownerBlock">
    <app-follow-me></app-follow-me>
  </div>
  <!-- <div class="w3-row" *ngIf="ownerBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>About Me</h4>
    </div>
    <app-me-card></app-me-card>
  </div> -->
  <div class="w3-row" *ngIf="adBlock">
    <div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
      <h4>Advertisement</h4>
    </div>
    <app-ad [adType]="'long'"></app-ad>
  </div>
</div>
