import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PostsService } from 'src/app/services/post/posts.service';
import { Post, LocalPost } from 'src/app/models/classes/posts';
import { PostStatus } from 'src/app/models/enums/poststatus';
import { ClipboardService } from 'ngx-clipboard';
import { QuillModules } from 'ngx-quill';
import { ActivatedRoute, Router } from '@angular/router';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.js';
import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill';
import { StateService } from 'src/app/services/state/state.service';
import ImageResize from 'quill-image-resize-module';
import { DomSanitizer } from '@angular/platform-browser';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { PostSettings } from 'src/app/models/interfaces/settings';
import { DashbaordDataService } from 'src/app/services/dashboard-data/dashbaord-data.service';
import { DashboardDataOperation } from 'src/app/models/enums/dashboardDataOperation';
import { scheduled } from 'rxjs';
Quill.register('modules/imageResize', ImageResize);

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class NewPostComponent implements OnInit, AfterViewInit {
  fileExts = ['png', 'jpg', 'jpeg', 'gif'];
  regex = '(https?://)?([\\da-zA-Z0-9//%._?=&-]+)';

  toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],
    ['emoji'],
    ['link', 'image', 'video'],
    ['clean'], // remove formatting button
  ];

  quillModules: QuillModules = {
    toolbar: {
      container: this.toolbarOptions,
      handlers: {
        image: this.imageHandler,
        emoji: function () {},
      },
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
    imageResize: {},
    keyboard: {
      bindings: {
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            return true;
          },
        },
      },
    },
  };

  pageTitle: string = 'New Post';
  pageSubTitle: string = 'Create a new blog or review post';
  today: number = Date.now();
  authorTypes: string[] = ['Admin', 'Editor', 'Subscriber'];
  categories: string[] = ['Blog', 'Reviews'];
  subcategories: string[] = [];
  postSettings!: PostSettings;
  tags: string[] = [];

  html: string = '';
  fg: FormGroup;
  displayFormError: boolean = false;
  badUser: boolean = false;
  hideSave: boolean = false;
  isPublished: boolean = false;
  localPost!: LocalPost;
  initialPost: Post = new Post(
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    false,
    0,
    0,
    [],
    false,
    [],
    '',
    '',
    ''
  );
  postId!: string;
  hasFocus = false;
  subject!: string;
  imageUrls?: string[] = [];
  previousImageUrls?: string[] = [];
  amazonAffiliateLinks: any[] = [];
  productLinks: any[] = [];
  otherLinks: any[] = [];

  onLoad: boolean = false;
  showSaveMessage: boolean = false;

  @ViewChild('saveBtn') saveBtnElm!: ElementRef;
  @ViewChild('saveBtn2') saveBtn2Elm!: ElementRef;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private postService: PostsService,
    private route: ActivatedRoute,
    private stateService: StateService,
    public sanitizer: DomSanitizer,
    private settingsService: SettingsService,
    private dashboardService: DashbaordDataService
  ) {
    this.settingsService.getSettings('post').then((snapShot) => {
      if (snapShot) {
        snapShot.forEach((data) => {
          this.postSettings = <PostSettings>data.data();
        });
      }
    });
    this.localPost = <LocalPost>stateService.getData();
    stateService.clearData();
    let urlData = this.route.snapshot.paramMap.get('data');
    this.fg = this.formBuilder.group({
      title: new FormControl('', [
        Validators.required || Validators.minLength(10),
      ]),
      titleImage: new FormControl('', [
        Validators.required,
        Validators.pattern(this.regex),
      ]),
      imageAlt: new FormControl(''),
      description: new FormControl('', [
        Validators.required || Validators.maxLength(100),
      ]),
      category: new FormControl('Blog'),
      subCategory: new FormControl(''),
      target: new FormControl('0'),
      author: new FormControl('Butterfly Mom', [Validators.required]),
      editorContent: new FormControl('', [Validators.required]),
      authorType: new FormControl({
        value: this.getAuthorType(),
        disabled: true,
      }),
      tags: new FormControl(''),
      disableComments: new FormControl(false),
      isTop: new FormControl({ value: false, disabled: true }),
      amafli: new FormControl(),
      spcl: new FormControl(),
      olinks: new FormControl(),
    });

    if (!this.localPost && urlData) {
      this.pageTitle = 'Edit Post';
      this.pageSubTitle = 'Edit a blog or review post';
      postService
        .getPost(urlData)
        .then((snapShot) => {
          if (snapShot.size == 1) {
            snapShot.forEach((data) => {
              this.localPost = {
                uid: data.id,
                post: <Post>data.data(),
              };
            });
            this.initialPost = this.localPost.post!;
            this.postId = this.localPost.uid!;
            this.setFormData(this.localPost);
          } else if (snapShot.size == 0) {
            // Display error message that no data could be fetched
          } else {
            // Multiple data found for the same url
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (this.localPost) {
      this.setFormData(this.localPost);
      this.previousImageUrls = this.localPost.post?.postImages;
    }
    this.initialPost = this.getFormData();
  }

  setFormData(localPost: LocalPost) {
    this.fg.controls['title'].setValue(localPost.post?.title);
    this.fg.controls['titleImage'].setValue(localPost.post?.titleImage);
    this.fg.controls['imageAlt'].setValue(localPost.post?.imageAlt);
    this.fg.controls['description'].setValue(localPost.post?.description);
    this.fg.controls['category'].setValue(localPost.post?.category);
    this.fg.controls['subCategory'].setValue(localPost.post?.subCategory);
    this.fg.controls['target'].setValue(localPost.post?.target);
    this.fg.controls['author'].setValue(localPost.post?.author);
    this.fg.controls['authorType'].setValue(localPost.post?.authorType);
    this.fg.controls['target'].setValue(localPost.post?.target?.toString());
    this.fg.controls['tags'].setValue(localPost.post?.tags?.join(','));
    this.fg.controls['disableComments'].setValue(
      localPost.post?.disableComments
    );
    this.fg.controls['isTop'].setValue(localPost.post?.isTop);
    this.fg.controls['amafli'].setValue(localPost.post?.relatedAmazonProducts);
    this.fg.controls['spcl'].setValue(localPost.post?.relatedSellingItems);
    this.fg.controls['olinks'].setValue(localPost.post?.otherlinks);
    this.html = localPost.post?.html!;
  }

  ngOnInit(): void {
    this.imageUrls = this.stateService.getArrayData();
  }

  ngAfterViewInit(): void {
    this.fg.valueChanges.subscribe(() => {
      if (this.getFormData().isEqual(this.initialPost)) {
        this.saveBtnElm.nativeElement.disabled = true;
        this.saveBtn2Elm.nativeElement.disabled = true;
      } else {
        this.saveBtnElm.nativeElement.disabled = false;
        this.saveBtn2Elm.nativeElement.disabled = false;
      }
    });
  }

  removeDuplicates(data: string[]) {
    if (!data) {
      return [];
    }
    return data.filter((value, index) => data.indexOf(value) === index);
  }

  getFormData() {
    var allImageUrls: string[] =
      this.removeDuplicates(this.previousImageUrls!) || [];
    this.imageUrls?.forEach((itm) => {
      allImageUrls.push(itm);
    });
    allImageUrls = this.removeDuplicates(allImageUrls);
    let formData = new Post(
      this.fg.controls['title'].value,
      this.fg.controls['titleImage'].value,
      this.fg.controls['imageAlt'].value,
      this.fg.controls['description'].value,
      this.fg.controls['category'].value,
      this.fg.controls['subCategory'].value,
      this.fg.controls['author'].value,
      this.fg.controls['authorType'].value,
      this.html,
      this.fg.controls['tags'].value,
      this.fg.controls['target'].value,
      this.fg.controls['disableComments'].value,
      this.initialPost != null || undefined ? this.initialPost.createdDate! : 0,
      0,
      this.initialPost.likes!,
      this.fg.controls['isTop'].value,
      allImageUrls,
      this.fg.controls['spcl'].value,
      this.fg.controls['amafli'].value,
      this.fg.controls['olinks'].value
    );
    return formData;
  }

  onSave() {
    if (
      this.fg.hasError('title') ||
      this.fg.hasError('author') ||
      this.fg.hasError('editorContent')
    ) {
      this.displayFormError = true;
      return;
    }
    this.initialPost = this.getFormData();
    this.initialPost.setCreatedDate();
    if (this.authService.isAdmin || this.authService.isEditor)
      if (!this.postId) {
        this.initialPost.setStatus(PostStatus.New);
        this.postService.savePost(this.initialPost).then((data) => {
          this.postId = data.toString();
        });
      } else {
        console.log(this.initialPost.toJson());
        this.postService.updatePost(this.postId, this.initialPost.toJson());
      }
    this.saveBtnElm.nativeElement.display = 'none';
    this.saveBtn2Elm.nativeElement.display = 'none';
    this.showSaveMessage = true;
    setTimeout(() => {
      this.showSaveMessage = false;
    }, 5000);
  }

  onPublish() {
    if (this.initialPost.category?.includes('log')) {
      this.dashboardService.updateBlogData(
        DashboardDataOperation.Increment,
        DashboardDataOperation.Increment,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.DoNothing,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    } else {
      this.dashboardService.updateReviewData(
        DashboardDataOperation.Increment,
        DashboardDataOperation.Increment,
        DashboardDataOperation.DoNothing,
        DashboardDataOperation.DoNothing,
        this.authService.isEditor
          ? DashboardDataOperation.Increment
          : DashboardDataOperation.DoNothing
      );
    }
    this.postService.publishPost(this.postId);
    this.router.navigate(['/dashboard/published'], {
      queryParams: { title: this.initialPost.urlTitle },
    });
  }

  // Form and page related methods

  imageHandler(this: any) {
    const tooltip = this.quill.theme.tooltip;
    const originalSave = tooltip.save;
    const originalHide = tooltip.hide;
    tooltip.save = function (this: any) {
      const range = this.quill.getSelection(true);
      const value = this.textbox.value;
      if (value) {
        this.quill.insertEmbed(range.index, 'image', value, 'user');
      }
    };
    // Called on hide and save.
    tooltip.hide = function (this: any) {
      tooltip.save = originalSave;
      tooltip.hide = originalHide;
      tooltip.hide();
    };
    tooltip.edit('image');
    tooltip.textbox.placeholder = 'Embed URL';
  }

  changedEditor(event: EditorChangeContent | EditorChangeSelection) {
    try {
      this.saveBtnElm.nativeElement.enabled = true;
      this.saveBtn2Elm.nativeElement.enabled = true;
    } catch (error) {
      //
    }
  }

  editorFocusChange(event: any) {
    if (this.getFormData() != this.initialPost) {
      this.saveBtnElm.nativeElement.enabled = true;
      this.saveBtn2Elm.nativeElement.enabled = true;
    }
  }

  addBindingCreated(quill: Quill) {
    quill.keyboard.addBinding(
      {
        key: 'b',
      },
      (range, context) => {
        console.log('KEYBINDING B', range, context);
      }
    );

    quill.keyboard.addBinding(
      {
        key: 'B',
        shiftKey: true,
      } as any,
      (range, context) => {
        console.log('KEYBINDING SHIFT + B', range, context);
      }
    );
  }

  openTab(id: string) {
    var i;
    var x = <HTMLElement[]>(<any>document.getElementsByClassName('tab'));
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    document.getElementById(id)!.style.display = 'block';
  }

  getAuthorType() {
    if (this.authService.isAdmin) return 'Admin';
    else if (this.authService.isEditor) return 'Editor';
    else return '';
  }

  expandAccordion(id: string, chevronId: string) {
    var x = document.getElementById(id);
    var y = document.getElementById(chevronId);
    if (x!.className.indexOf('w3-show') == -1) {
      x!.className += ' w3-show';
      y!.classList.replace('fa-chevron-down', 'fa-chevron-up');
    } else {
      x!.className = x!.className.replace(' w3-show', '');
      y!.classList.replace('fa-chevron-up', 'fa-chevron-down');
    }
  }

  populateImagesList() {
    this.imageUrls = this.stateService.getArrayData();
  }

  typeChange() {
    let data = 'post';
    switch (this.fg.controls['category'].value) {
      case 'Blog':
        data = 'post';
        break;
      case 'Reviews':
        data = 'reviews';
        break;
    }
    this.settingsService.getSettings(data).then((snapShot) => {
      if (snapShot) {
        snapShot.forEach((data) => {
          this.postSettings = <PostSettings>data.data();
        });
      }
    });
  }
}
