<div class="w3-padding w3-round-large">
    <form class="w3-center w3-margin-top w3-border w3-white w3-round-large w3-border-purple"
        style="width: 400px;margin: auto;">
        <div class="w3-padding">
            <h3 class="w3-text-purple">Reset Password</h3>
        </div>
        <div class="w3-padding">
            <p class="text-center">Please enter your email address to request a password reset.</p>
            <label class="w3-text-purple">Email Address</label>
            <input class="w3-input w3-border w3-border-purple w3-round-large" type="email" #passwordResetEmail required>
        </div>
        <div class="w3-padding">
            <input type="submit" class="w3-btn w3-purple" value="Reset Password"
                (click)="authService.forgotPassword(passwordResetEmail.value)">
        </div>
        <div class="w3-padding">
            <span>Go back to ? <a class="nostyleurl" href="web/sign-in">Log In</a></span>
        </div>
    </form>
</div>