<div class="w3-padding w3-round-large">
    <form class="w3-center w3-margin-top w3-border w3-white w3-round-large w3-border-purple" style="width: 400px;margin: auto;">
        <div class="w3-padding">
            <h3 class="w3-text-purple">Sign In</h3>
        </div>
        <div class="w3-padding">
            <label class="w3-text-purple">User Name</label>
            <input class="w3-input w3-border w3-border-purple w3-round-large" type="text" #userName>
        </div>
        <div class="w3-padding">
            <label class="w3-text-purple">Password</label>
            <input class="w3-input w3-border w3-border-purple w3-round-large" type="password" #userPassword>
        </div>
        <div class="w3-padding">
            <input type="button" class="w3-btn w3-pink" value="Log in"
                (click)="authService.signIn(userName.value, userPassword.value)">
        </div>
        <div class="w3-padding">
            <span class="or"><span class="orInner">Or</span></span>
        </div>
        <div class="w3-padding">
            <button type="button" class="w3-btn w3-red" (click)="authService.googleAuth()">
                <i class="fab fa-google-plus-g"></i>
                Log in with Google
            </button>
        </div>
        <div class="w3-padding">
            <a class="nostyleurl" href="web/forgot-password">Forgot Password?</a>
        </div>
        <div class="w3-padding">
            <span>Don't have an account?<a class="nostyleurl" href="web/sign-up"> Sign Up</a></span>
        </div>
    </form>
</div>