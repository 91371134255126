import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-tree',
  templateUrl: './blog-tree.component.html',
  styleUrls: ['./blog-tree.component.css']
})
export class BlogTreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  toggleCarret(id:string) {
    console.log(id);
    let elm = document.getElementById(id);
    elm?.parentElement!.querySelector(".nested")!.classList.toggle("active");
    elm?.classList.toggle("caret-down");
  }

}
