import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Inject } from '@angular/core';
import { Component, Input } from '@angular/core';

export interface carousel_image {
  src: string,
  alt: string
}

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css']
})
export class ImageCarouselComponent implements AfterViewInit {
  @Input() images: carousel_image[] = [];
  slideIndex = 1;
  className = this.makeid(10);

  constructor(@Inject(DOCUMENT) private doc: Document) { }
  ngAfterViewInit(): void {
    this.showDivs(this.slideIndex);
  }

  plusDivs(n: number) {
    if (n == 1 && this.slideIndex == this.images.length) {
      this.slideIndex = 0;
    } else if (n == -1 && this.slideIndex == 1) {
      this.slideIndex = this.images.length + 1;
    }
    this.showDivs(this.slideIndex += n);
  }

  showDivs(n: number) {
    var i;
    var x = this.doc.getElementsByClassName(this.className) as HTMLCollectionOf<HTMLElement>;
    if (n > x.length) { this.slideIndex = 1 }
    if (n < 1) { this.slideIndex = x.length }
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    x[this.slideIndex - 1].style.display = "block";
  }

  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
}
