import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalPost, Post } from 'src/app/models/classes/posts';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PostsService } from 'src/app/services/post/posts.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(750)),
    ]),
  ]
})
export class BlogPostComponent implements OnInit {
  isBlog:boolean = true;
  uid:string = "";
  backUrl:string = "/web/blog";
  showLoader: boolean = true;
  showError: boolean = false;
  post: Post = new Post("","", "", "", "", "", "", "", "", "", "", false, 0, 0, [], false, [],"","","");
  docId!: string;
  commentCount: number = 0;
  hasLiked?:boolean = false;

  constructor(public authService:AuthService, private router: Router, private route: ActivatedRoute, private stateService: StateService, private postService: PostsService, private titleService:Title) {
    if (authService.isLoggedIn) {
      this.uid = authService.user.uid;
    }
    if(!router.url.includes(this.backUrl))
      this.backUrl = "/web/reviews";
      this.isBlog = false;
  }

  ngOnInit(): void {
    try {
      let urlTitle = this.route.snapshot.queryParamMap.get('title');
      setTimeout(() => {

      }, 1000);
      this.titleService.setTitle(urlTitle!);
      let localPost = <LocalPost>this.stateService.getData();
      if (localPost) {
        this.post = <Post>localPost.post!;
        this.docId = localPost.uid!;
        this.showLoader = false;
        this.hasLiked = localPost.post?.likes?.includes(this.authService.user.uid);
      }
      else {
        if (urlTitle) {
          this.postService.getPost(urlTitle).then(snapShot => {
            if (snapShot.size == 1) {
              snapShot.forEach(data => {
                this.docId = data.id;
                this.post = <Post>data.data();
                this.hasLiked = this.post?.likes?.includes(this.authService.user.uid);
              });
              if (this.post.author) {
                this.showLoader = false;
              }
            }
          }).catch(err => {
            this.showLoader = false;
            this.showError = true;
            console.error(err);
          });
        }
      }
    } catch {

    }
  }

  updateParentLike(elementId: string) {
    let likedElm = document.getElementById(elementId);
    if (likedElm?.classList.contains("fa-heart-o")) {
      likedElm.classList.replace("fa-heart-o", "fa-heart");
      this.post.likes!.push(this.uid);
      this.postService.updateLike(this.docId, true, this.uid);
    }
    else {
      likedElm?.classList.replace("fa-heart", "fa-heart-o");
      const index = this.post.likes!.indexOf(this.authService.user.uid);
      this.post.likes!.splice(index, 1);
      this.postService.updateLike(this.docId, false, this.uid);
    }
  }

  updateCommentCount(commentCount:number) {
    this.commentCount = commentCount;
  }
}
