<div class="w3-container w3-margin w3-padding">
    <div class="w3-row-padding">
        <p class="w3-text-black w3-large"><b>Add Product</b></p>
        <p class="w3-text-grey">Add Amazon affiliate product text+image url</p>
    </div>
    <div id="productDetails" class="w3-container w3-row-padding">
        <div class="w3-row" *ngIf="successFlag">
            <div class="w3-panel w3-padding w3-green w3-card-2">
                <p>Data added successfully!!!</p>
            </div>
        </div>
        <div class="w3-row">
            <div class="w3-col s12 m6 l6 w3-padding">
                <div class="w3-row-padding w3-pink">
                    <h3>Product Details</h3>
                </div>
                <form [formGroup]="fg" class="w3-container">
                    <div class="w3-padding w3-margin">
                        <label class="w3-text-purple">Product Title</label>
                        <input class="w3-input w3-border w3-border-purple w3-round-large" autocomplete="off" id="title"
                            required formControlName="title">
                    </div>
                    <div class="w3-padding w3-margin">
                        <label class="w3-text-purple">Category</label>
                        <select class="w3-select w3-border w3-border-purple w3-round-large" id="category"
                            formControlName="category">
                            <option value="All" selected>All</option>
                            <option *ngFor="let category of categories" [ngValue]="category">{{category}}</option>
                        </select>
                    </div>
                    <div class="w3-padding w3-margin">
                        <label class="w3-text-purple">Age Group</label>
                        <select class="w3-select w3-border w3-border-purple w3-round-large" id="agegroup"
                            formControlName="agegroup">
                            <option value="-1" selected>All</option>
                            <option *ngFor="let group of ageGroups" [ngValue]="group">{{group}} - {{group+1}}</option>
                        </select>
                    </div>
                    <div class="w3-padding w3-margin">
                        <label class="w3-text-purple">Add Product Html</label>
                        <textarea class="w3-input w3-border w3-border-purple w3-round-large" id="product" required
                            rows="5" cols="100" formControlName="product" [(ngModel)]="productHtml"></textarea>
                    </div>
                </form>
            </div>
            <div class="w3-col s12 m6 l6 w3-padding">
                <div class="w3-row-padding w3-pink">
                    <h3>Preview</h3>
                </div>
                <div class="w3-padding w3-center" [innerHTML]="sanitizer.bypassSecurityTrustHtml(productHtml)"></div>

            </div>
        </div>
        <div class="w3-row w3-right">
            <button class="w3-btn w3-pink" [disabled]="!fg.valid" #addBtn (click)="addProduct()">Add</button>
        </div>
    </div>
</div>