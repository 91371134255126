import { Component, OnInit } from '@angular/core';
import { UserRoles } from 'src/app/models/enums/userRoles';
import { User } from 'src/app/models/user';
import { UsersService } from 'src/app/services/users/users.service';

export interface LocalUser {
  uid: string;
  user: User;
  disabled: boolean;
}


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  ADMIN:string = "admin";
  CUSTOMER:string ="customer";
  EDITOR:string ="editor";
  MODERATOR:string ="moderator";
  SELLER_ADMIN:string ="seller_admin";
  SELLER_OTHER:string ="seller_other";
  COURSE_ADMIN:string ="course_admin";
  COURSE_PROVIDER:string ="course_provider";
  EVENT_ADMIN:string ="event_admin";
  EVENT_PROVIDER:string ="event_provider";

  unlockedIndex:number = -1;
  noRecords:boolean = true;
  users:LocalUser[] = [];
  constructor(private usersService: UsersService) { }

  ngOnInit(): void {
    this.usersService.getAllUsers().then((snapshot)=> {
      if(snapshot.empty) {
        this.noRecords = true;
      }
      else {
        snapshot.forEach((data) => {
          this.users.push({uid:data.id, user: <User>data.data(), disabled: true});
        });
      }
    });
  }

  unlock(index:number) {
    if(this.unlockedIndex == -1) {
      this.users[index].disabled = false;
      this.unlockedIndex = index;
    }
  }

  lock(index:number) {
    var user = this.users[index];
    let existingRoles = user.user.roles;
    var newRoles:number[] = []

    if((document.getElementById(this.ADMIN + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.ADMIN);
    }
    if((document.getElementById(this.CUSTOMER + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.CUSTOMER);
    }
    if((document.getElementById(this.EDITOR + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.BLOG_EDITOR);
    }
    if((document.getElementById(this.MODERATOR + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.MODERATOR);
    }
    if((document.getElementById(this.SELLER_ADMIN + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.SELLER_ADMIN);
    }
    if((document.getElementById(this.SELLER_OTHER + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.SELLER_OTHER);
    }
    if((document.getElementById(this.COURSE_ADMIN + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.COURSE_ADMIN);
    }
    if((document.getElementById(this.COURSE_PROVIDER + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.COURSE_PROVIDER);
    }
    if((document.getElementById(this.EVENT_ADMIN + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.EVENT_ADMIN);
    }
    if((document.getElementById(this.EVENT_PROVIDER + index.toString())! as HTMLInputElement).checked) {
      newRoles.push(UserRoles.EVENT_PROVIDER);
    }
    var removedRoles = existingRoles.filter(item => newRoles.indexOf(item) < 0);
    var addedRoles = newRoles.filter(item => existingRoles.indexOf(item) < 0);

    this.usersService.updateUserRoles(user.uid, removedRoles, addedRoles);
    if(this.unlockedIndex == index) {
      this.users[index].disabled = true;
      this.unlockedIndex = -1;
    }
  }
}
