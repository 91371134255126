import { Component, OnInit } from '@angular/core';
import { UserRoles } from 'src/app/models/enums/userRoles';
import { AuthService } from 'src/app/services/auth/auth.service';
import { links } from '../../common/accordion/accordion.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  postHeader: string = "Post";
  postChildren: links[] = [];
  postIcon: string = "pencil";
  myStoreHeader: string = "My Store";
  myStoreChildren: links[] = [];
  myStoreIcon: string = "shopping-bag";
  afStoreHeader: string = "Affiliate Store";
  afStoreChildren: links[] = [];
  coursesIcon: string = "list-alt";
  coursesHeader: string = "Courses";
  coursesChildren: links[] = [];
  eventsIcon: string = "trophy";
  eventsHeader: string = "Events";
  eventsChildren: links[] = [];
  afStoreIcon: string = "cart-arrow-down";
  mySidebar!: HTMLElement | null;
  overlayBg!: HTMLElement | null;

  isSeller: boolean = false;
  isModerator:boolean = false;
  isCourseProvider:boolean = false;
  isEventProvider:boolean = false;

  constructor(public authService: AuthService) {
    if(authService.isAdmin)
    {
      this.postChildren = [{ item: "New", link: "post/new" }, { item: "Manage", link: "post/manage"}];
      this.afStoreChildren = [{ item: "Add Product", link: "aff-store/add-product"}, { item: "Manage Products", link: "aff-store/manage-products" }, { item: "Orders", link: "aff-store/orders"}];
      this.coursesChildren = [{ item: "All Courses", link: "all-course"}]
      this.eventsChildren = [{ item: "All Events", link: "aa-event"}]
    }
    if( authService.isEditor){
      if(!this.postChildren)
        this.postChildren = [{ item: "New", link: "new" }, { item: "Manage", link: "manage"}]
    }
    if(authService.roles.includes(UserRoles.SELLER_ADMIN) || authService.roles.includes(UserRoles.SELLER_OTHER))
    {
      this.isSeller = true;
      if(!this.myStoreChildren)
        this.myStoreChildren = [{ item: "Dashboard", link: "seller/dashboard"}, { item: "Add Product", link: "seller/add-product"}, { item: "Manage Products", link: "seller/manage-products"}, { item: "Store Orders", link: "seller/store-orders"}]
      else
        this.myStoreChildren.push({ item: "Dashboard", link: "seller/dashboard"},{ item: "Add Product", link: "seller/add-product"}, { item: "Manage Products", link: "seller/manage-products"}, { item: "Store Orders", link: "seller/store-orders"});
    }
    if(authService.roles.includes(UserRoles.COURSE_PROVIDER))
    {
      this.isCourseProvider = true;
      if(!this.coursesChildren)
        this.coursesChildren = [{ item: "Add Course", link: "add-course"}, { item: "Manage Courses", link: "manage-courses"}];
      else
        this.coursesChildren.push({ item: "Add Course", link: "add-course"}, { item: "Manage Courses", link: "manage-courses"});
    }
    if(authService.roles.includes(UserRoles.EVENT_ADMIN))
    {
      this.isEventProvider = true;
      if(!this.eventsChildren)
        this.eventsChildren = [{ item: "Add Event", link: "add-event"}, { item: "Manage Events", link: "manage-events"}];
      else
        this.eventsChildren.push({ item: "Add Event", link: "add-event"}, { item: "Manage Events", link: "manage-events"});
    }
    if(authService.roles.includes(UserRoles.MODERATOR))
      this.isModerator = true;
  }

  ngOnInit(): void {
    this.mySidebar = document.getElementById("mySidebar");
    this.overlayBg = document.getElementById("myOverlay");
  }

  w3_open() {
    if (this.mySidebar != null && this.overlayBg != null) {
      if (this.mySidebar.style.display === 'block') {
        this.mySidebar.style.display = 'none';
        this.overlayBg.style.display = "none";
      } else {
        this.mySidebar.style.display = 'block';
        this.overlayBg.style.display = "block";
      }
    }
  }

  w3_close() {
    if (this.mySidebar != null && this.overlayBg != null) {
      this.mySidebar.style.display = "none";
      this.overlayBg.style.display = "none";
    }
  }
}
