<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
  <h4>Popular Posts</h4>
</div>
<spinner *ngIf="showLoader"></spinner>
<div class="w3-card-4 w3-container w3-padding-16">
<ul class="w3-ul w3-hoverable w3-white">
  <li class="w3-padding-16" *ngFor="let item of postItems">
    <a href="/web/{{item.post!.category | lowercase}}/post?title={{item.post!.urlTitle}}" style="text-decoration: none;">
      <table>
      <tr>
        <td><img src="{{item.post!.titleImage!}}" alt="{{item.post!.title!}}" class="w3-left w3-margin-right" style="width:50px"></td>
        <td><span class="w3-large">{{item.post!.title}}</span>
          <br>
          <span>{{item.post!.description! | ellipsis:40}}</span></td>
      </tr>
    </table>
    </a>
  </li>
</ul>
</div>
