import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Comment, LocalComment, Reply, Review } from 'src/app/models/classes/Comments';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommentsService } from 'src/app/services/comments/comments.service';
import { PostsService } from 'src/app/services/post/posts.service';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'likes-comments-replies',
  templateUrl: './comments-replies.component.html',
  styleUrls: ['./comments-replies.component.css']
})
export class CommentsRepliesComponent implements OnInit{

  commentUserImage: string = '';

  @Input('docId') docId: string = "";
  @Input("bc") bc: string = "";
  @ViewChild('justAnItem') justAnItem!: ElementRef;
  @Output() commentEvent = new EventEmitter<number>();

  fgComment: FormGroup;
  localComments: LocalComment[] = [];
  commentCount: number = 0;
  addedComments: any[] = [];
  addedReplies: Reply[] = [];
  showingId: string = "";
  showNoComments: boolean = false;
  showLoaderC: boolean = true;
  uid:string = "";

  constructor(private fb: FormBuilder, public authService: AuthService, private postService: PostsService, private commentsService: CommentsService) {
    if (authService.isLoggedIn) {
      this.commentUserImage = authService.user.photoURL;
      this.uid = authService.user.uid;
    }

    this.fgComment = fb.group({
      commentCtrl: new FormControl()
    });
  }

  ngOnInit(): void {
    this.commentsService.getApprovedComments(this.bc, this.docId, "timestamp", true).then((snapshot) => {
      if (snapshot.size > 0) {
        this.showLoaderC = false;
        snapshot.forEach(data => {
          this.commentCount++;
          let id = data.id;
          let comment = <Comment>data.data();
          var replies: Reply[] = [];
          this.commentsService.getApprovedReplies(this.bc, this.docId, id, "timestamp", false).then(snaps => {
            if (snaps.size > 0) {
              snaps.forEach(reply => {
                this.commentCount++;
                let rId = reply.id;
                let replyComment = <Comment>reply.data();
                replies.push(<Reply>{ id: rId, comment: replyComment });
              });
            }
          });
          this.localComments.push(<LocalComment>{ id: id, comment: comment, replies: replies });
        });
        this.commentEvent.emit(this.commentCount);
      }
      else {
        this.showLoaderC = false;
        this.showNoComments = true;
      }
    });
  }

  addComment() {
    this.showNoComments = false;
    var c: Comment = {
      content: this.fgComment.controls['commentCtrl'].value,
      timestamp: Date.now(),
      userId: this.authService.user.uid,
      userName: this.authService.user.displayName,
      userPhotoUrl: this.authService.user.photoURL,
      isApproved: false,
      parentCommentUserName: "",
      likedUserIds: [],
      likes_count:0,
      isReview:false,
      review:<Review>{
        userId:"",
        userName:"",
        stars:0
      }
    }
    //this.addedComments.push(c);
    this.commentsService.addComment(this.bc, this.docId, c).then((data) => {
      var lc = <LocalComment>{ id: data.id, comment: c, replies: [] };
      this.localComments.unshift(lc);
      this.commentCount++;
      this.gotoNewlyAddedComment();
      this.fgComment.reset();
      this.commentEvent.emit(this.commentCount);
    });
  }

  showReply(id: string) {
    if (this.showingId.length > 0) {
      if (this.showingId == id) {
        document.getElementById(this.showingId)!.style.display = 'none';
        this.showingId = "";
        return;
      }
      else {
        document.getElementById(this.showingId)!.style.display = 'none';
      }
    }
    if (document.getElementById(id)!.style.display == 'none') {
      document.getElementById(id)!.style.display = 'block';
      this.showingId = id;
    }
    else {
      document.getElementById(id)!.style.display = 'none';
    }
  }

  addReply(commentId: string, commentOwner: string, replyFieldId: string, textAreaId: string) {
    let text = (<HTMLInputElement>document.getElementById(textAreaId)!).value!;
    var c: Comment = {
      content: text,
      timestamp: Date.now(),
      userId: this.authService.user.uid,
      userName: this.authService.user.displayName,
      userPhotoUrl: this.authService.user.photoURL,
      isApproved: false,
      parentCommentUserName: commentOwner,
      likedUserIds: [],
      likes_count: 0,
      isReview:false,
      review:<Review>{
        userId:"",
        userName:"",
        stars:0
      }

    }
    this.commentsService.addReply(this.bc, this.docId, commentId, c).then(data => {
      var lc = <Reply>{ id: data.id, comment: c };
      for (var i = 0; i < this.localComments.length; i++) {
        if (this.localComments[i].id == commentId) {
          this.localComments[i].replies.push(lc);
        }
      }
      this.commentCount++;
      (<HTMLInputElement>document.getElementById(textAreaId)!).value = "";
      document.getElementById(replyFieldId)!.style.display = 'none';
      this.commentEvent.emit(this.commentCount);
    });
  }

  updateCommentLike(docId: string, commentId: string) {
    let heart = document.getElementById(commentId);
    if (heart?.classList.contains("fa-heart-o")) {
      heart.classList.replace("fa-heart-o", "fa-heart");
      for (var i = 0; i < this.localComments.length; i++) {
        if (this.localComments[i].id == commentId) {
          this.localComments[i].comment.likedUserIds.push(this.authService.user.uid);
          this.commentsService.updateCommentLikes(this.bc, docId, commentId, true, this.authService.user.uid);
          break;
        }
      }
    }
    else {
      heart?.classList.replace("fa-heart", "fa-heart-o");
      for (var i = 0; i < this.localComments.length; i++) {
        if (this.localComments[i].id == commentId) {
          const index = this.localComments[i].comment.likedUserIds.indexOf(this.authService.user.uid);
          this.localComments[i].comment.likedUserIds.splice(index, 1);
          this.commentsService.updateCommentLikes(this.bc, docId, commentId, false, this.authService.user.uid);
          break;
        }
      }
    }
  }

  updateReplyLike(docId: string, commentId: string, replyId: string) {
    let flag = false;
    let heart = document.getElementById(replyId);
    if (heart?.classList.contains("fa-heart-o")) {
      heart.classList.replace("fa-heart-o", "fa-heart");
      for (var i = 0; i < this.localComments.length; i++) {
        if (this.localComments[i].id == commentId) {
          for (var j = 0; j < this.localComments[i].replies.length; j++) {
            if (this.localComments[i].replies[j].id == replyId) {
              this.localComments[i].replies[j].comment.likedUserIds.push(this.authService.user.uid);
              this.commentsService.updateReplyLikes(this.bc, docId, commentId, replyId, true, this.authService.user.uid);
              flag = true;
            }
            if (flag) { break; }
          }
        }
        if (flag) { break; }
      }
    }
    else {
      heart?.classList.replace("fa-heart", "fa-heart-o");
      for (var i = 0; i < this.localComments.length; i++) {
        if (this.localComments[i].id == commentId) {
          for (var j = 0; j < this.localComments[i].replies.length; j++) {
            if (this.localComments[i].replies[j].id == replyId) {
              this.localComments[i].replies[j].comment.likedUserIds.push(this.authService.user.uid);
              const index = this.localComments[i].replies[j].comment.likedUserIds.indexOf(this.authService.user.uid);
              this.localComments[i].replies[j].comment.likedUserIds.splice(index, 1);
              this.commentsService.updateReplyLikes(this.bc, docId, commentId, replyId, false, this.authService.user.uid);
              flag = true;
            }
            if (flag) { break; }
          }
        }
        if (flag) { break; }
      }
    }
  }

  deleteComment(commentContainerId: string, docId: string, commentId: string) {
    document.getElementById(commentContainerId)?.remove();
    for (var i = 0; i < this.localComments.length; i++) {
      if (this.localComments[i].id == commentId) {
        this.commentCount = this.commentCount - 1 - this.localComments[i].replies.length;
        this.localComments.splice(i, 1);
        this.commentsService.deleteComment(this.bc, docId, commentId);
        this.commentEvent.emit(this.commentCount);
        break;
      }
    }
    if (this.commentCount == 0)
      this.showNoComments = true;
  }

  deleteReply(replyContainerId: string, docId: string, commentId: string, replyId: string) {
    var flag = false;
    document.getElementById(replyContainerId)?.remove();
    for (var i = 0; i < this.localComments.length; i++) {
      if (this.localComments[i].id == commentId) {
        for (var j = 0; j < this.localComments[i].replies.length; j++) {
          if (this.localComments[i].replies[j].id == replyId) {
            this.localComments[i].replies.splice(j, 1);
            this.commentsService.deleteReply(this.bc, docId, commentId, replyId);
            flag = true;
          }
          if (flag) { break; }
        }
      }
      if (flag) { break; }
    }
    this.commentCount--;
    if (this.commentCount == 0)
      this.showNoComments = true;
    this.commentEvent.emit(this.commentCount);
  }

  gotoNewlyAddedComment() {
    this.justAnItem.nativeElement.scrollIntoView();
  }

  getRandomString() {
    return Math.random().toString(36).slice(-10);
  }
}
