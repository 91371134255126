export class LegacyProduct {
    title!: string;
    category!:string;
    ageGroup!:number;
    html!:string;
    clicks!:number;

    constructor(title:string, category:string, ageGroup:number,html:string){
        this.title = title;
        this.category = category;
        this.ageGroup = ageGroup;
        this.html = html;
    }

    isEqual(product:LegacyProduct){
        return this.title === product.title && this.category === product.category && this.ageGroup === product.ageGroup && this.html === product.html
    }

    toJson() {
        return JSON.parse(JSON.stringify(this));
    }
}

export interface LocalLP {
    uid:string;
    product:LegacyProduct;
}