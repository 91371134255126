<div class="">
  <div class="w3-row-padding">
    <p class="w3-text-black w3-large">
      <b>Moderation</b>
    </p>
    <p class="w3-text-grey">Moderate posts by editors and comments from users</p>
  </div>
</div>

<div id="content" class="w3-container">
  <div class="w3-bar w3-black">
    <button class="w3-bar-item w3-button" (click)="openTab('posts')">Posts</button>
    <button class="w3-bar-item w3-button" (click)="openTab('comments')">Comments</button>
  </div>
  <div id="posts" class="tab">
    <h2>Posts</h2>
    <p>London is the capital of England.</p>
  </div>

  <div id="comments" class="tab" style="display:none">
    <h2>Comments</h2>
    <p>Paris is the capital of France.</p>
  </div>
</div>
