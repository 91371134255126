import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  isHome: Boolean = true;
  constructor(private router: Router, public authService: AuthService,@Inject(DOCUMENT) private doc: Document) {
    router.events.subscribe((val)=>{
      if(val instanceof NavigationEnd)
      {
        this.isHome = window.location.href.includes("dashboard");
      }
    });
  }

  ngOnInit(): void {
  }

}
