<div class="">
  <div class="w3-row-padding">
    <p class="w3-text-black w3-large">
      <b>New Product</b>
    </p>
    <p class="w3-text-grey">Let's you add new product</p>
  </div>
  <div class="w3-container">
    <form [formGroup]="fg" class="">
      <div class="w3-row">
        <div *ngIf="displayFormError || badUser" class="w3-display-container w3-panel w3-red">
          <span class="w3-button w3-large w3-display-topright"
            onclick="this.parentElement.style.display='none'">&times;</span>
          <p *ngIf="displayFormError">
            Title or Author or Editor is not filled. Please fill them to save.
          </p>
          <p *ngIf="badUser">
            You must be Admin/Seller Admin/ Seller Representative to add new products.
          </p>
        </div>
        <div *ngIf="showSaveMessage" class="w3-display-container w3-panel w3-green">
          <span class="w3-button w3-large w3-display-topright"
            onclick="this.parentElement.style.display='none'">&times;</span>
          <p>Saved the post.)</p>
        </div>
      </div>
      <div class="w3-row">
        <button (click)="expandAccordion('general', 'generalChevron')"
          class="w3-btn w3-block w3-deep-purple w3-left-align">
          General Information<span class="w3-right"><i id="generalChevron" class="fa fa-chevron-down"
              aria-hidden="true"></i></span>
        </button>
        <div id="general" class="w3-hide w3-container">
          <div class="w3-padding w3-margin">
            <label class="w3-text-purple">Title</label>
            <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" required type="text"
              id="title" name="title" formControlName="title" minlength="10" />
            <div *ngIf="
                fg.controls['title'].invalid &&
                (fg.controls['title'].dirty || fg.controls['title'].touched)
              " class="w3-panel w3-text-red w3-padding">
              <div *ngIf="fg.controls['title'].errors!.required">
                Title is required.
              </div>
              <div *ngIf="fg.controls['title'].errors!.minlength">
                Title must be at least 10 characters long.
              </div>
            </div>
          </div>
          <div class="w3-padding">
            <div class="w3-row">
              <div class="w3-col s6 m6 l6 w3-padding">
                <label class="w3-text-purple">Title Image URL</label>
                <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" required
                  type="text" id="titleImage" name="titleImage" formControlName="titleImage" />
                <div *ngIf="
                    fg.controls['titleImage'].invalid &&
                    (fg.controls['titleImage'].dirty ||
                      fg.controls['titleImage'].touched)
                  " class="w3-panel w3-text-red w3-padding">
                  <div *ngIf="fg.controls['titleImage'].errors!.required">
                    Image URL is required.
                  </div>
                  <div *ngIf="fg.controls['titleImage'].errors!.pattern">
                    Enter a valid URL.
                  </div>
                </div>
              </div>
              <div class="w3-col s6 m6 l6 w3-padding">
                <label class="w3-text-purple">Title Image Alt Text</label>
                <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" required
                  type="text" id="imageAlt" name="imageAlt" formControlName="imageAlt" />
              </div>
            </div>
          </div>
          <div class="w3-padding w3-margin">
            <label class="w3-text-purple">Description</label>
            <textarea class="w3-input w3-border w3-border-purple w3-round-large" id="description" maxlength="100"
              required rows="5" cols="100" formControlName="description"></textarea>
            <div *ngIf="
                fg.controls['description'].invalid &&
                (fg.controls['description'].dirty ||
                  fg.controls['description'].touched)
              " class="w3-panel w3-text-red w3-padding">
              <div *ngIf="fg.controls['description'].errors!.required">
                Description is required.
              </div>
              <div *ngIf="fg.controls['description'].errors!.maxlength">
                Description must be upto 100 characters long
              </div>
            </div>
          </div>


          <div class="w3-padding w3-margin">
            <label class="w3-text-purple">Summary</label>
            <quill-editor [styles]="{ height: '800px' }" (onEditorChanged)="changedEditor($event)"
              (onFocus)="editorFocusChange($event)" [(ngModel)]="html" trackChanges="all" [modules]="quillModules"
              formControlName="editorContent" (onEditorCreated)="addBindingCreated($event)"
              placeholder="Write your post here"></quill-editor>
          </div>
        </div>
      </div>
      <div class="w3-row w3-margin-top">
        <button (click)="expandAccordion('pd', 'pdChevron')" class="w3-btn w3-block w3-deep-purple w3-left-align">
          Post Details<span class="w3-right"><i id="pdChevron" class="fa fa-chevron-down" aria-hidden="true"></i></span>
        </button>
        <div class="w3-container w3-hide" id="pd">
          <label class="w3-text-purple">Type</label>
          <select class="w3-select w3-border w3-border-purple w3-round-large" id="category" formControlName="category">
            <option value="" disabled selected>Choose your option</option>
            <!-- <option *ngFor="let category of categories" [ngValue]="category">
                {{ category }}
              </option> -->
          </select>
          <p class="w3-clear"></p>
          <label class="w3-text-purple">Category</label>
          <select class="w3-select w3-border w3-border-purple w3-round-large" id="subCategory"
            formControlName="subCategory">
            <option value="" disabled>Choose your option</option>
            <!-- <option *ngFor="let item of postSettings.category | keyvalue" [ngValue]="item.key" [disabled]="!item.value">
                {{ item.key }}
              </option> -->
          </select>
          <p class="w3-clear"></p>
          <label class="w3-text-purple">Author</label>
          <input class="w3-input w3-border w3-border-purple w3-round-large" required type="text" id="author"
            formControlName="author" />
          <div *ngIf="
                fg.controls['author'].invalid &&
                (fg.controls['author'].dirty || fg.controls['author'].touched)
              " class="w3-panel w3-text-red w3-padding">
            <div *ngIf="fg.controls['author'].errors!.required">
              Author is required.
            </div>
          </div>
          <p class="w3-clear"></p>
          <label class="w3-text-purple">Author Type</label>
          <input class="w3-input w3-border w3-border-purple w3-round-large" required type="text" id="authorType"
            formControlName="authorType" />
        </div>
      </div>
      <div class="w3-row w3-margin-top">
        <button (click)="expandAccordion('other', 'ochevron')" class="w3-btn w3-block w3-deep-purple w3-left-align">
          Other Details<span class="w3-right"><i id="ochevron" class="fa fa-chevron-down" aria-hidden="true"></i></span>
        </button>
        <div id="other" class="w3-container w3-hide">
          <p class="w3-clear"></p>
          <label class="w3-text-purple">Tags (Separate by comma)</label>
          <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" type="text" id="tags"
            formControlName="tags" />
          <div class="w3-margin">
            <label class="w3-text-purple">Target Users</label>
            <input class="w3-radio w3-margin" type="radio" formControlName="target" name="target" value="0" checked />
            <label>Any</label>
            <input class="w3-radio w3-margin" type="radio" formControlName="target" name="target" value="1" />
            <label>Subscriber</label>
            <input class="w3-radio w3-margin" type="radio" formControlName="target" name="target" value="2" />
            <label>Special Subscriber</label>
            <input class="w3-radio w3-margin" type="radio" formControlName="target" name="target" value="3" />
            <label>Premium Subscriber</label>
          </div>
          <div class="w3-margin">
            <input class="w3-check w3-padding w3-margin" type="checkbox" id="disableComments"
              formControlName="disableComments" />
            <label class="w3-text-purple">Disable Comments</label>
            <input class="w3-check w3-padding w3-margin" type="checkbox" id="isTop" formControlName="isTop" />
            <label class="w3-text-purple">Is Top</label>
          </div>
          <label class="w3-text-purple">Amazon Affiliate Links (Separate by comma)</label>
          <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" type="text" id="amafli"
            formControlName="amafli" />
          <label class="w3-text-purple">Sold Product/Course Links (Separate by comma)</label>
          <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" type="text" id="spcl"
            formControlName="spcl" />
          <label class="w3-text-purple">Other Links (Separate by comma)</label>
          <input autocomplete="off" class="w3-input w3-border w3-border-purple w3-round-large" type="text" id="olinks"
            formControlName="olinks" />
        </div>
      </div>
      <div class="w3-row w3-margin-top">
        <button (click)="expandAccordion('uploadAccordion', 'upchevron')"
          class="w3-btn w3-block w3-deep-purple w3-left-align">
          Image Upload<span class="w3-right"><i id="upchevron" class="fa fa-chevron-down" aria-hidden="true"></i></span>
        </button>
        <div id="uploadAccordion" class="w3-container w3-hide">
          <div class="w3-padding">
            <app-uploader [exts]="fileExts"></app-uploader>
          </div>
        </div>
      </div>
      <div class="w3-row w3-margin-top">
        <button (click)="expandAccordion('rui', 'ruiChevron')" class="w3-btn w3-block w3-deep-purple w3-left-align">
          Recently Uploaded Images<span class="w3-right"><i id="ruiChevron" class="fa fa-chevron-down"
              aria-hidden="true"></i></span>
        </button>
        <div class="w3-container w3-hide" id="rui">
          <ul class="w3-ul w3-card-4" #container *ngIf="imageUrls!.length > 0; else ruiNoRecords">
            <li class="w3-bar" *ngFor="let item of imageUrls" ngxClipboard [container]="container" [cbContent]="item">
              <span class="w3-bar-item w3-button w3-white w3-xlarge w3-right"><i class="fa fa-clone"
                  aria-hidden="true"></i></span>
              <img src="{{ item }}" class="w3-bar-item" style="width: 85px; height: 85px" />
            </li>
          </ul>
          <ng-template #ruiNoRecords>
            <div class="w3-center w3-padding">No images to show</div>
          </ng-template>
        </div>
      </div>
      <div class="w3-row w3-margin-top">
        <button (click)="expandAccordion('pi', 'piChevron')" class="w3-btn w3-block w3-deep-purple w3-left-align">
          Post Images<span class="w3-right"><i id="piChevron" class="fa fa-chevron-down" aria-hidden="true"></i></span>
        </button>
      </div>
    </form>
  </div>
</div>
