import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalLP } from 'src/app/models/classes/LegacyProduct';
import { LegacyAffiliateService } from 'src/app/services/legacy-affiliate/legacy-affiliate.service';

@Component({
  selector: 'app-buy-from-amazon',
  templateUrl: './buy-from-amazon.component.html',
  styleUrls: ['./buy-from-amazon.component.css'],
})
export class BuyFromAmazonComponent implements OnInit {
  showLoader = true;
  showError = false;
  showNoRecords = false;
  products: LocalLP[] = [];
  productHtmls:string[] = [];
  slideIndex = 1;
  constructor(
    private lpaService: LegacyAffiliateService,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit(): void {
    this.lpaService.getTopClickedProducts(6).then((snapshot) => {
      if (snapshot.size > 0) {
        snapshot.forEach((data) => {
          let l = <LocalLP>{ uid: data.id, product: data.data() };
          this.productHtmls.push(l.product.html.replace("//ws-in.amazon-adsystem.com","https://ws-in.amazon-adsystem.com"));
        });
        this.showDivs(this.slideIndex);
        this.showLoader = false;
      } else{
        this.showLoader = false;
        this.showNoRecords = true;
      }
    }).catch(err=>{
      this.showLoader = false;
      this.showError = true;
      console.error(err);
    });
  }

  updateClickCount(id: string) {
    this.lpaService.updateClick(id);
  }

  plusDivs(n) {
    this.showDivs((this.slideIndex += n));
  }

  showDivs(n) {
    var i;
    var x = this.doc.getElementsByClassName(
      'mySlides'
    ) as HTMLCollectionOf<HTMLElement>;
    if (n > x.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = x.length;
    }
    for (i = 0; i < x.length; i++) {
      x[i].style.display = 'none';
    }
    x[this.slideIndex - 1].style.display = 'block';
  }
}
