import { Component, Input, OnInit } from '@angular/core';

export class links {
  item!: string;
  link!: string;
}

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css']
})
export class AccordionComponent implements OnInit {

  @Input() icon: string = ""
  @Input() headItem: string = ""
  @Input() children: links[] = []
  accordionId!: string;
  iconClass!: string;

  constructor() { 
  }

  ngOnInit(): void {
    this.accordionId = "myaccordion" + String(Math.floor(Math.random() * 2000))
    this.iconClass = "fa fa-" + this.icon;
  }

  accordion() {
    var x = document.getElementById(this.accordionId);
    if(x != null) {
      if (x.className.indexOf("w3-show") == -1) {
        x.className += " w3-show";
        let y = x.previousElementSibling
        if(y != null)
          y.className += " w3-purple";
      } else { 
        x.className = x.className.replace(" w3-show", "");
        let y = x.previousElementSibling
        if(y != null)
        {  
          y.className = y.className.replace(" w3-purple", "");
        }
      }
    }
  }
}
