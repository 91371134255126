import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Butterfly Mom';
  metaDescription:string = "ButterflyMom is mom blog aimed to guide parents in helping their toddlers and pre schoolers learn early in a playful and fun way. Also it contains store where in mompreneurs sell their products to fellow moms. Events and Courses are organised to share stuff that each mom can be aware of.";

  constructor(private metaService:Meta){}

  ngOnInit(): void {
    this.metaService.addTag({name: 'description', content: this.metaDescription});
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:title', content: 'Mom blog by Butterfly Mom' });
    this.metaService.addTag({name:"keywords", content:"ButterflyMom, Butterfly Mom. butterflymom.in, mom, blog, reviews, affiliate store, ecommerce, mompreneur, sell, courses, events"});
    this.metaService.addTag({property: 'og:description', content: this.metaDescription});
    this.metaService.addTag({property: 'og:image', content: ''});
    this.metaService.addTag({property: 'og:url', content: "https://butterflymom.in/web/home"});
  }
}
