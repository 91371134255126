import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PostSettings } from 'src/app/models/interfaces/settings';
import { SettingsService } from 'src/app/services/settings/settings.service';

@Component({
  selector: 'app-posts-settings',
  templateUrl: './posts-settings.component.html',
  styleUrls: ['./posts-settings.component.css']
})
export class PostsSettingsComponent implements OnInit {
  postSettings!: PostSettings;
  showLoader:boolean = true;
  newCategory = new FormControl();
  newTag = new FormControl();
  constructor(private settingsService: SettingsService) {

  }

  ngOnInit(): void {
    this.settingsService.getSettings("post").then((snapShot)=>{
      if(snapShot) {
        snapShot.forEach((data)=>{
          this.postSettings = <PostSettings>data.data();
        });
        this.showLoader = false
      }
    });
  }

  addCategory(category:string) {
    if(category.length < 3) {
      return
    }
    this.postSettings.category[category] = true;
    this.settingsService.addOrUpdateCategory("post",category, true);
    this.newCategory.reset();
  }

  tapCheckCategory(category:string, checked: boolean) {
    this.postSettings.category[category] = checked;
    console.log(category,checked);
    console.log(this.postSettings.category);
    this.settingsService.addOrUpdateCategory("post",category, checked);
  }

  addTag(tag:string) {
    if(tag.length < 3) {
      return
    }
    this.postSettings.Tags[tag] = true;
    this.settingsService.addOrUpdateTag("post",tag, true);
    this.newTag.reset();
  }

  tapCheckTag(tag:string, checked: boolean) {
    this.postSettings.Tags[tag] = checked;
    this.settingsService.addOrUpdateTag("post",tag, checked);
  }

}
