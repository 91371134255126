import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  collection = 'users';
  constructor(private afs: AngularFirestore) {}

  async getAllUsers() {
    return this.afs.collection(this.collection).ref.get();
  }

  async updateUserRoles(uid:string, removedRoles:number[], addedRoles:number[]) {
    const ref = this.afs.collection(this.collection).doc(uid).ref;
    if (addedRoles.length > 0) {
      let array = firebase.default.firestore.FieldValue.arrayUnion(...addedRoles);
      await ref.update({roles:array});
    }
    if(removedRoles.length > 0) {
      let array = firebase.default.firestore.FieldValue.arrayRemove(...removedRoles);
      await ref.update({roles:array});
    }
  }
}
