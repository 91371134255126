<div [id]="id" class="w3-modal">
  <div class="w3-modal-content w3-card-4 w3-animate-zoom" style="max-width:600px">
    <span (click)="closeModal(false)" class="w3-button w3-xlarge w3-hover-red w3-display-topright" title="Close Modal">&times;</span>
    <div class="w3-purple">
      <h2>{{title}}</h2>
    </div>
    <div class="w3-center"><br>
      <p>{{description}}</p>
    </div>
    <div class="w3-container w3-border-top w3-padding-16 w3-light-grey">
      <button *ngIf="cancel" (click)="closeModal(false)" type="button" class="w3-button w3-red">Cancel</button>
      <button *ngIf="ok" (click)="closeModal(true)" type="button" class="w3-button w3-green">Ok</button>
    </div>

  </div>
</div>
