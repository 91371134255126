import { Component, Input, OnInit } from '@angular/core';
import { Banner } from 'src/app/models/classes/adBanner';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-right-bar',
  templateUrl: './right-bar.component.html',
  styleUrls: ['./right-bar.component.css']
})
export class RightBarComponent implements OnInit {
  @Input() subscribeBlock:boolean = true;
  @Input() blogBlock:boolean = false;
  @Input() reviewBlock:boolean = false;
  @Input() popularPostsBlock:boolean = true;
  @Input() timeLineBlock:boolean = false;
  @Input() authorBlock:boolean = false;
  @Input() ownerBlock:boolean = true;
  @Input() adBlock:boolean = false;
  @Input() amazonProductsBlock:boolean = true;
  @Input() popularTagsBlock:boolean = false;
  @Input() topSellingBlock:boolean = false;
  @Input() productSuggestionsBlock:boolean = false;
  @Input() courseSuggestionsBlock:boolean = false;


  @Input() subCategory:string = 'Other';
  constructor() {

  }

  ngOnInit(): void {
  }

}
