<div class="w3-white" style="min-height: 600px;">
    <div class="w3-panel w3-bottombar w3-border-purple w3-text-purple">
        <h3>Profile</h3>
    </div>
    <div class="w3-row w3-white w3-margin">
        <div class="w3-quarter w3-container w3-padding w3-lavendar w3-center">
            <br>
            <div class="w3-center" style="width: 80%;margin: 10%;">
                <img src="{{user.photoURL}}"
                    class="w3-circle w3-hover-grayscale w3-card-4 w3-border w3-padding w3-white" alt="Profile Picture"
                    width="100%">
            </div>
        </div>
        <div class="w3-half w3-container w3-padding w3-margin">
            <table class="w3-table">
                <tr>
                    <td>Name</td>
                    <td>:</td>
                    <th>{{user.displayName}}</th>
                </tr>
                <tr>
                    <td>Email</td>
                    <td>:</td>
                    <th>{{user.email}}</th>
                </tr>
                <tr>
                    <td>Role</td>
                    <td>:</td>
                    <th *ngIf="authService.isAdmin">Admin</th>
                    <th *ngIf="authService.isEditor">Editor</th>
                    <th *ngIf="authService.isSubscriber">Subscriber</th>
                </tr>

            </table>
        </div>
    </div>
</div>
