<div class="w3-white" style="min-height: 600px;">
    <div class="w3-panel w3-bottombar w3-border-purple w3-text-purple">
        <h3>Blog</h3>
    </div>
    <spinner *ngIf="showLoader"></spinner>
    <no-records *ngIf="showNoRecords"></no-records>
    <error-loading *ngIf="showError"></error-loading>
    <div>
        <div *ngFor="let set of blogSets" class="w3-row" [@fadeInOut]>
            <div *ngFor="let blog of set.postsSet" class="w3-col s12 m6 l2">
                <app-blog-card [lpPost]="blog"></app-blog-card>
            </div>
        </div>
    </div>
</div>
