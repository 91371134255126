<div class="w3-panel w3-bottombar w3-border-deep-purple">
  <div class="w3-left">
  <p class="w3-large">Affiliate Store Settings</p>
</div>
<div class="w3-right">
  <a class="w3-btn w3-pink" href="/dashboard/settings">Back</a>
</div>
</div>
<div class="w3-container">
  <form>
    <label>Category (Separate each data by comma)</label>
    <input class="w3-input" type="text">
    <div class="w3-container w3-lavendar w3-padding w3-text-deep-purple">Get categories from db</div>
    <p class="w3-clear"></p>
    <label>Age Group (Separate each data by comma)</label>
    <input class="w3-input" type="text">
    <div class="w3-container w3-lavendar w3-padding w3-text-deep-purple">Get age groups from db</div>
  </form>
  <p class="w3-clear"></p>
  <button class="w3-btn w3-pink">Save</button>
</div>
