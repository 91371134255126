<header *ngIf="display" class="w3-hide-small w3-container w3-purple font-oswald"
  style="background-image:linear-gradient(#e91e63, #9c27b0);box-shadow: inset 0 4px 10px 0 rgba(0,0,0,0.2)">
  <p class="w3-clear"></p>
  <app-menu></app-menu>
  <div class="w3-row w3-center">
    <div class="w3-twothird">
      <div class="w3-row">
        <div class="w3-half">
          <p class="w3-clear"></p>
          <h1 class="w3-jumbo" style="font-family: 'Josefin Sans';">Butterfly Mom</h1>
        </div>
        <div class="w3-half">
          <app-butterfly-animation></app-butterfly-animation>
        </div>
      </div>
    </div>
    <div class="w3-third w3-margin-top w3-padding w3-center">
      <img src="../../assets/images/headerlogo.png" width="20%">
      <p class="w3-xxlarge" style="font-family: MonteCarlo;"><b>Spread Your Wings</b></p>
    </div>
  </div>
</header>
<header *ngIf="display" class="w3-hide-large w3-hide-medium w3-container w3-center w3-purple font-oswald w3-card-2"
  style="background-image:linear-gradient(#e91e63, #9c27b0);box-shadow: inset 0 4px 10px 0 rgba(0,0,0,0.2)">
  <div >
    <div class="w3-row">
      <div class="w3-display-container">
        <div class="w3-display-topright" style="top: -20px;right: -20px;">
          <app-butterfly-animation></app-butterfly-animation>
        </div>
      </div>
      <h1 class="w3-xxxlarge" style="font-family: Josefin Sans;">Butterfly Mom</h1>
    </div>
    <div class="w3-row" style="margin-top: -20px;">
      <img src="../../assets/images/headerlogo.png" width="40%">
    </div>
    <div class="w3-row" style="font-family: MonteCarlo;margin-top: -30px;">
      <p class="w3-xxlarge"><em>Spread Your Wings</em></p>
    </div>
  </div>
  <app-menu></app-menu>
</header>
