import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.css']
})
export class StoreComponent implements OnInit {
  showLoader:boolean = true;
  showNoRecords:boolean = false;
  showError:boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

}
