<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
    <h4 *ngIf="isBlog;else review">Blog Suggestions</h4>
    <ng-template #review>
        <h4>Review Suggestions</h4>
    </ng-template>
  </div>
  <spinner *ngIf="showLoader"></spinner>
  <error-loading *ngIf="showError"></error-loading>
  <no-records *ngIf="showNoRecords"></no-records>
  <ul class="w3-ul w3-hoverable w3-white">
    <li class="w3-padding-16" *ngFor="let item of postItems">
      <a>
        <img src="{{item.post!.titleImage!}}" alt="item.post!.title!" class="w3-left w3-margin-right" style="width:50px">
        <span class="w3-large">{{item.post!.title}}</span>
        <br>
        <span>{{item.post!.description! | ellipsis:50}}</span>
      </a>
    </li>
  </ul>