<div class="w3-row w3-white w3-margin-top" style="min-height: 600px;" [@fadeInOut]>
  <div class="w3-col l8 s12">
    <div class="w3-panel w3-bottombar w3-border-purple w3-text-purple">
      <div class="w3-left" id="postHeading">
        <h3>Post</h3>
      </div>
      <div class="w3-right w3-margin-top">
        <p><button routerLink="{{backUrl}}" class="w3-btn w3-pink"><i class="fa fa-arrow-left" aria-hidden="true"></i>
            Back</button>
        </p>
      </div>
    </div>
    <spinner *ngIf="showLoader"></spinner>
    <div *ngIf="!showLoader" class="w3-container">
      <div class="w3-row" style="margin-top: -30px;">
        <div class="w3-left">
          <h6 class="w3-opacity">{{post?.author}}</h6>
        </div>
        <div class="w3-right">
          <h6 class="w3-opacity">{{post?.createdDate | date:'fullDate'}}</h6>
        </div>
      </div>
      <div class="w3-center" style="margin-top: -30px;">
        <h3>{{post?.title}}</h3>
        <h5><span *ngIf="post.description!.length > 0">{{post?.description}}
          </span></h5>
      </div>
      <div class="w3-justify">
        <div class="w3-center">
          <img src="{{post?.titleImage}}" alt="{{post?.imageAlt}}" style="width:100%;max-height: 750px;"
            class="w3-padding-16" [@fadeInOut]>
        </div>
        <div class="w3-container">
          <div class="ql-container ql-snow" style="border-width: 0;">
            <div class="ql-editor" [innerHTML]="post.html!">
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
    <div class="w3-row w3-deep-purple">
      <div class="">
        <p class="w3-left w3-margin-left">
          <button class="w3-button w3-circle" (click)="updateParentLike('id_'+docId)">
            <i [ngClass]="hasLiked?'fa-heart':'fa-heart-o'" class="fa w3-text-red w3-xlarge"
              id="id_{{docId}}" aria-hidden="true"></i>
          </button> {{post.likes!.length}}
          <button class="w3-button w3-circle">
            <i class="fa fa-comment w3-text-white w3-xlarge" aria-hidden="true"></i>
          </button>{{commentCount}}
        </p>
        <p class="w3-clear"></p>
        <p class="w3-left w3-margin-left">Category: <strong>{{post.subCategory!}}
            ({{post.category!}})</strong></p>
      </div>
    </div>
    <div *ngIf="docId">
      <likes-comments-replies [bc]="'posts'" [docId]="docId" (commentEvent)="updateCommentCount($event)"></likes-comments-replies>
    </div>
  </div>
  <div class="w3-col l4">
    <app-right-bar [subscribeBlock]="true" [blogBlock]="isBlog" [reviewBlock]="!isBlog" [popularPostsBlock]="true" [timeLineBlock]="true" [authorBlock]="false" [ownerBlock]="true" [adBlock]="true" [amazonProductsBlock]="true"></app-right-bar>
  </div>
</div>
