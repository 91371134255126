import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  COMMENTS_COLLECTION: string = "comments";
  COMMENT_ENTRY_SUBCOLLECTION: string = "comment";
  REPLY_SUBCOLLECTION: string = "replies";

  constructor(private afs: AngularFirestore) { }

  async addComment(baseCollection:string, id: string, comment: any) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).add(comment);
  }

  async addComments(baseCollection:string, id: string, comments: Comment[]) {
    console.log(comments);
    var batch = this.afs.firestore.batch();
    comments.forEach((doc) => {
      var docRef = this.afs.firestore.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).doc();
      batch.set(docRef, doc);
    });
    batch.commit();
  }

  async deleteComment(baseCollection:string, id: string, commentEntryId: string) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).doc(commentEntryId).delete();
  }

  async updateComment(baseCollection:string, id: string, commentEntryId: string, data: any) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).doc(commentEntryId).update(data);
  }

  async addReply(baseCollection:string, id: string, commentEntryId: string, comment: any) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION + "/" + commentEntryId + "/" + this.REPLY_SUBCOLLECTION).add(comment);
  }

  async deleteReply(baseCollection:string, id: string, commentEntryId: string, replyId: string) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION + "/" + commentEntryId + "/" + this.REPLY_SUBCOLLECTION).doc(replyId).delete();
  }

  async updateReply(baseCollection:string, id: string, commentEntryId: string, replyId: string, data: any) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION + "/" + commentEntryId + "/" + this.REPLY_SUBCOLLECTION).doc(replyId).update(data);
  }

  async getComment(baseCollection:string, id: string) {
    return await this.afs.doc(baseCollection + "/" + id).get();
  }

  async getApprovedComments(baseCollection:string, id: string, sortBy: string, desc: boolean) {
    //where("isApproved","==",true)
    return this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).ref.orderBy(sortBy, desc ? "desc" : "asc").get();
  }

  async updateCommentLikes(baseCollection:string, id: string, commentId: string, increment: boolean, email: string) {
    let ref = this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION).doc(commentId).ref;
    var array;
    if (increment) {
      array = firebase.default.firestore.FieldValue.arrayUnion(email);
    }
    else {
      array = firebase.default.firestore.FieldValue.arrayRemove(email);
    }
    ref.update({likedUserIds:array});
  }

  async getApprovedReplies(baseCollection:string, id: string, commentId: string, sortBy: string, desc: boolean) {
    return await this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION + "/" + commentId + "/" + this.REPLY_SUBCOLLECTION).ref.orderBy(sortBy, desc ? "desc" : "asc").get();
  }

  async updateReplyLikes(baseCollection:string, id: string, commentId: string, replyId:string, increment: boolean, email: string) {
    let ref = this.afs.collection(baseCollection + "/" + id + "/" + this.COMMENT_ENTRY_SUBCOLLECTION + "/" + commentId + "/" + this.REPLY_SUBCOLLECTION).doc(replyId).ref;
    var array;
    if (increment) {
      array = firebase.default.firestore.FieldValue.arrayUnion(email);
    }
    else {
      array = firebase.default.firestore.FieldValue.arrayRemove(email);
    }
    ref.update({likedUserIds:array});
  }
}
