<div class="w3-white" style="min-height: 550px;">
  <div class="w3-panel w3-bottombar w3-border-purple w3-text-purple">
      <h3>About</h3>
  </div>
  <div class="w3-container">
    <p>ButterflyMom deal with Parenting, Story Sessions, Printables, etc.
      The blog showcases topics from parenting, travel, cooking, kids activities etc.
      The review showcases review of kids activity products.</p>


  </div>
</div>
