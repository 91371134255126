<div class="w3-card-4">
  <div class="w3-display-container">
  <!-- <button class="w3-button w3-text-red w3-display-topright w3-large"><i class="fa fa-heart"></i></button> -->
  <div class="w3-row">
    <div class="w3-image">
      <img [src]="item.general.cover_image.length == 0 ? '../../../../../../assets/images/dummy.jpg': item.general.cover_image" width="100%" height="125px">
    </div>
  </div>
</div>
  <div class="w3-center">
    <p class="w3-text-purple margin">{{item.pricing.baseCurrencySymbol}} {{sellingPrice}} <span *ngIf="item.pricing.discount > 0">You save <span>{{savings}}</span></span></p>
    <p class="w3-small margin">{{item.general.product_name}}</p>
    <p class="w3-tiny w3-text-grey margin">Sold by <span class="w3-text-pink">{{item.general.seller_name}}</span></p>
  </div>
  <div class="w3-row w3-center">
    <p class="margin" *ngIf="item.data.requires_shipping; else downloadBtn">
      <button class="w3-btn w3-circle w3-white"><i class="fa fa-minus" aria-hidden="true"></i></button>
    <span style="margin-right: 5px;margin-left: 5px;">1</span>
    <button class="w3-btn w3-circle w3-white w3-hover-pink"><i class="fa fa-plus" aria-hidden="true"></i></button>
  </p>
  <ng-template #downloadBtn>
    <p *ngIf="downloadBtn && item.pricing.mrp == 0; else addToCart">
      <button class="w3-btn w3-purple"><i class="fa fa-download" aria-hidden="true"></i> Free Download</button>
    </p>
    <ng-template #addToCart>
      <p>
        <button class="w3-btn w3-purple"><i class="fa fa-cart-plus" aria-hidden="true"></i> Add to Cart</button>
      </p>
    </ng-template>
  </ng-template>
  </div>
</div>
