<!-- Header -->
<header class="w3-container" style="padding-top:22px">
  <h5><b><i class="fa fa-dashboard"></i> Dashboard</b></h5>
</header>

<div class="w3-row-padding w3-margin-bottom">
  <div class="w3-quarter">
    <div class="w3-container w3-red w3-padding-16">
      <div class="w3-left"><i class="fa fa-archive w3-xxxlarge"></i></div>
      <div class="w3-right">
        <h3>{{dashboardData.orders.open_orders}}</h3>
      </div>
      <div class="w3-clear"></div>
      <h4>Orders</h4>
    </div>
  </div>
  <div class="w3-quarter">
    <div class="w3-container w3-blue w3-padding-16">
      <div class="w3-left"><i class="fa fa-ban w3-xxxlarge"></i></div>
      <div class="w3-right">
        <h3>99</h3>
      </div>
      <div class="w3-clear"></div>
      <h4>To Moderate</h4>
    </div>
  </div>
  <div class="w3-quarter">
    <div class="w3-container w3-teal w3-padding-16">
      <div class="w3-left"><i class="fa fa-bell-o w3-xxxlarge"></i></div>
      <div class="w3-right">
        <h3>{{dashboardData.subscribers.total}}</h3>
      </div>
      <div class="w3-clear"></div>
      <h4>Subscribers</h4>
    </div>
  </div>
  <div class="w3-quarter">
    <div class="w3-container w3-orange w3-text-white w3-padding-16">
      <div class="w3-left"><i class="fa fa-users w3-xxxlarge"></i></div>
      <div class="w3-right">
        <h3>{{dashboardData.users.total}}</h3>
      </div>
      <div class="w3-clear"></div>
      <h4>Users</h4>
    </div>
  </div>
</div>
<div class="w3-container">
  <h5>Recent Users</h5>
  <ul class="w3-ul w3-card-4 w3-white">
    <li class="w3-padding-16">
      <img src="/w3images/avatar2.png" class="w3-left w3-circle w3-margin-right" style="width:35px">
      <span class="w3-xlarge">Mike</span><br>
    </li>
    <li class="w3-padding-16">
      <img src="/w3images/avatar5.png" class="w3-left w3-circle w3-margin-right" style="width:35px">
      <span class="w3-xlarge">Jill</span><br>
    </li>
    <li class="w3-padding-16">
      <img src="/w3images/avatar6.png" class="w3-left w3-circle w3-margin-right" style="width:35px">
      <span class="w3-xlarge">Jane</span><br>
    </li>
  </ul>
</div>
<hr>
<h4>Moderation</h4>
<div class="w3-container">
  <h5>Recent Posts/Reviews</h5>
  <div class="w3-row">
    <div class="w3-col m2 text-center">
      <img class="w3-circle" src="/w3images/avatar3.png" style="width:96px;height:96px">
    </div>
    <div class="w3-col m10 w3-container">
      <h4>John <span class="w3-opacity w3-medium">Sep 29, 2014, 9:12 PM</span></h4>
      <p>Keep up the GREAT work! I am cheering for you!! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br>
    </div>
  </div>

  <div class="w3-row">
    <div class="w3-col m2 text-center">
      <img class="w3-circle" src="/w3images/avatar1.png" style="width:96px;height:96px">
    </div>
    <div class="w3-col m10 w3-container">
      <h4>Bo <span class="w3-opacity w3-medium">Sep 28, 2014, 10:15 PM</span></h4>
      <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br>
    </div>
  </div>
</div>
<br>
<div class="w3-container">
  <h5>Recent Comments</h5>
  <div class="w3-row">
    <div class="w3-col m2 text-center">
      <img class="w3-circle" src="/w3images/avatar3.png" style="width:96px;height:96px">
    </div>
    <div class="w3-col m10 w3-container">
      <h4>John <span class="w3-opacity w3-medium">Sep 29, 2014, 9:12 PM</span></h4>
      <p>Keep up the GREAT work! I am cheering for you!! Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br>
    </div>
  </div>
  <div class="w3-row">
    <div class="w3-col m2 text-center">
      <img class="w3-circle" src="/w3images/avatar1.png" style="width:96px;height:96px">
    </div>
    <div class="w3-col m10 w3-container">
      <h4>Bo <span class="w3-opacity w3-medium">Sep 28, 2014, 10:15 PM</span></h4>
      <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p><br>
    </div>
  </div>
</div>
<hr>
