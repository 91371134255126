import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LocalPost, Post } from 'src/app/models/classes/posts';
import { PostsService } from 'src/app/services/post/posts.service';

export interface BlogSet {
  postsSet: LocalPost[];
}

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(750)),
    ]),
  ]
})
export class BlogComponent implements OnInit {
  showLoader:boolean = true;
  showNoRecords:boolean = false;
  showError:boolean = false;
  blogSets: BlogSet[] = [];
  blogPosts: LocalPost[] = [];
  constructor(public postService: PostsService, private titleService: Title) {
    postService.getPublishedPostsForVisitors().then((snapShot) => {
      if (snapShot.size > 0) {
        this.showLoader = false;
        snapShot.forEach((snap) => {
          this.blogPosts.push(<LocalPost>{uid:snap.id, post:<Post>snap.data()});
        });
        var i = 0;
        var temp1: LocalPost[] = [];
        for (i = 1; i <= this.blogPosts.length; i++) {
          temp1.push(this.blogPosts[i - 1]);
          if (i % 6 == 0 || i == this.blogPosts.length) {
            this.blogSets.push(<BlogSet>{ postsSet: temp1 });
            temp1 = [];
          }
        }
      }else{
        this.showLoader = false;
        this.showNoRecords = true;
      }
    }).catch((err)=>
    {
      this.showLoader = false;
      this.showError = true;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle("Blog - Butterfly Mom");
  }

}
