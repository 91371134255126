import { AfterViewInit, ElementRef } from '@angular/core';
import { Component, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-butterfly-animation',
  templateUrl: './buttefly-animation.component.html',
  styleUrls: ['./buttefly-animation.component.css']
})
export class ButteflyAnimationComponent{

  constructor() {
  }

}
