<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
  <h4>Buy @ Amazon</h4>
</div>
<spinner *ngIf="showLoader"></spinner>
<div class="w3-card-4 w3-container w3-padding-16">
<div class="w3-content w3-display-container">
  <div class="mySlides w3-row">
    <div class="w3-half w3-center" *ngFor="let item of productHtmls.slice(0, 2)"
      [innerHTML]="item | safe: 'html'"></div>
  </div>
  <div class="mySlides w3-row">
    <div class="w3-half w3-center" *ngFor="let item of productHtmls.slice(2, 4)"
      [innerHTML]="item | safe: 'html'"></div>
  </div>
  <div class="mySlides w3-row" *ngIf="productHtmls.slice(4, 6).length > 0">
    <div class="w3-half w3-center" *ngFor="let item of productHtmls.slice(4, 6)"
      [innerHTML]="item | safe: 'html'"></div>
  </div>
  <button class="w3-button w3-white w3-hover-purple w3-display-left" (click)="plusDivs(-1)">
    &#10094;
  </button>
  <button class="w3-button w3-white w3-hover-purple w3-display-right" (click)="plusDivs(1)">
    &#10095;
  </button>
</div>
</div>
