import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { HTMLDoc } from 'src/app/models/htmldoc';
import { Post } from 'src/app/models/classes/posts';
import { PostStatus } from 'src/app/models/enums/poststatus';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  today: any = Date.now()

  constructor(private afs: AngularFirestore) { }

  async savePost(post: Post) {
    const ref_post = await this.afs.collection('posts').add(post.toJson());
    return ref_post.id;
  }

  async updatePost(postId: string, post:Post) {
    await this.afs.collection('posts').doc(postId).update(post);
  }

  async updatePostX(postId: string, updatedPost: any) {
    await this.afs.collection('posts').doc(postId).update(updatedPost);
  }

  async publishPost(postId: string) {
    await this.afs.collection('posts').doc(postId).update({ status: PostStatus.Published, updatedDate: this.today });
  }

  async unPublishPost(postId: string) {
    await this.afs.collection('posts').doc(postId).update({ status: PostStatus.Unpublished, updatedDate: this.today });
  }

  async deletePost(postId: string) {
    await this.afs.collection('posts').doc(postId).delete();
  }

  async getPost(urlTitle:string) {
    return await this.afs.collection('posts').ref.where("urlTitle", "==", urlTitle).get();
  }

  async getAllPostsAndReviews(limit: number = -1) {
    if (limit == -1)
      return this.afs.collection("posts").ref.orderBy("updatedDate", "desc").get();
    else
      return this.afs.collection("posts").ref.orderBy("updatedDate", "desc").limit(limit).get();
  }

  async getPublishedPostsForVisitors() {
    return this.afs.collection("posts").ref.where("status", "==", PostStatus.Published).where("category", "==", "Blog").get();
  }

  async getPublishedReviewsForVisitors() {
    return this.afs.collection("posts").ref.where("status", "==", PostStatus.Published).where("category", "==", "Reviews").get();
  }

  async getPublishedItemsForVisitors(limit: number = -1) {
    if (limit == -1) {
      return this.afs.collection("posts").ref.where("status", "==", PostStatus.Published).get();
    }
    else {
      return this.afs.collection("posts").ref.where("status", "==", PostStatus.Published).limit(limit).get();
    }
  }

  async getPublishedItemsByCategory(type:string, category:string, limit:number) {
    return this.afs.collection("posts").ref.where('category',"==",type).where("status", "==", PostStatus.Published).where("subCategory","==",category).limit(limit).orderBy("likes").get();
  }

  async getPublishedPopularPostsForVisitors(limit:number)
  {
    return this.afs.collection("posts").ref.where("status", "==", PostStatus.Published).limit(limit).orderBy("likes").get();
  }

  async updateLike(postId:string, up:boolean, uid:string)
  {
    const ref = await this.afs.collection('posts').doc(postId).ref;
    var array;
    if (up) {
      array = firebase.default.firestore.FieldValue.arrayUnion(uid.toString());
    }
    else {
      array = firebase.default.firestore.FieldValue.arrayRemove(uid.toString());
    }
    ref.update({likes:array});
  }
}
