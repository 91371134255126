<div class="w3-panel w3-border-purple w3-bottombar w3-text-purple">
    <h4>Tags</h4>
</div>
<div class="w3-container w3-white">
    <p>
        <span class="w3-tag w3-pink w3-margin-bottom">mom</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">New York</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">entrepreneurship</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">Hats</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">Norway</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">kids</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">Ideas</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">Deals</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">learnings</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">News</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">Clothing</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">child development</span>
        <span class="w3-tag w3-light-grey w3-small w3-margin-bottom">Jeans</span> <span
            class="w3-tag w3-light-grey w3-small w3-margin-bottom">Trends</span>
    </p>
</div>