<div class="">
    <div class="w3-panel w3-white w3-bottombar w3-border-purple w3-text-purple">
        <h3>Affiliate Store</h3>
    </div>
    <div class="w3-row" style="margin-top:-30px;">
        <form [formGroup]="fg" class="w3-container w3-text-white">
            <p class="w3-medium">Filter</p>
            <div class="w3-col s12 m6 l4 w3-padding">
                <label>Category</label>
                <select class="w3-select w3-border w3-border-purple w3-round-large" id="category"
                    formControlName="category">
                    <option value="" selected>All</option>
                    <option *ngFor="let category of categories" [ngValue]="category">{{category}}</option>
                </select>
            </div>
            <div class="w3-col s12 m6 l4  w3-padding">
                <label>Age Group</label>
                <select class="w3-select w3-border w3-border-purple w3-round-large" id="agegroup"
                    formControlName="agegroup">
                    <option value="-1" selected>All</option>
                    <option *ngFor="let group of ageGroups" [ngValue]="group">{{group}} - {{group+1}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="w3-white">
    <hr style="border-top: 1px solid purple;">
    <spinner *ngIf="showLoader"></spinner>
    <no-records *ngIf="showNoRecords"></no-records>
    <error-loading *ngIf="showError"></error-loading>
    <div>
        <div *ngFor="let set of legacyProductsSet" class="w3-row" [@fadeInOut]>
            <div *ngFor="let item of set.products" class="w3-col s6 m3 l1">
                <app-af-card [legacy]=true [data]="item"></app-af-card>
            </div>
        </div>
    </div>
</div>
</div>
