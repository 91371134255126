import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.css']
})
export class TitlebarComponent implements OnInit {
  isHome: Boolean = true;
  display:boolean = true;

  constructor(private router: Router) {
    router.events.subscribe((val)=>{
      if(val instanceof NavigationEnd)
      {
        this.isHome = val.url.includes("home");
        if(window.location.href.includes("dashboard")){
          this.display = false;
        }
        else {
          this.display = true;
        }
      }
    });
   }

  ngOnInit(): void {

  }

}
