<div class="w3-white">
  <div class="w3-bar w3-top w3-purple w3-pink" style="z-index:4">
    <button class="w3-bar-item w3-button w3-hide-large w3-hover-none w3-hover-text-light-grey" (click)="w3_open()"><i
        class="fa fa-bars"></i> &nbsp;Menu</button>
  </div>
  <nav class="w3-sidebar w3-collapse w3-white w3-animate-left" style="z-index:3;width:300px;scroll-behavior: unset;"
    id="mySidebar"><br>
    <a class="w3-right w3-text-black w3-margin-right w3-hide-large" (click)="w3_close()"><i
        class="fa fa-remove fa-fw"></i></a>
    <div class="w3-container w3-row w3-border-bottom">
      <div class="w3-col s4 w3-center">
        <img src="{{authService.user.photoURL}}" class="w3-circle w3-margin-right" style="width:46px">
        <div class="w3-small w3-margin-top">
          <b>
            <span *ngIf="authService.isAdmin">Admin</span>
            <span *ngIf="authService.isEditor">Editor</span> User
          </b>
        </div>
      </div>
      <div class="w3-col s8 w3-bar w3-margin-top">
        <span>Welcome, <strong>{{authService.userName}}</strong></span>
        <br>
        <a routerLink="profile" class="w3-bar-item w3-button"><i class="fa fa-user"></i></a>
        <a href="#" class="w3-bar-item w3-button"><i class="fa fa-cog"></i></a>
      </div>
    </div>
    <div class="w3-container">
      <h5>Dashboard</h5>
    </div>
    <div class="w3-bar-block">
      <!-- For Admin/ Editor/ Seller/ Seller Rep/ Moderator -->
      <a routerLink="overview" routerLinkActive="w3-purple" class="w3-bar-item w3-button w3-padding"><i
          class="fa fa-users fa-fw"></i>&nbsp; Overview</a>
      <!-- For Admin/ Editor -->
      <app-accordion *ngIf="authService.isAdmin || authService.isEditor" [icon]="postIcon" [headItem]="postHeader" [children]="postChildren"></app-accordion>
      <!-- For Admin/ Seller/ Seller Rep -->
      <app-accordion *ngIf="authService.isAdmin || isSeller" [icon]="myStoreIcon" [headItem]="myStoreHeader" [children]="myStoreChildren"></app-accordion>
      <!-- For Admin -->
      <app-accordion *ngIf="authService.isAdmin" [icon]="afStoreIcon" [headItem]="afStoreHeader" [children]="afStoreChildren"></app-accordion>
      <!-- For Admin/ Course Provider -->
      <app-accordion *ngIf="authService.isAdmin || isCourseProvider" [icon]="coursesIcon" [headItem]="coursesHeader" [children]="coursesChildren"></app-accordion>
      <!-- For Admin/ Event Provider -->
      <app-accordion *ngIf="authService.isAdmin || isEventProvider" [icon]="eventsIcon" [headItem]="eventsHeader" [children]="eventsChildren"></app-accordion>
      <!-- For Admin/ Moderator -->
      <a *ngIf="authService.isAdmin || isModerator" routerLink="moderation" routerLinkActive="w3-purple" class="w3-bar-item w3-button w3-padding"><i
          class="fa fa-ban fa-fw"></i>&nbsp; Moderation</a>
      <!-- For Admin -->
      <a *ngIf="authService.isAdmin" routerLink="orders" routerLinkActive="w3-purple"
        class="w3-bar-item w3-button w3-padding"><i class="fa fa-diamond fa-fw"></i>&nbsp; Orders</a>
      <!-- For Admin -->
      <a *ngIf="authService.isAdmin" routerLink="users" routerLinkActive="w3-purple"
        class="w3-bar-item w3-button w3-padding"><i class="fa fa-users fa-fw"></i>&nbsp; Users</a>
      <!-- For Admin -->
      <a *ngIf="authService.isAdmin" routerLink="settings" routerLinkActive="w3-purple"
        class="w3-bar-item w3-button w3-padding"><i class="fa fa-cog fa-fw"></i>&nbsp; Settings</a>
      <!-- For Admin -->
      <a *ngIf="authService.isAdmin" routerLink="views" routerLinkActive="w3-purple"
        class="w3-bar-item w3-button w3-padding"><i class="fa fa-eye fa-fw"></i>&nbsp; Analytics</a><br><br>
      <p class="w3-bar-item w3-button w3-padding w3-small"><b>v0.0.0</b></p>
    </div>
  </nav>
  <div class="w3-overlay w3-hide-large w3-animate-opacity" (click)="w3_close()" style="cursor: pointer; display: none;"
    title="close side menu" id="myOverlay"></div>
  <div class="w3-main w3-white" style="margin-left:300px;margin-top:43px;">
    <router-outlet></router-outlet>
  </div>
</div>