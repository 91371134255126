import { AfterContentInit, OnInit } from '@angular/core';
import { AfterViewInit, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import postscribe from 'postscribe';

@Component({
  selector: 'app-ad',
  templateUrl: './ad.component.html',
  styleUrls: ['./ad.component.css'],
})
export class AdComponent implements OnInit, AfterViewInit, AfterContentInit {
  @Input() adType: String = '';

  array = [
    {
      image: '../../../../../assets/images/audible.jpg',
      link: 'https://www.amazon.in/dp/B077S5CVBQ/?ref=assoc_tag_sept19?actioncode=AINOTH066082819002X&tag=wedb-21',
      alt: 'Try Audible',
    },
    {
      image: '../../../../../assets/images/prime.jpg',
      link: 'https://www.amazon.in/tryprime?tag=wedb-21;',
      alt: 'Try Amazon Prime',
    },
  ];

  squareAd = `<script type="text/javascript" language="javascript">  var aax_size='300x250';  var aax_pubname = 'puzzles06-21';  var aax_src='302';</script><script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script>`;
  longAd = `<script type="text/javascript" language="javascript">  var aax_size='300x600'; var aax_pubname = 'puzzles06-21';  var aax_src='302';</script><script type="text/javascript" language="javascript" src="http://c.amazon-adsystem.com/aax2/assoc.js"></script>`;
  idVal = Math.floor(Math.random() * 16777215).toString(16);
  adItem: any;

  constructor(public sanitizer: DomSanitizer) {}
  ngAfterContentInit(): void {
    if (this.adType.length < 1) {
      let a = Math.floor(Math.random() * 100);
      if (a % 2 == 0) {
        this.adItem = this.array[Math.floor(Math.random() * this.array.length)];
      } else {
        this.adType = 'square';
      }
    }
  }
  ngOnInit(): void {
    setTimeout(()=>{
    }, 2000);
  }

  ngAfterViewInit() {
    let ad = '';
    switch (this.adType) {
      case 'square':
        ad = this.squareAd;
        break;
      case 'long':
        ad = this.longAd;
        break;
      default:

        break;
    }
    if (ad.length > 0) {
      postscribe('#' + this.idVal, ad);
    }
  }
}
