import { Component, Input, OnInit } from '@angular/core';
import { Upload } from 'src/app/models/interfaces/upload';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.css']
})
export class UploaderComponent implements OnInit {

  files: File[] = [];
  @Input() exts:string[] = [];
  @Input() folder:string = "";

  percentage:number = 0;

  constructor() { }

  ngOnInit(): void {
  }

  onDrop(files: FileList) {
    this.percentage = 0;
    if(this.files.length > 0)
      this.files = [];
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      let ext = file?.name.split('.').pop();
      if(this.isSupportedFormat(ext!))
        this.files.push(file!);
    }
  }

  uploadFile($event:any) {
    this.percentage = 0;
    if(this.files.length > 0)
      this.files = [];
    let fs = $event.target.files;
    for (let i = 0; i < fs.length; i++) {
      let file = fs[i];
      let ext = file?.name.split('.').pop();
      if(this.isSupportedFormat(ext!))
        this.files.push(file!);
    }
  }

  isSupportedFormat(ext:string) {
    var flag = false;
    for(var i=0; i< this.exts.length; i++)
    {
      if(this.exts[i].toLowerCase() == ext.toLowerCase()) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  addPercentage(pct:Upload){
    this.percentage = pct.percentage;
  }

}
