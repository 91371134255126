import { Component, OnInit } from '@angular/core';
import { LocalPost } from 'src/app/models/classes/posts';
import { PostsService } from 'src/app/services/post/posts.service';

@Component({
  selector: 'app-popular-posts',
  templateUrl: './popular-posts.component.html',
  styleUrls: ['./popular-posts.component.css']
})
export class PopularPostsComponent implements OnInit {

  postItems:LocalPost[] = []
  showLoader:boolean = true;

  constructor(private postsService:PostsService) { }

  ngOnInit(): void {
    this.postsService.getPublishedPopularPostsForVisitors(5).then((snapshot)=>{
      console.log(snapshot.size);
      if(snapshot.size > 0){
        snapshot.forEach((data)=>{
          let lp = <LocalPost>{uid:data.id,post:data.data()}
          this.postItems.push(lp);
        });
      }
      this.showLoader = false;
    }).catch(err=>{
      this.showLoader = false;
      console.error(err);
    });
  }

}
