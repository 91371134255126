import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tag-results',
  templateUrl: './tag-results.component.html',
  styleUrls: ['./tag-results.component.css']
})
export class TagResultsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
