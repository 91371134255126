import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalPost, Post } from 'src/app/models/classes/posts';
import { PostsService } from 'src/app/services/post/posts.service';
import { StateService } from 'src/app/services/state/state.service';

@Component({
  selector: 'app-recent-blogs',
  templateUrl: './recent-blogs.component.html',
  styleUrls: ['./recent-blogs.component.css']
})
export class RecentBlogsComponent implements OnInit {
  showLoader: boolean = true;
  showNoRecords: boolean = false;
  showError: boolean = false;
  localPosts: LocalPost[] = [];
  constructor(private postsService: PostsService,private router:Router, private stateService: StateService) { }

  ngOnInit(): void {
    this.postsService.getPublishedItemsForVisitors(5).then((snapshot) => {
      if (snapshot.size > 0) {
        this.showLoader = false;
        snapshot.forEach((doc) => {
          let post = <Post>doc.data();
          this.localPosts.push({ uid: doc.id, post: post });
        });
      }
      else {
        this.showLoader = false;
        this.showNoRecords = true;
      }
    }).catch(err => {
      this.showLoader = false;
      this.showError = true;
    });
  }

  openPost(localPost:any){
    this.stateService.setData(localPost);
    if(localPost.post.category == "Blog")
      this.router.navigate(['web/blog/post'],{ queryParams:{ title: localPost.post.urlTitle }});
    else if(localPost.post.category == "Reviews")
      this.router.navigate(['web/reviews/post'],{ queryParams:{ title: localPost.post.urlTitle }});
  }

  extractContent(s, space) {
    var span= document.createElement('span');
    span.innerHTML= s;
    if(space) {
      var children= span.querySelectorAll('*');
      for(var i = 0 ; i < children.length ; i++) {
        if(children[i].textContent)
        (<HTMLElement>children[i]).textContent += ' ';
        else
        (<HTMLElement>children[i]).innerText += ' ';
      }
    }
    return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
  };

}
