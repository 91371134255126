import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LegacyProduct } from 'src/app/models/classes/LegacyProduct';

@Component({
  selector: 'app-af-card',
  templateUrl: './af-card.component.html',
  styleUrls: ['./af-card.component.css']
})
export class AfCardComponent implements OnInit {

  @Input() data!:LegacyProduct;
  @Input() legacy:boolean = false;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

}
