import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DashboardDataOperation } from 'src/app/models/enums/dashboardDataOperation';
import { Subscription } from 'src/app/models/subscription';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DashbaordDataService } from 'src/app/services/dashboard-data/dashbaord-data.service';
import { SubscribeService } from 'src/app/services/subscribe/subscribe.service';

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css'],
})
export class SubscribeComponent implements OnInit {
  @ViewChild('modalDialog') modalDialog!: ElementRef;
  @ViewChild('subscribeHomeBtn') subscribeHomeBtn!: ElementRef;
  @ViewChild('subscribeBtn') subscribeBtn!: ElementRef;
  @ViewChild('subscribedMsg') subscribedMsg!: ElementRef;

  homeSubsFG: FormGroup;
  subsFG: FormGroup;
  email: string = '';
  subscription!: Subscription;
  alreadySubscribed: boolean = false;
  subscribed: boolean = false;
  displaySubscribed: boolean = false;

  constructor(
    public authService: AuthService,
    private subscriptionService: SubscribeService,
    private dashboardDataService: DashbaordDataService,
    private fb: FormBuilder
  ) {
    if (authService.isLoggedIn) {
      this.subscriptionService
        .hasSubscribed(this.authService.user.email)
        .then((data) => {
          if (data) {
            this.subscribed = true;
          }
        });
    }
    this.homeSubsFG = fb.group({
      emailElm: new FormControl('', [Validators.required, Validators.email]),
    });
    this.subsFG = fb.group({
      blog: new FormControl({ value: false, disabled: false }),
      reviews: new FormControl({ value: false, disabled: false }),
      courses: new FormControl({ value: false, disabled: true }),
      events: new FormControl({ value: false, disabled: true }),
      products: new FormControl({ value: false, disabled: true }),
      suggest: new FormControl({ value: false, disabled: true }),
    });
  }

  ngOnInit(): void {
    this.homeSubsFG.valueChanges.subscribe(() => {
      if (
        this.homeSubsFG.controls['emailElm'].value.length > 0 &&
        this.homeSubsFG.controls['emailElm'].valid
      )
        this.subscribeHomeBtn.nativeElement.disabled = false;
      else {
        this.subscribeHomeBtn.nativeElement.disabled = true;
        this.alreadySubscribed = false;
      }
    });
    this.subsFG.valueChanges.subscribe(() => {
      this.subscribeBtn.nativeElement.disabled = false;
    });
  }

  openModal() {
    this.email = this.homeSubsFG.controls['emailElm'].value;
    this.subscriptionService.hasSubscribed(this.email).then((data) => {
      if (data.valueOf()) {
        this.alreadySubscribed = true;
        this.modalDialog.nativeElement.style.display = 'none';
      } else {
        this.modalDialog.nativeElement.style.display = 'block';
      }
    });
  }

  closeModal() {
    this.modalDialog.nativeElement.style.display = 'none';
  }

  subscribe() {
    this.subscription = {
      email: this.email,
      subscriptions: {
        blog: this.subsFG.controls['blog'].value,
        review: this.subsFG.controls['reviews'].value,
        events: this.subsFG.controls['events'].value,
        courses: this.subsFG.controls['courses'].value,
        newProducts: this.subsFG.controls['products'].value,
        suggestions: this.subsFG.controls['suggest'].value,
      },
      createdDate: Date.now().toString()
    };
    this.subscriptionService
        .hasSubscribed(this.authService.user.email)
        .then((data) => {
          if (!data) {
            this.subscriptionService.subscribe(this.subscription).then((data) => {
              if (data) {
                this.dashboardDataService.updateSubscribersData(DashboardDataOperation.Increment, this.subscription.subscriptions.blog ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing,
                  this.subscription.subscriptions.review ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing,
                  this.subscription.subscriptions.courses ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing,
                  this.subscription.subscriptions.events ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing,
                  this.subscription.subscriptions.newProducts ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing,
                  this.subscription.subscriptions.suggestions ? DashboardDataOperation.Increment : DashboardDataOperation.DoNothing);
                this.displaySubscribed = true;
                setTimeout(() => {
                  this.displaySubscribed = false;
                }, 2000);
                setTimeout(() => {
                  this.closeModal();
                }, 1000);
                setTimeout(() => {
                  this.subscribed = true;
                }, 1000);
              }
            });
          } else {
            this.alreadySubscribed = true
            setTimeout(()=>{
              this.subscribed = true;
            });
          }
        });
  }
}
