<div class="w3-row-padding">
  <p class="w3-text-black w3-large">
    <b>Settings</b>
  </p>
  <p class="w3-text-grey">Change these to modify defaults</p>
</div>
<div class="w3-bar w3-lavendar w3-card">
  <a routerLinkActive="w3-purple" routerLink="home-slider" class="w3-bar-item w3-button w3-mobile">Home Slider</a>
  <a routerLinkActive="w3-purple" routerLink="blog" class="w3-bar-item w3-button w3-mobile">Blog</a>
  <a routerLinkActive="w3-purple" routerLink="reviews" class="w3-bar-item w3-button w3-mobile">Review</a>
  <a routerLinkActive="w3-purple" routerLink="affiliate" class="w3-bar-item w3-button w3-mobile">Affiliate Store</a>
</div>

<div class="w3-container">
  <router-outlet></router-outlet>
</div>
