import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LegacyProduct } from 'src/app/models/classes/LegacyProduct';
import { LegacyAffiliateService } from 'src/app/services/legacy-affiliate/legacy-affiliate.service';

export interface LProductSet {
  products:LegacyProduct[];
}

@Component({
  selector: 'app-affiliate-store',
  templateUrl: './affiliate-store.component.html',
  styleUrls: ['./affiliate-store.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({
        opacity: 0
      })),
      transition('void <=> *', animate(250)),
    ]),
  ]
})
export class AffiliateStoreComponent implements OnInit {
  showLoader:boolean = true;
  showNoRecords:boolean = false;
  showError:boolean = false;
  legacyProductsSet:LProductSet[] = [];
  ageGroups:number[] = [0,1,2,3,4];
  categories:string[] = ["Toys & Games", "Books", "Gadgets", "Essentials"];

  fg:FormGroup;

  constructor(private legacyAffiliateService:LegacyAffiliateService, fb:FormBuilder) {
    let defaultCategory = "";
    let defaultAgeGroup = -1;
    this.fg = fb.group({
      category: new FormControl(defaultCategory),
      agegroup: new FormControl(defaultAgeGroup)
    });
    this.populateView(defaultCategory, defaultAgeGroup);
  }

  ngOnInit(): void {
    this.fg.valueChanges.subscribe(()=>{
      this.legacyProductsSet = [];
      this.showLoader = true;
      this.populateView(this.fg.controls['category'].value, this.fg.controls['agegroup'].value);
    });
  }

  populateView(category:string, ageGroup:number){
    this.legacyAffiliateService.getProducts(category, ageGroup).then(snapshot =>{
      console.log(snapshot.size);
      if(snapshot.size > 0)
      {
        this.showError = false;
        this.showNoRecords = false;
        var legacyProducts:LegacyProduct[] = [];
        snapshot.forEach((snap) => {
          legacyProducts.push(<LegacyProduct>snap.data());
        });
        var i = 0;
        var temp1: LegacyProduct[] = [];
        for (i = 1; i <= legacyProducts.length; i++) {
          temp1.push(legacyProducts[i - 1]);
          if (i % 12 == 0 || i == legacyProducts.length) {
            this.legacyProductsSet.push(<LProductSet>{ products: temp1 });
            temp1 = [];
          }
        }
        this.showLoader = false;
      }else{
        this.showLoader = false;
        this.showNoRecords = true;
      }
    }).catch((err)=>{
      this.showLoader = false;
      this.showError = true;
    });
  }



}
