import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  COLLECTION_NAME = "settings";
  constructor(private afs: AngularFirestore) { }

  async getSettings(type:string) {
    return this.afs.collection(this.COLLECTION_NAME).doc(type.toLowerCase()).get();
  }

  async addOrUpdateCategory(type: string, category: string, value: boolean) {
    category = "category."+category;
    var update = {};
    update[category] = value;
    console.log(update);
    console.log(type);
    await this.afs.collection(this.COLLECTION_NAME).doc(type.toLowerCase()).update(update);
  }

  async addOrUpdateTag(type: string, tag: string, value: boolean) {
    tag = "Tags."+tag;
    var update = {};
    update[tag] = value;
    await this.afs.collection(this.COLLECTION_NAME).doc(type.toLowerCase()).update(update);
  }
}
