import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-published-post',
  templateUrl: './published-post.component.html',
  styleUrls: ['./published-post.component.css']
})
export class PublishedPostComponent implements OnInit {

  urlTitle!:any;

  constructor(private route: ActivatedRoute) {

   }

  ngOnInit(): void {
    this.urlTitle = this.route.snapshot.queryParamMap.get('title');
  }

}
