import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'no-records',
  templateUrl: './no-records.component.html',
  styleUrls: ['./no-records.component.css']
})
export class NoRecordsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
