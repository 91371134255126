import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLoginGuardGuard } from './guards/admin-login-guard.guard';
import { SignInGuardGuard } from './guards/sign-in-guard.guard';
import { AdminLoginErrorComponent } from './ui/dashboard/admin-login-error/admin-login-error.component';
import { AfLegacyAddProductComponent } from './ui/dashboard/af-store/af-legacy-add-product/af-legacy-add-product.component';
import { AfStoreManageProductsComponent } from './ui/dashboard/af-store/af-store-manage-products/af-store-manage-products.component';
import { AfStoreOrdersComponent } from './ui/dashboard/af-store/af-store-orders/af-store-orders.component';
import { DashboardComponent } from './ui/dashboard/dashboard/dashboard.component';
import { ModerationComponent } from './ui/dashboard/moderation/moderation.component';
import { OrdersComponent } from './ui/dashboard/orders/orders.component';
import { OverviewComponent } from './ui/dashboard/overview/overview.component';
import { EditPostComponent } from './ui/dashboard/post/edit-post/edit-post.component';
import { ManagePostsComponent } from './ui/dashboard/post/manage-posts/manage-posts.component';
import { NewPostComponent } from './ui/dashboard/post/new-post/new-post.component';
import { PostComponent } from './ui/dashboard/post/post.component';
import { PublishedPostComponent } from './ui/dashboard/post/published-post/published-post.component';
import { SellerDashboardComponent } from './ui/dashboard/seller/seller-dashboard/seller-dashboard.component';
import { SellerComponent } from './ui/dashboard/seller/seller.component';
import { StoreAddProductComponent } from './ui/dashboard/seller/store-add-product/store-add-product.component';
import { StoreManageComponent } from './ui/dashboard/seller/store-manage/store-manage.component';
import { StoreOrdersComponent } from './ui/dashboard/seller/store-orders/store-orders.component';
import { AffiliateSettingsComponent } from './ui/dashboard/settings/affiliate-settings/affiliate-settings.component';
import { HomeSliderComponent } from './ui/dashboard/settings/home-slider/home-slider.component';
import { PostsSettingsComponent } from './ui/dashboard/settings/posts-settings/posts-settings.component';
import { ReviewSettingsComponent } from './ui/dashboard/settings/review-settings/review-settings.component';
import { SettingsComponent } from './ui/dashboard/settings/settings.component';
import { UsersComponent } from './ui/dashboard/users/users.component';
import { ViewsComponent } from './ui/dashboard/views/views.component';
import { PageNotFoundComponent } from './ui/page-not-found/page-not-found.component';
import { BlogPostComponent } from './ui/web/content/blog/blog-post/blog-post.component';
import { BlogComponent } from './ui/web/content/blog/blog.component';
import { ContentComponent } from './ui/web/content/content.component';
import { HomeComponent } from './ui/web/content/home/home.component';
import { ProfileComponent } from './ui/web/content/profile/profile.component';
import { ReviewsComponent } from './ui/web/content/reviews/reviews.component';
import { AfPdpComponent } from './ui/web/content/shop/affiliate-store/af-pdp/af-pdp.component';
import { AffiliateStoreComponent } from './ui/web/content/shop/affiliate-store/affiliate-store.component';
import { DownloadsComponent } from './ui/web/content/shop/downloads/downloads.component';
import { StoreComponent } from './ui/web/content/shop/store/store.component';
import { AboutComponent } from './ui/web/footer/about/about.component';
import { PrivacyPolicyComponent } from './ui/web/footer/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './ui/web/footer/terms-of-use/terms-of-use.component';
import { ForgotPasswordComponent } from './ui/web/forgot-password/forgot-password.component';
import { SignIpComponent } from './ui/web/sign-ip/sign-ip.component';
import { UserLoginComponent } from './ui/web/user-login/user-login.component';

const routes: Routes = [
  { path: '', redirectTo: 'web/home', pathMatch: 'full' },
  { path: '*', component: PageNotFoundComponent },
  {
    path: 'web', component: ContentComponent, children: [
      { path: 'home', component: HomeComponent },
      { path: 'sign-in', component: UserLoginComponent, canActivate: [SignInGuardGuard] },
      { path: 'sign-up', component: SignIpComponent, canActivate: [SignInGuardGuard] },
      { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [SignInGuardGuard] },
      { path: 'blog', component: BlogComponent },
      { path: 'blog/post', component: BlogPostComponent },
      { path: 'reviews', component: ReviewsComponent },
      { path: 'reviews/post', component: BlogPostComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'store', component: StoreComponent },
      { path: 'affiliate-store', component: AffiliateStoreComponent },
      { path: 'affiliate-store/product', component: AfPdpComponent },
      {path: 'downloads', component: DownloadsComponent},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'terms-of-use', component: TermsOfUseComponent},
      {path: 'about', component: AboutComponent},
    ]
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AdminLoginGuardGuard], children: [
      { path: 'overview', component: OverviewComponent },
      { path: 'post', component: PostComponent, children:[
        { path: 'new', component: NewPostComponent },
        { path: 'edit', component: EditPostComponent },
        { path: 'published', component: PublishedPostComponent },
        { path: 'manage', component: ManagePostsComponent },
      ]},
      { path:'seller', component: SellerComponent, children:[
        { path: 'dashboard', component: SellerDashboardComponent },
        { path: 'add-product', component: StoreAddProductComponent },
        { path: 'manage-products', component: StoreManageComponent },
        { path: 'store-orders', component: StoreOrdersComponent },
      ]},
      { path:'aff-store', component: SellerComponent, children:[
        { path: 'add-product', component: AfLegacyAddProductComponent },
        { path: 'manage-products', component: AfStoreManageProductsComponent },
        { path: 'orders', component: AfStoreOrdersComponent },
      ]},
      { path: 'views', component: ViewsComponent },
      { path: 'orders', component: OrdersComponent },
      { path: 'settings', component: SettingsComponent, children:[
        {path :'home-slider', component: HomeSliderComponent },
        { path: 'blog', component: PostsSettingsComponent },
        { path: 'reviews', component: ReviewSettingsComponent },
        { path: 'affiliate', component: AffiliateSettingsComponent },
      ]},
      { path: 'users', component: UsersComponent },
      { path: 'profile', component: ProfileComponent },
      {path: 'moderation', component: ModerationComponent}
    ]
  },
  { path: 'admin-error', component: AdminLoginErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 25], // cool option, or ideal option when you have a fixed header on top.
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
